<template>
  <div class="container">
    <div class="text-center">
      <h1>ПРИХОД</h1>
      <div class="d-flex justify-content-center m-3 btn-group">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getDocs()">
              Сформировать
            </button>
          </div>

          <div class="col mt-1">
            <button class="btn btn-info w-100" @click="addDoc()">
              Добавить
            </button>
          </div>
        </div>
      </div>

      <div class="row row-cols-1">
        <div class="col-sm">
          <select
            id="stores"
            v-model="currentStore"
            class="border-1 form-select custom-select w-100"
          >
            <option v-for="store in stores" :value="store" :key="store">
              {{ store }}
            </option>
          </select>
        </div>
      </div>

      <div class="row row-cols-1 mt-1">
        <div class="col-sm">
          <input
            v-model="searchText"
            type="text"
            class="form-control"
            placeholder="Поиск"
          />
        </div>

        <div class="col mt-1">
          <button class="btn btn-secondary w-100" @click="setShowClosedDate()">
            ПОКАЗАТЬ ВРЕМЯ
          </button>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading">
      <div v-for="doc in docs" :key="doc._id" class="border mt-3">
        <button class="btn btn-light w-100" @click="setDocShow(doc)">
          <span class="h4">
            {{ dateFormat(doc.deliveryDate).substring(0, 10) }}
          </span>
        </button>

        <NewOrder
          v-if="'show' in doc && doc.show"
          :clipboard-active="true"
          :delivery-date="doc.deliveryDate"
          :show-closed-date="showClosedDate"
          :isList="true"
          :search-text-list="searchText"
          :clipboard-text="clipboardText"
          :store="currentStore"
        />
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch /*, computed, nextTick*/ } from "vue";
import useCommonMixin from "@/components/commonMixin.js";
import NewOrder from "@/components/NewOrder.vue";

export default {
  components: { NewOrder },

  setup() {
    const dateFrom = ref("");
    const dateTo = ref("");
    const spinnerLoading = ref("");
    const docs = ref([]);
    const showClosedDate = ref(false);
    const searchText = ref("");
    const clipboardText = ref("");
    const stores = ref([
      "Склад №1 (ПТЗ)",
      "Склад №2 (МУРМАНСК)",
      "Склад №3 (АПАТИТЫ)",
    ]);
    const currentStore = ref("");

    const { getSkladOrderDraft, dateFormat, postUserHistoryByMetric } = useCommonMixin();

    const skladPtz = () => {
      return JSON.parse(localStorage.getItem("skladPtz"));
    };

    const skladApatiti = () => {
      return JSON.parse(localStorage.getItem("skladApatiti"));
    };

    const skladMyrmansk = () => {
      return JSON.parse(localStorage.getItem("skladMyrmansk"));
    };


    const getDocs = async () => {
      docs.value = [];
      if (!currentStore.value) {
        return
      }
      spinnerLoading.value = true;
      let result = await getSkladOrderDraft(dateFrom.value, dateTo.value, currentStore.value);
      if (result.status) {
        docs.value = result.data;
        spinnerLoading.value = false;
      }
    };


    const setDocShow = (doc) => {
      if ("show" in doc) {
        doc.show = doc.show ? false : true;
      } else {
        doc.show = true;
      }
    };

    const addDoc = () => {
      docs.value.unshift({
        income: true,
        deliveryDate: new Date().toISOString().substring(0, 10),
        goods: [],
      });
    };

    const setShowClosedDate = () => {
      showClosedDate.value = showClosedDate.value ? false : true;
    };

    watch(
      () => searchText.value,
      () => {
        console.log("watch searchText", searchText.value);
        if (searchText.value.length >= 2) {
          docs.value.forEach((doc) => (doc.show = true));
        }
      }
    );

    onMounted(() => {
      stores.value = [];
      if (skladPtz()) stores.value.push("Склад №1 (ПТЗ)");
      if (skladMyrmansk()) stores.value.push("Склад №2 (МУРМАНСК)");
      if (skladApatiti()) stores.value.push("Склад №3 (АПАТИТЫ)");


      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);

      document.addEventListener("paste", (event) => {
        let paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );
        handleClipboard(paste);
        event.preventDefault();
      });

      postUserHistoryByMetric('Приход')
    });

    const handleClipboard = (text) => {
      clipboardText.value = text;
    };

    return {
      dateFrom,
      dateTo,
      spinnerLoading,
      getDocs,
      docs,
      showClosedDate,
      setDocShow,
      dateFormat,
      searchText,
      setShowClosedDate,
      clipboardText,
      addDoc,
      stores,
      currentStore,
    };
  },
};
</script>
