<template>
  <div>
    <div class="text-center">
      <h1>ИНВЕНТАРИЗАЦИЯ</h1>
    </div>

    <div v-if="!spinnerLoading" class="container lh">
      <div class="row">
        <div class="col">
          <input
            v-model="doc.date"
            type="datetime-local"
            class="form-control"
            placeholder="Дата"
          />
        </div>
        <div class="col">
          <input
            v-model="doc.number"
            type="text"
            class="form-control"
            placeholder="Номер"
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <Multiselect
            v-model="doc.store"
            :options="stores"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="name"
            label="name"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
          >
          </Multiselect>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          Список прошлых инвентаризаций:
          <button class="btn btn-warning" @click="addLastInventory()">+</button>

          <div
            v-for="inventoryItem in doc.inventories"
            :key="inventoryItem.inventory"
          >
            <Multiselect
              v-model="inventoryItem.inventory"
              :options="lastInventories"
              :loading="false"
              :internal-search="false"
              valueProp="inventory"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
            >
            </Multiselect>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <input
            v-model="doc.comment"
            type="text"
            class="form-control"
            placeholder="Комментарий"
          />
        </div>
      </div>
      <div v-if="doc && doc.goods" class="row mt-1 mx-1">
        Дата остатков из 1C:
        {{ dateBalance }}
      </div>

      <div class="row mt-3">
        <div class="col">
          <input
          style="width: 1rem; height: 1.0rem"
          class="form-check-input"
          type="checkbox"
          v-model="showNotCollectedOnly"
          />
          Показать только не готовые
        </div>
      </div>

      <div v-if="!spinnerSave" class="mt-2">
        <div class="row">
          <span class="text-danger">{{ errorSaving }}</span>
        </div>
        <div class="d-flex justify-content-center btn-group">
          <button class="btn btn-primary w-100" @click="saveDoc()">
            СОХРАНИТЬ
          </button>
          <button
            class="btn btn-warning w-100"
            @click="getStoreBalance()"
            :disabled="!getStoreBalanceActive"
          >
            ЗАПОЛНИТЬ
          </button>
        </div>

        <router-link
          class="btn btn-secondary w-100 mt-1"
          :to="{ name: 'inventoryList' }"
          >НАЗАД</router-link
        >
        <div class="d-flex justify-content-center btn-group mt-1">
          <input
            v-model="searchTextTemp"
            type="text"
            class="form-control "
            placeholder="Поиск"
            @change="searchText = searchTextTemp"
            @focus="searchTextFocused = true"
            @blur="searchTextFocused = false"
          />

          <button class="btn btn-outline-secondary" @click="clearSearchText()">
            ОЧИСТИТЬ
          </button>
        </div>

        <div class="d-flex justify-content-center btn-group mt-1">
          <button class="btn btn-light mx-1" @click="addRow()">
            Строка +
          </button>
          <button v-if="false" class="btn btn-light mx-1" @click="addProduct()">
            Номенклатура +
          </button>
        </div>
      </div>
      <div v-if="spinnerSave" class="d-flex justify-content-center mt-3">
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
        ></div>
      </div>

      <div class="mt-2">
        <div class="row fw-bold">
          <div class="col">Номенклатура</div>
          <div class="col">План</div>
          <div class="col">Факт</div>
          <div class="col">Готово</div>
        </div>

        <div class="row  ">
          <div class="col">
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('up')"
            >
              Показать еще
            </button>
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('start')"
            >
              В начало
            </button>
          </div>
        </div>

        <div>
          <div
            v-for="goodsRow in docGoodsFilter"
            :key="goodsRow._id"
            :id="`inventory-row-${goodsRow._id}`"
            class="row cols-4 border"
            @click="selectRow(goodsRow._id)"
            :class="{
              'selected-row':
                goodsRow._id == currentRowId && !fillProductByBarcode,
              'adding-barcode-product':
                goodsRow._id == currentRowId && addingBarcode,
              'fill-product-by-barcode':
                goodsRow._id == currentRowId && fillProductByBarcode,
            }"
          >
            <div class="col-5 px-0">
              <span v-if="!goodsRow.newRow">{{ goodsRow.productName }}</span>
              <div v-if="goodsRow.newRow">
                <Multiselect
                  v-model="goodsRow.product"
                  :options="products"
                  :loading="false"
                  :internal-search="false"
                  valueProp="_id"
                  trackBy="name"
                  label="name"
                  :filterResults="true"
                  :minChars="2"
                  :resolveOnLoad="true"
                  :searchable="true"
                  noOptionsText="Не найден"
                  :limit="10"
                >
                </Multiselect>
                <button
                  class="btn btn-secondary btn-sm"
                  @click="
                    fillProductByBarcode = fillProductByBarcode ? false : true
                  "
                >
                  Заполнить по ШК
                </button>
              </div>

              <label
                class="
              col-form-label
              text-muted
              px-0 py-0
              "
                >Ячейки
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    goodsRow.productPositions.unshift({
                      _id: null,
                      productGuid: goodsRow.guid,
                      positionGuid: null,
                    })
                  "
                >
                  +
                </button>
              </label>

              <div
                v-for="(position, positionIndex) in goodsRow.productPositions"
                :key="positionIndex"
                class="row mb-1 ms-1"
              >
                <div
                  class="btn-group w-100 px-0"
                  role="group"
                  aria-label="Basic example"
                  style="min-width: 130px;"
                >
                  <Multiselect
                    v-model="
                      goodsRow.productPositions[positionIndex].positionGuid
                    "
                    :options="positions"
                    :loading="false"
                    :internal-search="false"
                    valueProp="guid"
                    trackBy="name"
                    label="name"
                    :filterResults="true"
                    :minChars="2"
                    :resolveOnLoad="true"
                    :searchable="true"
                    noOptionsText="Не найден"
                    @select="changeProductPosition(goodsRow._id, positionIndex)"
                  >
                  </Multiselect>
                  <button
                    class="btn btn-outline-danger"
                    @click="removeProductPosition(goodsRow._id, positionIndex)"
                  >
                    -
                  </button>
                </div>
              </div>
            </div>
            <div class="col ps-1 fw-bold">
              <input
                v-model="goodsRow.plan"
                type="number"
                class="form-control px-0"
                placeholder=""
                disabled
              />
            </div>
            <div class="col px-0">
              <input
                v-model="goodsRow.fact"
                type="number"
                class="form-control px-0"
                placeholder=""
              />
            </div>
            <div class="col">
              <div
                class="
              form-check form-switch
              d-flex
              justify-content-center
              mb-3
              "
              >
                <input
                  style="width: 3rem; height: 1.5rem"
                  class="form-check-input"
                  type="checkbox"
                  v-model="goodsRow.collected"
                  @change="fillCollected(goodsRow)"
                />
              </div>
              <p class="text-center">
                ШК:
                <span class="fw-bold">{{ goodsRow.barcodes.length }}</span>
              </p>
              <button
                class="btn btn-light"
                @click="addingBarcode = addingBarcode ? false : true"
              >
                ШК+
              </button>
            </div>
          </div>
        </div>

        <div class="row  ">
          <div class="col">
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('down')"
            >
              Показать еще
            </button>
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('start')"
            >
              В начало
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, nextTick } from "vue";
//import { onMounted, onUnmounted } from "vue";
import Multiselect from "@vueform/multiselect";
import router from "../router";
import { v4 as uuidv4 } from "uuid";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  components: { Multiselect },
  props: { id: { type: String, default: null } },
  setup(props) {
    const spinnerSave = ref(false);
    const spinnerLoading = ref(false);
    const dateFormat = (date) => {
      let dateFormated = new Date(date);
      return dateFormated.toLocaleString();
    };

    const products = ref([]);
    const stores = ref([]);
    const positions = ref([]);
    const addingBarcode = ref(false);
    const searchText = ref("");
    const searchTextTemp = ref("");
    const fillProductByBarcode = ref(false);

    const filterCursor = ref(10);
    const filterStep = ref(10);
    const filterMax = ref(20);
    const errorSaving = ref("");
    const searchTextFocused = ref(false);

    const lastInventories = ref([]);

    const { getLastInventories } = useCommonMixin();
    const addLastInventory = () => {
      if (!("inventories" in doc.value)) doc.value.inventories = [];
      doc.value.inventories.push({ inventory: "" });
    };

    const showNotCollectedOnly = ref(false);

    const dateBalance = computed(() => {
      let result = "";
      if (doc.value.goods.length >= 2)
        result = dateFormat(
          doc.value.goods[doc.value.goods.length - 1].dateBalance
        );
      return result;
    });

    const getDoc = async () => {
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getInventory/byId/" + props.id,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        doc.value = await res.json();
      }
    };

    const initNewDoc = async () => {
      let date = new Date();
      date.setTime(date.getTime() + 1000 * 3 * 60 * 60);
      doc.value = {
        number: 0,
        date: date.toISOString().substring(0, 19),
        comment: "",
        store: "1",
      };
    };

    const saveDoc = async () => {
      spinnerSave.value = true;
      errorSaving.value = "";

      let res = await fetch(process.env.VUE_APP_API_URL + "/saveInventory/", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(doc.value),
      });
      console.log(res.status);
      if (res.status == 200) {
        spinnerSave.value = false;
        doc.value = await res.json();
        router.push({ name: "inventoryItem", params: { id: doc.value._id } });
      } else {
        errorSaving.value = await res.json();
        spinnerSave.value = false;
      }
    };

    const getStores = async () => {
      let res = await fetch(process.env.VUE_APP_API_URL + "/getStores/", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      });
      console.log(res.status);

      if (res.status == 200) {
        stores.value = await res.json();
      }
    };

    const getStoreBalance = async () => {
      spinnerSave.value = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          "/getStoreBalance/inventoryWithLastInventories/store/" +
          doc.value.store,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify({ lastInventories: doc.value.inventories }),
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        doc.value.goods = await res.json();
        spinnerSave.value = false;
      }
    };

    const getProducts = async () => {
      let res = await fetch(process.env.VUE_APP_API_URL + "/getProducts/", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      });
      console.log(res.status);

      if (res.status == 200) {
        products.value = await res.json();
      }
    };

    const getPositions = async () => {
      let res = await fetch(process.env.VUE_APP_API_URL + "/getPositions/", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      });
      console.log(res);
      if (res.status == 200) {
        positions.value = await res.json();
      }
    };

    const getProductName = (productId) => {
      let name = "";
      let productIndex = products.value.findIndex(
        (product) => product._id == productId
      );
      name = productIndex >= 0 ? products.value[productIndex].name : "";
      return name;
    };

    const doc = ref([]);

    const fillCollected = (row) => {
      if (!row.fact && row.collected) {
        row.fact = row.plan;
      }
    };

    const currentRow = ref(null);
    const currentRowId = ref(null);
    const selectRow = (rowId, productIndex = null) => {
      let rowIndex = doc.value.goods.findIndex((item) => item._id == rowId);
      currentRow.value = rowIndex;
      currentRowId.value = rowId;
      if (productIndex) {
        filterCursor.value = productIndex;
      }
    };

    const isPosition = (text) => {
      let positionIndex = positions.value.findIndex(
        (item) => item.guid == text
      );
      return positionIndex >= 0;
    };

    const addPosition = (guid) => {
      if (currentRow.value >= 0) {
        let product = doc.value.goods[currentRow.value];
        let productsPositionIndex = product.productPositions.findIndex(
          (item) => item.positionGuid == guid
        );

        if (productsPositionIndex < 0) {
          product.productPositions.unshift({
            _id: null,
            productGuid: product.guid,
            positionGuid: guid,
          });
          changeProductPosition(product._id, 0);
        }
      }
    };

    const addBarcode = (code, productIndex) => {
      if (productIndex >= 0 && code.length > 0) {
        let currentProduct = doc.value.goods[productIndex];
        let codeIndex = currentProduct.barcodes.findIndex(
          (item) => item == code
        );
        if (codeIndex < 0) {
          currentProduct.barcodes.push(code);
        }
      }
    };

    const scrollToRow = async (rowId) => {
      await nextTick();
      console.log("scrollToRow", `inventory-row-${rowId}`);
      let rowEl = document.getElementById(`inventory-row-${rowId}`);
      rowEl.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const handleClipboard = (text) => {
      console.log("handleClipboard", text);
      if (searchTextFocused.value) {
        searchTextTemp.value = text;
        searchText.value = text;
        //searchTextFocused.value = false
      } else if (isPosition(text)) {
        // console.log("isPosition");
        addPosition(text);
      } else {
        if (fillProductByBarcode.value) {
          let productIndex = products.value.findIndex((product) => {
            let barcodeIndex = product.barcodes.findIndex(
              (barcodeItem) => text.indexOf(barcodeItem) >= 0
            );
            return barcodeIndex >= 0;
          });
          if (productIndex >= 0) {
            doc.value.goods[currentRow.value].product =
              products.value[productIndex]._id;
          }
          fillProductByBarcode.value = false;
        } else if (addingBarcode.value) {
          // console.log("addingBarcode");
          addBarcode(text, currentRow.value);
          addingBarcode.value = false;
        } else {
          let rowIndex = doc.value.goods.findIndex((row) => {
            let barcodeIndex = row.barcodes.findIndex(
              (barcodeItem) => text.indexOf(barcodeItem) >= 0
            );
            return barcodeIndex >= 0;
          });

          console.log("rowIndex", rowIndex);
          let rowGoods = doc.value.goods[rowIndex];
          // let rowFilterIndex = docGoodsFilter.value.findIndex((row) => row._id == rowGoods._id)
          // console.log("rowFilterIndex", rowFilterIndex);

          if (rowIndex >= 0) {
            selectRow(rowGoods._id, rowIndex);
            scrollToRow(rowGoods._id);
          } else {
            searchTextTemp.value = text;
            searchText.value = text;
            // console.log("adding searchText");
          }
        }
      }
    };
    const spinnerSearch = ref(true);
    const docGoodsFilter = computed(() => {
      let result = doc.value.goods;
      let searchT = searchText.value.toLowerCase();

      if (searchText.value.length >= 3) {
        result = result.filter(
          (row) =>
            JSON.stringify(row)
              .toLowerCase()
              .indexOf(searchT) >= 0
        );
      }

      if (showNotCollectedOnly.value){
        result = result.filter((row) => !row.collected)
      }

      if (result && result.length > filterMax.value) {
        let filterStart =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
        let filterEnd = filterCursor.value + filterStep.value;

        result = result.slice(filterStart, filterEnd);
      }
      return result;
    });

    const showMore = (direction) => {
      if (direction == "up") {
        filterCursor.value =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
      }
      if (direction == "down") {
        filterCursor.value =
          filterCursor.value + filterStep.value < doc.value.goods.length
            ? filterCursor.value + filterStep.value
            : doc.value.goods.length - filterStep.value;
      }

      if (direction == "start") {
        filterCursor.value = filterStep.value;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    const addRow = () => {
      let newRow = {
        product: null,
        productName: "",
        plan: 0,
        fact: "",
        positions: [],
        barcodes: [],
        newRow: true,
        collected: false,
        _id: uuidv4(),
      };
      doc.value.goods.unshift(newRow);
    };

    const clearSearchText = () => {
      searchText.value = "";
      searchTextTemp.value = "";
    };

    const getStoreBalanceActive = computed(() => {
      return doc.value.store && doc.value.store.length == 24;
    });

    onMounted(async () => {
      document.addEventListener("paste", (event) => {
        let paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );
        handleClipboard(paste);
        // addBarcode(paste);
        event.preventDefault();
      });

      spinnerLoading.value = true;
      await getStores();
      await getProducts();
      await getPositions();

      if (props.id == "__new__") {
        await initNewDoc();
      } else {
        await getDoc();
      }

      let lastInventoriesResp = await getLastInventories(
        doc.value.store,
        doc.value.date
      );
      if (lastInventoriesResp.status)
        lastInventories.value = lastInventoriesResp.data;

      spinnerLoading.value = false;
    });

    const removeProductPosition = (productId, positionIndex) => {
      console.log("removeProductPosition");
      changeProductPosition(productId, positionIndex, true);
    };

    const changeProductPosition = async (
      productId,
      positionIndex,
      markDelete = false
    ) => {
      let productGuid = null;
      let positionGuid = null;
      let result = null;
      let _id = null;

      let productIndex = doc.value.goods.findIndex(
        (row) => row._id == productId
      );
      let product = doc.value.goods[productIndex];
      productGuid = product.guid;
      positionGuid = product.productPositions[positionIndex].positionGuid;
      _id = product.productPositions[positionIndex]._id;

      let payload = {
        productGuid: productGuid,
        positionGuid: positionGuid,
        _id: _id,
        delete: markDelete,
      };
      console.log("_id", _id);
      console.log("productGuid, positionGuid", productGuid, positionGuid);

      let res = await fetch(
        process.env.VUE_APP_API_URL + "/saveProductPosition",
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(payload),
        }
      );

      if (res.status == 200) {
        if (!markDelete) {
          result = await res.json();
          product.productPositions[positionIndex] = result;
        }

        if (markDelete) {
          product.productPositions.splice(positionIndex, 1);
        }
      }
    };

    return {
      getDoc,
      doc,
      dateFormat,
      saveDoc,
      spinnerSave,
      stores,
      getStoreBalance,
      products,
      getProductName,
      spinnerLoading,
      positions,
      removeProductPosition,
      fillCollected,
      selectRow,
      currentRow,
      addingBarcode,
      searchText,
      docGoodsFilter,
      addRow,
      fillProductByBarcode,
      showMore,
      filterCursor,
      searchTextTemp,
      clearSearchText,
      currentRowId,
      searchTextFocused,
      spinnerSearch,
      errorSaving,
      getStoreBalanceActive,
      scrollToRow,
      changeProductPosition,
      lastInventories,
      addLastInventory,
      dateBalance,
      showNotCollectedOnly,
    };
  },
};
</script>

<style>
:root {
  --ms-px: 0.4rem;
}

.lh {
  line-height: 1;
}

.selected-row {
  background-color: #00ff9d;
}
.adding-barcode-product {
  background-color: #e1f100a2;
}
.fill-product-by-barcode {
  background-color: #1000f149;
}
</style>
