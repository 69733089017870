import { createRouter, createWebHistory } from 'vue-router'
import OrderList from '../components/OrderList.vue'
import SkladOrderList from '../components/SkladOrderList.vue'
import KkmTaskList from '../components/KkmTaskList.vue'
import UserList from '../components/UserList.vue'
import Cheque from '../components/Cheque.vue'
import InventoryList from '../components/InventoryList.vue'
import InventoryItem from '../components/InventoryItem.vue'
import NewOrder from '../components/NewOrder.vue'
import SkladOrderGoods from '../components/SkladOrderGoods.vue'
import ReportSkladLoaders from '../components/ReportSkladLoaders.vue'
import ReportSkladWeight from '../components/ReportSkladWeight.vue'
import DocCorrectionList from '../components/DocCorrectionList.vue'
import DocCorrectionItem from '../components/DocCorrectionItem.vue'
import DocIncomeList from '../components/DocIncomeList.vue'
import DocIncomeItem from '../components/DocIncomeItem.vue'
import ProductBalance from '../components/ProductBalance.vue'
import Settings from '../components/Settings.vue'
import SkladRevision from '../components/SkladRevision.vue'
import NewOrderList from '../components/NewOrderList.vue'
import ReportRefunds from '../components/ReportRefunds.vue'
import SkladInventoryList from '../components/SkladInventoryList.vue'
import SkladInventoryItem from '../components/SkladInventoryItem.vue'
import ReportSkladLost from '../components/ReportSkladLost.vue'
import ReportSkladBalance from '../components/ReportSkladBalance.vue'
import SkladAkts from '../components/SkladAkts.vue'
import ClientOrders from '../components/ClientOrders.vue'
import ClientOrder from '../components/ClientOrder.vue'

const routes = [
  {
    path: '/',
    component: OrderList,
    name: "orderList",
  },
  {
    path: '/sklad',
    component: SkladOrderList,
    name: "skladOrderList",
  },
  {
    path: '/newOrder/:deliveryDate',
    props: true,
    component: NewOrder,
    name: "newOrder",
  },
  {
    path: '/skladOrderGoods/:deliveryDate',
    props: true,
    component: SkladOrderGoods,
    name: "skladOrderGoods",
  },
  {
    path: '/kkmtasklist',
    component: KkmTaskList
  },
  {
    path: '/users',
    component: UserList
  },
  {
    path: '/cheque/:chequeId/:chequeType',
    name: 'cheque',
    component: Cheque
  },
  {
    path: '/inventoryList',
    name: 'inventoryList',
    component: InventoryList
  },
  {
    path: '/inventoryItem/:id',
    props: true,
    name: 'inventoryItem',
    component: InventoryItem,
  },
  {
    path: '/reportSkladLoaders',
    name: 'reportSkladLoaders',
    component: ReportSkladLoaders
  },
  {
    path: '/reportSkladWeight',
    name: 'reportSkladWeight',
    component: ReportSkladWeight
  },
  {
    path: '/docCorrections',
    name: 'docCorrections',
    component: DocCorrectionList
  },
  {
    path: '/docCorrectionItem/:id/:invoiceGuid?/:orderGuid?/:orderObj?',
    props: true,
    name: 'docCorrectionItem',
    component: DocCorrectionItem,
  },
  {
    path: '/docIncomes',
    name: 'docIncomes',
    component: DocIncomeList
  },
  {
    path: '/docIncomeItem/:id',
    props: true,
    name: 'docIncomeItem',
    component: DocIncomeItem,
  },
  {
    path: '/productBalance',
    name: 'productBalance',
    component: ProductBalance
  },
  {
    path: '/settings',
    component: Settings
  },
  {
    path: '/skladRevision',
    name: 'skladRevision',
    component: SkladRevision
  },
  {
    path: '/income',
    name: 'income',
    component: NewOrderList
  },
  {
    path: '/reportRefunds',
    name: 'reportRefunds',
    component: ReportRefunds
  },
  {
    path: '/skladInventoryList',
    name: 'skladInventoryList',
    component: SkladInventoryList
  },
  {
    path: '/skladInventoryItem/:id',
    props: true,
    name: 'skladInventoryItem',
    component: SkladInventoryItem
  },
  {
    path: '/reportSkladLost',
    name: 'reportSkladLost',
    component: ReportSkladLost
  },
  {
    path: '/reportSkladBalance',
    name: 'reportSkladBalance',
    component: ReportSkladBalance
  },
  {
    path: '/skladAkts',
    name: 'skladAkts',
    component: SkladAkts
  },
  {
    path: '/clientOrders',
    name: 'clientOrders',
    component: ClientOrders
  },
  {
    path: '/clientOrder/:refKey',
    props: true,
    name: 'clientOrder',
    component: ClientOrder,
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
