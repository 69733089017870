import { ref, nextTick } from "vue";
import { /*Html5Qrcode,*/ Html5QrcodeScanner } from "html5-qrcode";
import useCommonMixin from "@/components/commonMixin.js";

export default () => {
  const settings = ref({
    camera: '',
    scannerMode: '',
  })
  let Html5QrcodeObj = undefined
  const scanner = ref(false);
  const currentOrder = ref(false);
  const currentProductIndex = ref('')
  const clearScanner = ref(true)

  const {
    saveOrder,
  } = useCommonMixin();

  const getSettings = async () => {
    const settingsFromLocalstotrage = JSON.parse(
      localStorage.getItem("settings")
    );
    if (settingsFromLocalstotrage == null) {
      return;
    }
    settings.value = settingsFromLocalstotrage;
  };

  const saveSettings = () => {
    localStorage.setItem("settings", JSON.stringify(settings.value));
  };

  const dateFormat = (date) => {
    let dateFormated = new Date(date);
    return dateFormated.toLocaleString();
  };

  const loadCameraScanner = async (needInit, needToClear) => {
    let scannerId = currentOrder.value ? `scanner-${currentOrder.value._id}` : `scanner`
    console.log('scanner', scanner.value);

    if (scanner.value) {

      if (Html5QrcodeObj && needToClear) {
        Html5QrcodeObj.clear()
        clearScanner.value = false
        console.log('Html5QrcodeObj.clear()', Html5QrcodeObj);
      }

      if (needInit || needToClear) {

        const config = {
          fps: 10,
          qrbox: { width: 250, height: 250 },
          showZoomSliderIfSupported: true,
          defaultZoomValueIfSupported: 2,
          showTorchButtonIfSupported: true,
        };

        Html5QrcodeObj = new Html5QrcodeScanner(
          scannerId,
          config,
        )

        console.log('new Html5QrcodeScanner', Html5QrcodeObj);

        Html5QrcodeObj.render(scannerSuccessCallback, onScanFailure);
      }
    } else {
      document.getElementById(scannerId).innerHTML = "";
    }
  };

  const scannerSuccessCallback = (decodedText, decodedResult) => {
    console.log('decodedText, decodedResult', decodedText, decodedResult);
    console.log('currentOrder', currentOrder.value,)
    if (decodedText.length < 1) { //33
      let confi = window.confirm(`Некорретная марка - ${decodedText}`);
      console.log(confi);
      return
    }

    if (currentOrder.value) {
      if (!('marks' in currentOrder.value.goods[currentProductIndex.value])) currentOrder.value.goods[currentProductIndex.value].marks = []
      let markIndex = currentOrder.value.goods[currentProductIndex.value].marks.findIndex(item => item == decodedText)
      if (markIndex < 0) {
        currentOrder.value.goods[currentProductIndex.value].marks.push(decodedText)
        saveOrder(currentOrder.value)
      }
    }

  };

  const onScanFailure = () => {
    //console.log('onScanFailure');
  }

  const scan = async() => {
    let needToClear = false
    let needInit = !Html5QrcodeObj
    let scannerId = currentOrder.value ? `scanner-${currentOrder.value._id}` : `scanner`
    if (Html5QrcodeObj && Html5QrcodeObj.elementId != scannerId) needToClear = true
    console.log('scan------------------');
    console.log('Html5QrcodeObj', Html5QrcodeObj);
    console.log('currentOrder.value', currentOrder.value);
    console.log('scannerId', scannerId);
    console.log('needToClear', needToClear);
    console.log('needInit', needInit);
    if (needInit || needToClear) {

      scanner.value = true;
      let scannerElements = document.getElementsByClassName('scanner')
      scannerElements.forEach(item => {
        item.innerHTML = ""
      })
      
      await loadCameraScanner(needInit, needToClear);
    }
  };



  const scrollToId = async (Id) => {
    await nextTick();
    let el = document.getElementById(Id);
    el.scrollIntoView({ block: "start", behavior: "smooth" });
  };



  return {
    settings,
    getSettings,
    saveSettings,
    dateFormat,
    loadCameraScanner,
    Html5QrcodeObj,
    scan,
    scanner,
    currentOrder,
    currentProductIndex,
    clearScanner,
    scrollToId,
    saveOrder
  };
};
