<template>
  <div v-if="clientOrders()">
    <div class="text-center container">
      <h1>ЗАКАЗЫ КЛИЕНТОВ</h1>
      <div class="">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <CatalogWidget
              v-model:item="currentStore"
              type="Склады"
              placeholder="Склад"
              :fetch-all="true"
            />
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getDocs">
              Сформировать
            </button>
          </div>
          <div class="col mt-1">
            <router-link
              class="btn btn-warning w-100"
              :to="{ name: 'clientOrder', params: { refKey: '__NEW__' } }"
              >Новый</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container mt-3">
      <div class="row fw-bold">
        <div class="col-sm" style="max-width:40px"></div>
        <div class="col-sm" style="max-width:40px"></div>
        <div class="col-sm" style="max-width:40px"></div>
        <div class="col-sm">Номер</div>
        <div class="col-sm">Дата</div>
        <div class="col-sm">Сумма</div>
        <div class="col-sm">Клиент</div>
        <div class="col-sm">Склад</div>
        <div class="col-sm">Менеджер</div>
      </div>

      <div
        v-for="doc in docsFiltered"
        :key="doc.Ref_Key"
        class="row border border-1"
      >
        <div class="col-sm" style="max-width:40px">
          <svg
            v-if="doc.Posted"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width="32"
            height="32"
          >
            <path
              fill="#71f702"
              d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
            />
          </svg>

          <svg
            v-if="doc.DeletionMark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            width="28"
            height="28"
          >
            <path
              fill="#ff0000"
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            />
          </svg>
        </div>

        <div class="col-sm" style="max-width:40px">
          <svg
            v-if="doc.ЗКР_ГотовДляРеализации"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            width="28"
            height="28"
          >
            <path
              fill="#63e6be"
              d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
            />
          </svg>
        </div>

        <div class="col-sm" style="max-width:40px">
          <svg
            v-if="doc.isShipping"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            width="28"
            height="28"
          >
            <path
              fill="#5c5ace"
              d="M48 0C21.5 0 0 21.5 0 48L0 368c0 26.5 21.5 48 48 48l16 0c0 53 43 96 96 96s96-43 96-96l128 0c0 53 43 96 96 96s96-43 96-96l32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-64 0-32 0-18.7c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7L416 96l0-48c0-26.5-21.5-48-48-48L48 0zM416 160l50.7 0L544 237.3l0 18.7-128 0 0-96zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
            />
          </svg>
        </div>

        <div class="col-sm">
          <router-link
            class=""
            :to="{ name: 'clientOrder', params: { refKey: doc.Ref_Key } }"
            >{{ doc.Number }}</router-link
          >
        </div>
        <div class="col-sm">{{ dateFormat(doc.Date) }}</div>
        <div class="col-sm">{{ doc.СуммаДокумента }}</div>
        <div class="col-sm">
          {{ doc.Партнер.Description }}
          {{ НаименованиеИнтернетПокупателя(doc) }}
        </div>
        <div class="col-sm">{{ doc.Склад.Description }}</div>
        <div class="col-sm">
          <span v-if="doc.Менеджер">
            {{ doc.Менеджер.Description }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
// import Multiselect from "@vueform/multiselect";
import useCommonMixin from "@/components/commonMixin.js";
import CatalogWidget from "@/components/CatalogWidget.vue";

export default {
  components: { CatalogWidget },
  setup() {
    const spinnerLoading = ref(false);

    const docs = ref([]);
    const dateFrom = ref("");
    const dateTo = ref("");
    const { dateFormat, getDoc1C, userSklad } = useCommonMixin();

    const currentStore = ref('');

    const clientOrders = () => {
      return JSON.parse(localStorage.getItem("clientOrders"));
    };

    const getDocs = async () => {
      spinnerLoading.value = true;
      let reqData = {
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        type: "ЗаказКлиента",
        store: currentStore.value,
        expand: "Партнер,Склад,Менеджер,Договор",
        select:
          "Number, Date, Партнер_Key, Партнер, Posted, DeletionMark, Ref_Key, Склад_Key, Склад, Менеджер, Менеджер_Key, СуммаДокумента, Договор, Договор_Key, ЗКР_ГотовДляРеализации",
      };

      let resp = await getDoc1C(reqData);
      console.log("getDocs", resp);
      if (resp.status) {
        spinnerLoading.value = false;
        docs.value = resp.data.value;
      }
    };

    const docsFiltered = computed(() => {
      let result = docs.value;
      if (currentStore.value)
        result = result.filter((item) => item.Склад_Key == currentStore.value);
      return result;
    });

    const НаименованиеИнтернетПокупателя = computed(() => {
      return (doc) => {
        let result = "";
        if (doc.Договор && doc.Партнер.Description == "Интернет Покупатель")
          result = doc.Договор.Description;
        return result;
      };
    });
    onMounted(async () => {
      console.log('userSklad', userSklad());
      currentStore.value = userSklad()
      let today = new Date();
      // var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      //var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);

      let daysAgo = new Date();
      daysAgo.setDate(daysAgo.getDate() - 1);

      dateFrom.value = daysAgo.toISOString().slice(0, 10);
      dateTo.value = today.toISOString().slice(0, 10);
      await getDocs();
    });

    return {
      dateFrom,
      dateTo,
      getDocs,
      docs,
      spinnerLoading,
      currentStore,
      dateFormat,
      docsFiltered,
      clientOrders,
      НаименованиеИнтернетПокупателя,
    };
  },
};
</script>
