<template>
  <div class="card m-3" style="max-width: 30rem">
    <div class="card-body">
      <h5 class="card-title">ООО "ТЕХНОЛОГИЯ ВКУСА"</h5>
      <h5 class="card-title">ИНН: 1001348586</h5>
      <div class="card-text">
        <div class="row">
          <div class="col-sm">
            <div
              class="text-wrap"
              v-for="(item, index) in order.goods"
              :key="index"
            >
              {{ index + 1 }}. {{ item.productName }}: {{ item.productQty }} X
              {{ item.productPrice }} = {{ item.productSum }}<br />
              {{ taxLabel(item.taxName)
              }}<span v-if="item.taxSum > 0">: {{ item.taxSum }}</span
              ><br />
            </div>
            <h3>ИТОГ: {{ fiscalParams.sumFD }} руб.</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-sm">
            <div v-if="order.nal">НАЛИЧНЫМИ<br /></div>
            <div v-if="order.karta || order.qr">БЕЗНАЛИЧНЫМИ<br /></div>
            <span v-if="route.params.chequeType == 'qrCodes'">
              ПРИХОД
            </span>
            <span v-if="route.params.chequeType == 'refunds'">
              ВОЗВРАТ ПРИХОДА
            </span>
            <br />
            <p v-for="(tax, index) in getTaxSum" :key="index" class="mb-0">
              {{ taxLabel(tax.name) }}: {{ tax.total }}
            </p>
            СНО: ОБЩАЯ<br />
            <div v-if="fiscalParams.timeFD">
              Дата:
              {{
                fiscalParams.timeFD
                  .slice(0, 16)
                  .replace("T", " ")
              }}<br />
            </div>
            Смена: {{ fiscalParams.shiftNumber }}<br />
            Кассир: Баженов Д. И.<br />
            E-mail: {{ order.email }}<br />
            Сайт ФНС: {{ fiscalParams.fnsUrl }}<br />
            РН: {{ fiscalParams.registrationNumber }}<br />
          </div>

          <div class="col-sm">
            ФД: {{ fiscalParams.numFD }}<br />
            ФН: {{ fiscalParams.fnFD }}<br />
            ФП: {{ fiscalParams.fpFD }}<br />
            <qrcode-vue :value="qr" :size="170" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <pre>
  {{ qr }}
</pre
  > -->
</template>

<script>
import { useRoute } from "vue-router";
import { ref, onMounted, computed } from "vue";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },
  setup() {
    const route = useRoute();
    const fiscalParams = ref({});
    const order = ref({});
    const qr = ref("");

    const getCheque = async () => {
      console.log("/getKkmTask/" + route.params.chequeId);
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getKkmTask/" + route.params.chequeId,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " + btoa("username:" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);
      console.log("res", res);

      if (res.status == 200) {
        let cheque = await res.json();
        console.log("cheque", cheque);
        let resData = JSON.parse(cheque.resData);
        order.value = cheque.order;
        fiscalParams.value = resData
        qr.value =
          `t=${fiscalParams.value.timeFD
            .slice(0, 17)
            .replace(/-/g, "")
            .replace(/:/g, "")}&` +
          `s=${
            String(fiscalParams.value.sumFD).indexOf(".") >= 0
              ? fiscalParams.value.sumFD
              : fiscalParams.value.sumFD + ".00"
          }&fn=${fiscalParams.value.fnFD}&i=${
            fiscalParams.value.numFD
          }&` +
          `fp=${fiscalParams.value.fpFD}&n=1`;
      }
    };


    const taxLabel = (tax) => {
      let label = "БЕЗ НДС";
      if (tax == "vat10") {
        label = "НДС 10%";
      } else if (tax == "vat20") {
        label = "НДС 20%";
      } else if (tax == "vat18") {
        label = "НДС 18%";
      } else if (tax == "vat0") {
        label = "НДС 0%";
      } else if (tax == "vat120") {
        label = "НДС 20/120%";
      } else if (tax == "vat110") {
        label = "НДС 10/110%";
      } else if (tax == "vat118") {
        label = "НДС 18/118%";
      }
      return label;
    };

    const getTaxSum = computed(() => {
      let sum = [];
      if ("goods" in order.value) {
        order.value.goods.forEach((item) => {
          let taxIndex = sum.findIndex((tax) => tax.name == item.taxName);
          if (taxIndex < 0 && item.taxSum > 0) {
            sum.push({ name: item.taxName, total: item.taxSum });
          } else if (item.taxSum > 0) {
            sum[taxIndex].total = sum[taxIndex].total + item.taxSum;
          }
        });
      }
      return sum;
    });

    onMounted(getCheque);

    return {
      getCheque,
      order,
      fiscalParams,
      qr,
      taxLabel,
      getTaxSum,
      route,
    };
  },
};
</script>
