<template>
  <div>
    <div class="text-center container">
      <h1>СКЛАДСКИЕ АКТЫ</h1>
      <div class="">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <Multiselect
              v-model="currentStore"
              :options="stores"
              :loading="false"
              :internal-search="false"
              valueProp="_id"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
              :limit="10"
              placeholder="Склад"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getDocs">
              Сформировать
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container mt-3">
      <SkladOrdersReadOnly
        :orders="docsFiltered"
        :show-only-weight-diff="false"
        :show-weight-check-btn="false"
        :show-revision="false"
      />
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import Multiselect from "@vueform/multiselect";
import SkladOrdersReadOnly from "@/components/SkladOrdersReadOnly.vue";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  components: { Multiselect, SkladOrdersReadOnly },
  setup() {
    const spinnerLoading = ref(false);

    const docs = ref([]);
    const dateFrom = ref("");
    const dateTo = ref("");
    const { dateFormat, getSkladAkts } = useCommonMixin();

    const currentStore = ref("Склад №1 (ПТЗ)");
    const stores = ref([
      { name: "Все склады", _id: "" },
      { name: "Склад №1 (ПТЗ)", _id: "Склад №1 (ПТЗ)" },
      { name: "Склад №2 (МУРМАНСК)", _id: "Склад №2 (МУРМАНСК)" },
      { name: "Склад №3 (АПАТИТЫ)", _id: "Склад №3 (АПАТИТЫ)" },
    ]);


    const getDocs = async () => {
      spinnerLoading.value = true
      let reqData = {
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        storeName: currentStore.value,
      }
      let resp = await getSkladAkts(reqData)
      if (resp.status) {
        spinnerLoading.value = false
        docs.value = resp.data
      }
    }

    const docsFiltered = computed(() => {
      let result = docs.value
      result = result.filter(item => !('markDelete' in item) || !item.markDelete )
      return result
    })

    onMounted(async () => {
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);
      await getDocs();

    });

    return {
      dateFrom,
      dateTo,
      getDocs,
      docs,
      spinnerLoading,
      currentStore,
      stores,
      dateFormat,
      docsFiltered,
    };
  },
};
</script>
