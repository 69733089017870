<template>
  <div
    v-if="isSkladRevision() || isSkladRevisionRO()"
    class="text-center container"
  >
    <h1>Ревизия</h1>

    <div class="row">
      <Multiselect
        v-model="currentStore"
        :options="stores"
        :loading="false"
        :internal-search="false"
        valueProp="_id"
        trackBy="name"
        label="name"
        :filterResults="true"
        :minChars="2"
        :resolveOnLoad="true"
        :searchable="true"
        noOptionsText="Не найден"
        :limit="10"
      />
    </div>

    <div class="row">
      <div class="col mt-1">
        <input
          v-model="dateFrom"
          type="date"
          class="form-control fs-5"
          placeholder="Дата"
        />
      </div>
      <div class="col mt-1">
        <input
          v-model="dateTo"
          type="date"
          class="form-control fs-5"
          placeholder="Дата"
        />
      </div>
      <div class="col mt-1">
        <button class="btn btn-primary w-100" @click="fetchSkladRevision()">
          Сформировать
        </button>
      </div>
    </div>

    <div v-if="skladRevision" class="row">
      <div class="col">
        <input
          v-model="searchText"
          type="text"
          class="form-control px-0"
          placeholder="Поиск"
        />
      </div>
    </div>

    <div v-if="skladRevision" class="row">
      <div class="col">
        <div class="row">
          <div class="col">Приход кол-во</div>
          <div class="col">Приход, руб</div>
          <div class="col">Раход кол-во</div>
          <div class="col">Расход, руб</div>
        </div>

        <div class="row">
          <div class="col">{{ total.inQty.toFixed(2) }}</div>
          <div class="col">{{ total.inSum.toFixed(2) }}</div>
          <div class="col">{{ total.outQty.toFixed(2) }}</div>
          <div class="col">{{ total.outSum.toFixed(2) }}</div>
        </div>

        <div class="row">
          <span class="fw-bold"
            >ИТОГО, руб: {{ (total.inSum + total.outSum).toFixed(2) }}</span
          >
        </div>
      </div>
    </div>

    <div v-if="false" class="row mt-1">
      <div class="col">
        <button
          v-if="skladRevision.edited"
          class="btn btn-warning"
          @click="saveItem()"
        >
          Сохранить
        </button>
      </div>
      <div class="col">
        <button class="btn btn-light" @click="addRow()">Добавить</button>
      </div>
    </div>

    <div v-if="skladRevision" class="mt-2">
      <div class="row fw-bold">
        <div class="col-5">Номенклатура</div>
        <div class="col-2">Кол-во</div>
        <div class="col-2">Цена</div>
        <div class="col-2">Сумма</div>
      </div>

      <div
        v-for="(goodsRow, goodsRowIndex) in goodsFiltered"
        :key="goodsRowIndex"
        class="row mt-1 border"
      >
        <div class="col-5 px-0">
          <span v-if="goodsRow.productName"> {{ goodsRow.productName }}</span>
          <Multiselect
            v-if="!goodsRow.productName"
            v-model="goodsRow.product"
            :options="products"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="name"
            label="name"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
            :limit="10"
            :class="{ 'product-input-wide': isMobile }"
            @change="skladRevision.edited = true"
            :disabled="!isSkladRevision()"
          >
            <template v-slot:singlelabel="option">
              <div class="multiselect-single-label">
                <span class="multiselect-single-label-text1 single-label">{{
                  option.value.name
                }}</span>
              </div>
            </template>
          </Multiselect>
          <p class="mb-0">
            <span v-if="goodsRow.selectDate" class="fst-italic">
              {{ dateFormat(goodsRow.selectDate) }}</span
            >
          </p>
        </div>

        <div class="col-2 px-0">
          <input
            v-model="goodsRow.qtyFact"
            type="number"
            class="form-control px-0"
            placeholder=""
            @input="skladRevision.edited = true"
            disabled
          />
        </div>

        <div class="col-2 px-0">
          <input
            v-model="goodsRow.price"
            type="number"
            class="form-control px-0"
            placeholder=""
            @input="skladRevision.edited = true"
            disabled
          />
        </div>

        <div class="col-2 px-0">
          <input
            v-model="goodsRow.sum"
            type="number"
            class="form-control px-0"
            placeholder=""
            @input="skladRevision.edited = true"
            disabled
          />
        </div>
      </div>
    </div>

    <Spinner v-if="spinnerLoading" />
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import useCommonMixin from "@/components/commonMixin.js";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "SkladRevision",
  components: { Spinner, Multiselect },
  setup() {
    const products = ref([]);
    const spinnerLoading = ref(true);
    const skladRevision = ref(null);
    const searchText = ref("");
    const stores = ref([]);
    const currentStore = ref("");
    const dateFrom = ref('')
    const dateTo = ref('')

    const {
      dateFormat,
      getProducts,
      getSkladRevision,
      saveSkladRevision,
      initSkladRevision,
      isMobile,
      getStores,
      postUserHistoryByMetric,
    } = useCommonMixin();

    const addRow = () => {
      var CurrentTime = new Date();
      CurrentTime.setHours(CurrentTime.getHours() + 3);
      let selectDate = CurrentTime.toISOString().substring(0, 19);

      skladRevision.value.goods.unshift({
        product: null,
        productName: "",
        qtyFact: null,
        selectDate: selectDate,
      });
    };

    const saveItem = async () => {
      spinnerLoading.value = true;
      let saveItemRes = await saveSkladRevision(skladRevision.value);
      if (saveItemRes.status) {
        skladRevision.value = saveItemRes.data[0];
      }
      spinnerLoading.value = false;
    };

    const goodsFiltered = computed(() => {
      let result = skladRevision.value ? skladRevision.value.goods : [];

      if (searchText.value.length >= 3) {
        result = result.filter(
          (item) =>
            JSON.stringify(item)
              .toLocaleLowerCase()
              .indexOf(searchText.value.toLocaleLowerCase()) >= 0
        );
      }
      return result;
    });

    const isSkladRevision = () => {
      return JSON.parse(localStorage.getItem("isSkladRevision"));
    };

    const isSkladRevisionRO = () => {
      return JSON.parse(localStorage.getItem("isSkladRevisionRO"));
    };

    const total = computed(() => {
      let result = {
        inQty: 0,
        outQty: 0,
        inSum: 0,
        outSum: 0,
      };

      goodsFiltered.value.forEach((row) => {
        if (row.qtyFact >= 0) {
          result.inQty += row.qtyFact;
          result.inSum += row.sum;
        }

        if (row.qtyFact < 0) {
          result.outQty += row.qtyFact;
          result.outSum += row.sum;
        }
      });

      return result;
    });

    const fetchSkladRevision = async () => {
      if (!currentStore.value) return;
      spinnerLoading.value = true;
      let skladRevisionRes = await getSkladRevision(currentStore.value, dateFrom.value, dateTo.value);
      skladRevision.value = skladRevisionRes.status
        ? skladRevisionRes.data[0]
        : null;
      spinnerLoading.value = false;
    };

    watch(currentStore, () => {
      console.log("currentStore");
      //fetchSkladRevision();
    });

    onMounted(async () => {
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);

      let productsRes = await getProducts();
      products.value = productsRes.status ? productsRes.data : products.value;

      let storesRes = await getStores();
      stores.value = storesRes.status ? storesRes.data : storesRes.value;

      await initSkladRevision(currentStore.value);

      await fetchSkladRevision();

      spinnerLoading.value = false;
      postUserHistoryByMetric('Ревизия')
    });

    return {
      products,
      spinnerLoading,
      skladRevision,
      isMobile,
      addRow,
      dateFormat,
      saveItem,
      searchText,
      goodsFiltered,
      isSkladRevision,
      isSkladRevisionRO,
      stores,
      fetchSkladRevision,
      currentStore,
      total,
      dateTo,
      dateFrom,
    };
  },
};
</script>
