<template>
  <div id="nav">
    <NavBar/>
  </div>
  <router-view/>
    <footer class="text-muted text-center">
    <p class="m-0">Версия {{ version }}</p>
    <p class="copyright text-muted text-center m-0">
    </p>
  </footer>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { version } from "../package.json";

export default ({
  components: {
    NavBar,
  },
  setup() {
    return {
      version,
    }
  },
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>