<template>
  <div class="mt-3">
    <button class="btn btn-outline-primary" @click="addUser">Создать</button>
    <input
      v-model="searchUser"
      type="text"
      class="form-control mt-3"
      placeholder="Поиск"
    />
  </div>

  <div v-if="editUsers()">
    <div class="list-group" v-for="user in usersFilter" :key="user._id">
      <div
        :id="user._id"
        class="list-group-item list-group-item-action border border-primary mt-3"
      >
        <div class="row">
          <div class="col-sm" v-if="false">
            <div class="row"><small class="text-muted">Id</small></div>
            <div class="row">
              <h5 class="mb-1 h5 border-0 text-muted">
                {{ user._id }}
              </h5>
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">Имя 1С</small></div>
            <div class="row">
              <input
                v-model="user.name"
                class="mb-1 h5 border-0"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">Username</small></div>
            <div class="row">
              <input
                v-model="user.username"
                class="mb-1 h5 border-0"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">Password</small></div>
            <div class="row">
              <input
                v-model="user.password"
                class="mb-1 h5 border-0"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">GUID</small></div>
            <div class="row">
              <input
                v-model="user.guid"
                class="mb-1 h6 border-0"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">Заблокирован</small></div>
            <div class="row">
              <input
                v-model="user.isBlocked"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">Админ</small></div>
            <div class="row">
              <input
                v-model="user.admin"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Админ пользователей</small>
            </div>
            <div class="row">
              <input
                v-model="user.editUsers"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">Водитель</small></div>
            <div class="row">
              <input
                v-model="user.driver"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row"><small class="text-muted">Грузчик</small></div>
            <div class="row">
              <input
                v-model="user.loader"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Менеджер по оплате</small>
            </div>
            <div class="row">
              <input
                v-model="user.payManager"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Менеджер по cкладу</small>
            </div>
            <div class="row">
              <input
                v-model="user.storeManager"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Приходный ордер</small>
            </div>
            <div class="row">
              <input
                v-model="user.orderIncome"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Перемещение</small>
            </div>
            <div class="row">
              <input
                v-model="user.transfer"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Корректировки</small>
            </div>
            <div class="row">
              <input
                v-model="user.correctionFull"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Ошибки грузчика</small>
            </div>
            <div class="row">
              <input
                v-model="user.correctionLoaderError"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>


          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Заказы клиентов</small>
            </div>
            <div class="row">
              <input
                v-model="user.clientOrders"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Заказы водителей</small>
            </div>
            <div class="row">
              <input
                v-model="user.driverOrders"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>



          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Период в Заказах</small>
            </div>
            <div class="row">
              <input
                v-model="user.orderListPeriod"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Период в Ордерах</small>
            </div>
            <div class="row">
              <input
                v-model="user.skladOrderListPeriod"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Показать время</small>
            </div>
            <div class="row">
              <input
                v-model="user.showClosedDate"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Учет товаров (только чтение)</small>
            </div>
            <div class="row">
              <input
                v-model="user.productBalanceRO"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Удалить учет товаров</small>
            </div>
            <div class="row">
              <input
                v-model="user.deleteProductBalance"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Блокнот (добавить товар)</small>
            </div>
            <div class="row">
              <input
                v-model="user.orderDraftWriteProduct"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Блокнот (проверка)</small>
            </div>
            <div class="row">
              <input
                v-model="user.orderDraftCheckProduct"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Ревизия</small>
            </div>
            <div class="row">
              <input
                v-model="user.skladRevision"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Ревизия (только чтение)</small>
            </div>
            <div class="row">
              <input
                v-model="user.skladRevisionRO"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Показать ревизию в ордерах</small>
            </div>
            <div class="row">
              <input
                v-model="user.showRevisionInSkladOrder"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Проверка ордеров</small>
            </div>
            <div class="row">
              <input
                v-model="user.checkSkladOrder"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Отчет по весу (ПРОВЕРЕНО)</small>
            </div>
            <div class="row">
              <input
                v-model="user.reportWeightChecked"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Отчет по сборке ордеров</small>
            </div>
            <div class="row">
              <input
                v-model="user.reportSkladLoaders"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Отчет по остаткам</small>
            </div>
            <div class="row">
              <input
                v-model="user.reportSkladBalance"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">ПТЗ</small>
            </div>
            <div class="row">
              <input
                v-model="user.skladPtz"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Мурманск</small>
            </div>
            <div class="row">
              <input
                v-model="user.skladMyrmansk"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Апатиты</small>
            </div>
            <div class="row">
              <input
                v-model="user.skladApatiti"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>

          <div class="col-sm">
            <div class="row">
              <small class="text-muted">Открыть закрытый ордер</small>
            </div>
            <div class="row">
              <input
                v-model="user.openClosedSkladOrder"
                type="checkbox"
                class="ms-3 form-check-input"
                @input="user.modified = true"
              />
            </div>
          </div>
        </div>

        <div class="btn-group mt-3">
          <button
            v-if="user.modified"
            class="btn btn-warning"
            @click="saveUser(user)"
          >
            Сохранить
          </button>

          <button class="btn btn-danger" @click="deleteUser(user)" disabled>
            Удалить
          </button>

          <button class="btn btn-primary" @click="getHistory(user)">
            История
          </button>
        </div>

        <div v-if="user.showHistory">
          <div v-for="(historyRow, index) in user.history" :key="index">
            <div class="row border border-1">
              <div class="col-sm">{{ dateFormat(historyRow.date) }}</div>
              <div class="col-sm">{{ historyRow.metric }}</div>
            </div>
          </div>
        </div>

        <div v-if="user.spinner" class="spinner-border text-primary"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import useUsersMixin from "@/components/usersMixin.js";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  setup() {
    useUsersMixin();
    const {
      users,
      saveUser,
      addUser,
      deleteUser,
      getUsers,
      usersFilter,
      searchUser,
    } = useUsersMixin();

    const {
      postUserHistoryByMetric,
      getUserHistory,
      dateFormat,
    } = useCommonMixin();

    const editUsers = () => {
      return JSON.parse(localStorage.getItem("editUsers"));
    };

    const getHistory = async (user) => {
      user.history = [];
      if (!user.showHistory) {
        user.spinner = true;
        let resp = await getUserHistory(user._id);

        if (resp.status) {
          user.spinner = false;
          user.history = resp.data;
          user.showHistory = true;
        }
      } else {
        user.showHistory = false;
      }
    };

    onMounted(async () => {
      await getUsers();
      postUserHistoryByMetric("Пользователи");
    });

    return {
      users,
      saveUser,
      addUser,
      deleteUser,
      usersFilter,
      searchUser,
      editUsers,
      getHistory,
      dateFormat,
    };
  },
};
</script>
