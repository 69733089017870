<template>
  <div class="container">
    <h1>Настройки</h1>
    <div class="mb-3 row">
      <label for="country" class="col-sm-3 col-form-label">Камера</label>
      <div class="col-sm-9">
        <select v-model="settings.camera" class="form-select" @change="edit=true">
          <option
            v-for="camera in cameras"
            :value="camera.id"
            :key="camera.id"
            >{{ camera.label }}</option
          >
        </select>
      </div>
    </div>
    <div id="scanner"></div>
    <button class="btn btn-warning w-100" @click="scan">Тест камеры</button>

    <div class="row">
      <div class="col-3">
        РЕЖИМ СКАНЕРА
      </div>
      <div class="col">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label class="btn btn-light">
            <input
              type="radio"
              name="options"
              value="camera"
              autocomplete="off"
              v-model="settings.scannerMode"
              @click="edit = true"
            />
            КАМЕРА
          </label>
          <label class="btn btn-light">
            <input
              type="radio"
              name="options"
              value="tsd"
              autocomplete="off"
              v-model="settings.scannerMode"
              @click="edit = true"

            />
            ТСД
          </label>
        </div>
      </div>
    </div>

    
    <div v-if="edit" class="row mt-3">
      <div class="col">
        <button class="btn btn-primary w-100" @click="saveCameraSettings()">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { Html5Qrcode } from "html5-qrcode";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  setup() {
    const {
      settings,
      getSettings,
      saveSettings,
      scan,
      scanner,
      postUserHistoryByMetric,
    } = useCommonMixin();

    const saveCameraSettings = () => {
      saveSettings()
      edit.value = false
    }

    const edit = ref(false)
    const cameras = ref([]);
    let Html5QrcodeObj = undefined;

    const getCameras = async () => {
      Html5QrcodeObj = new Html5Qrcode("scanner");
      cameras.value = await Html5Qrcode.getCameras();
    };

    onMounted(async () => {
      console.log("Html5QrcodeObj", Html5QrcodeObj);
      await getCameras();
      await getSettings();
      postUserHistoryByMetric('Настройки')
    });

    return {
      saveCameraSettings,
      cameras,
      settings,
      scan,
      scanner,
      edit,
    };
  },
};
</script>
