<template>
  <div>
    <div v-if="reportSkladBalance()" class="text-center container">
      <h1 class="mt-3">ОСТАТКИ НА СКЛАДАХ</h1>

      <div class="">
        <div
          class="btn-group w-100 text-center"
          role="group"
          aria-label="Basic example"
        >
          <button
            v-for="store in stores"
            :key="store"
            type="button"
            class="btn"
            :class="{
              'btn-outline-secondary': currentStore != store,
              'btn-secondary': currentStore == store,
            }"
            @click="currentStore = store"
          >
            <h3>{{ store }}</h3>
          </button>
        </div>

        <div class="row">
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getReportData">
              Сформировать
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="container mt-3 mb-3">
      <div class="row row-cols-1">
        <div class="col-sm flex">
          <div class=" form-check form-switch margin-auto">
            <label class="mx-1 text-muted">Показать нулевые остатки</label>

            <input
              style="width: 3rem; height: 1.5rem"
              class="form-check-input"
              type="checkbox"
              v-model="showZeroBalance"
            />
          </div>
        </div>
      </div>

      <div class="row row-cols-1">
        <div class="col-sm flex">
          <div class=" form-check form-switch margin-auto">
            <label class="mx-1 text-muted"
              >Показать больше 14 дней c проследней продажи</label
            >

            <input
              style="width: 3rem; height: 1.5rem"
              class="form-check-input"
              type="checkbox"
              v-model="showOverDaysLastSale"
            />
          </div>
        </div>
      </div>

        
        <div class="d-flex justify-content-center btn-group">
          <div class="row">
            <div class="col mt-1">
              Период истории
            </div>
            <div class="col mt-1">
              <input
                v-model="dateFrom"
                type="date"
                class="form-control"
                placeholder="Дата"
              />
            </div>
            <div class="col mt-1">
              <input
                v-model="dateTo"
                type="date"
                class="form-control"
                placeholder="Дата"
              />
            </div>
          </div>
        </div>

    </div>

    <div v-if="!spinnerLoading" class="container mt-3 mb-3">
      <input
        v-model="searchText"
        type="text"
        class="form-control "
        placeholder="Поиск"
      />

      <div class="row fw-bold border border-1 mt-3">
        <div class="col-sm">Товар</div>
        <div class="col-sm">Остаток</div>
        <div class="col-sm">Цена закупа</div>
        <div class="col-sm">Сумма</div>
        <div class="col-sm">Дней послед. продажи</div>
        <div class="col-sm"></div>
      </div>

      <div class="row fw-bold border border-1">
        <div class="col-sm">ИТОГО:</div>
        <div class="col-sm">{{ reportTotal.balance.toFixed(0) }}</div>
        <div class="col-sm"></div>
        <div class="col-sm">{{ reportTotal.sum.toFixed(0) }}</div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
      </div>

      <div
        v-for="product in reportDataFiltered"
        :key="product.guid"
        class="row  border border-1"
        :class="{'border-success': product.showHistory}"
      >
        <div class="col-sm">{{ product.name }}</div>
        <div class="col-sm">
          <span>{{ currentBalance(product) }}</span>

          <!-- <pre>{{product}}</pre> -->
        </div>
        <div class="col-sm">{{ product.priceBuy }}</div>
        <div class="col-sm">
          {{ (product.priceBuy * currentBalance(product)).toFixed(2) }}
        </div>
        <div class="col-sm fw-bold">
          <span>{{ daysLastSale(product) }}</span>
        </div>

        <div class="col-sm">
          <button class="btn btn-light" @click="showRowHistory(product)">
            История
          </button>
        </div>

        <div v-if="product.showHistory">
          <div
            v-for="(historyRow, historyRowIndex) in historyFiltered(product.rows)"
            :key="historyRowIndex"
            class="row row-cols-4 product-label border-top mt-1"
          >
            <div class="col-sm">
              {{ dateFormat(historyRow.date) }}
            </div>
            <div class="col-sm">
              {{
                historyRow.user
                  ? historyRow.user.name
                  : "Пользователь не найден"
              }}
            </div>
            <div class="col-sm">
              {{ historyRow.qty * (historyRow.income ? 1 : -1) }}
            </div>
            <div class="col-sm">
              <span v-if="historyRow.order">{{
                historyRow.order.orderNumber
              }}</span>
            </div>

            <div
              v-if="
                !('productChange' in historyRow) || !historyRow.productChange
              "
              class="col-sm fw-bold"
            >
              <span v-if="historyRow.income">{{
                historyRow.incomeType.label
              }}</span>
              <span v-if="!historyRow.income">{{
                historyRow.outcomeType.label
              }}</span>
            </div>

            <div
              v-if="'productChange' in historyRow && historyRow.productChange"
              class="col-sm fw-bold"
            >
              <span>Смена товара</span>
            </div>

            <div class="col-7">
              <span>{{ historyRow.comment }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row fw-bold border border-1">
        <div class="col-sm">ИТОГО:</div>
        <div class="col-sm">{{ reportTotal.balance.toFixed(0) }}</div>
        <div class="col-sm"></div>
        <div class="col-sm">{{ reportTotal.sum.toFixed(0) }}</div>
        <div class="col-sm"></div>
        <div class="col-sm"></div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  components: {},
  setup() {
    const spinnerLoading = ref(false);

    const reportData = ref([]);
    const {
      dateFormat,
      getReportSkladBalance,
      getProductBalanceRowsByProduct,
      postUserHistoryByMetric,
    } = useCommonMixin();

    const currentStore = ref("ПТЗ");
    const stores = ref(["ПТЗ", "МУРМАНСК", "АПАТИТЫ", "ВСЕ"]);
    const searchText = ref("");

    const showZeroBalance = ref(false);
    const showOverDaysLastSale = ref(false);

    const dateFrom = ref("");
    const dateTo = ref("");

    const getReportData = async () => {
      spinnerLoading.value = true;
      let res = await getReportSkladBalance();
      if (res.status) {
        reportData.value = res.data;
        spinnerLoading.value = false;
      }
    };

    const reportDataFiltered = computed(() => {
      let result = reportData.value;

      if (searchText.value.length > 2) {
        result = result.filter(
          (item) =>
            item.name.toLowerCase().indexOf(searchText.value.toLowerCase()) >= 0
        );
      }

      if (!showZeroBalance.value) {
        result = result.filter((item) => calcBalance(item) > 0);
      }

      if (showOverDaysLastSale.value) {
        result = result.filter((item) => {
          let daysLastSale = calcDaysLastSale(item);
          return daysLastSale > 14;
        });
      }
      return result;
    });

    const daysLastSale = computed(() => {
      return (product) => {
        return calcDaysLastSale(product);
      };
    });

    const currentBalance = computed(() => {
      return (product) => {
        return calcBalance(product);
      };
    });

    const calcDaysLastSale = (product) => {
      let result = "";
      if (!product) return result;
      if (currentStore.value == "ПТЗ" && product.productBalancePTZ)
        result = product.productBalancePTZ.daysLastSale;
      if (currentStore.value == "МУРМАНСК" && product.productBalanceMyrmansk)
        result = product.productBalanceMyrmansk.daysLastSale;
      if (currentStore.value == "АПАТИТЫ" && product.productBalanceApatity)
        result = product.productBalanceApatity.daysLastSale;
      if (currentStore.value == "ВСЕ") {
        let days = [];
        if (
          product.productBalancePTZ &&
          product.productBalancePTZ.daysLastSale > 0
        )
          days.push(product.productBalancePTZ.daysLastSale);
        if (
          product.productBalanceMyrmansk &&
          product.productBalanceMyrmansk.daysLastSale > 0
        )
          days.push(product.productBalanceMyrmansk.daysLastSale);
        if (
          product.productBalanceApatity &&
          product.productBalanceApatity.daysLastSale > 0
        )
          days.push(product.productBalanceApatity.daysLastSale);
        result = Math.min(...days);
      }

      if (result < 0 || result == Infinity) result = "";
      return result;
    };

    const calcBalance = (product) => {
      let result = 0;
      if (!product) return result;
      if (currentStore.value == "ПТЗ" && product.productBalancePTZ)
        result = product.productBalancePTZ.balance;
      if (currentStore.value == "МУРМАНСК" && product.productBalanceMyrmansk)
        result = product.productBalanceMyrmansk.balance;
      if (currentStore.value == "АПАТИТЫ" && product.productBalanceApatity)
        result = product.productBalanceApatity.balance;
      if (currentStore.value == "ВСЕ") {
        result = product.totalBalance;
      }

      return result;
    };

    const reportTotal = computed(() => {
      let result = { balance: 0, sum: 0 };
      reportDataFiltered.value.forEach((product) => {
        let balance = calcBalance(product);
        result.balance += balance;
        result.sum += balance * product.priceBuy;
      });

      return result;
    });

    const showRowHistory = async (row) => {
      row.showHistory = "showHistory" in row && row.showHistory ? false : true;
      if (row.showHistory) {
        let historyResp = await getProductBalanceRowsByProduct(row._id, currentStore.value);
        if (historyResp.status) row.rows = historyResp.data;
      }
    };

    const historyFiltered = computed(()=> {
      return (history) => {
        let result = []
        if(history) {
          result = history.filter(item =>  item.date >= dateFrom.value && item.date <= dateTo.value)
        }
        return result
      }
    })

    const reportSkladBalance = () => {
      return JSON.parse(localStorage.getItem("reportSkladBalance"));
    };

    onMounted(async () => {
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(2024, 6, 30);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);

      await getReportData();
      postUserHistoryByMetric('Остатки на складах')
    });

    return {
      getReportData,
      reportData,
      spinnerLoading,
      currentStore,
      dateFormat,
      reportDataFiltered,
      stores,
      daysLastSale,
      searchText,
      currentBalance,
      showOverDaysLastSale,
      showZeroBalance,
      reportTotal,
      showRowHistory,
      dateFrom,
      dateTo,
      historyFiltered,
      reportSkladBalance,
    };
  },
};
</script>
