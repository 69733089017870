<template>
  <div>
    <div class="text-center">
      <h1>Оприходования</h1>
      <div class="d-flex justify-content-center m-3 btn-group">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getDocs()">
              Сформировать
            </button>
            <button class="btn btn-info w-100 mt-1" @click="openDoc('__new__')">
              Добавить
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container">
      <div class="row row-cols-1 border mx-1 mt-1">
        <div class="row">
          <div class="col">Дата</div>
          <div class="col">Номер</div>
        </div>
        <div class="row">
          <div class="col">Склад</div>
          <div class="col">Комментарий</div>
        </div>
      </div>

      <div
        v-for="doc in docs"
        :key="doc._id"
        class="row row-cols-1 border mx-1 mt-1"
        @click="openDoc(doc._id)"
      >
        <div class="row">
          <div class="col">{{dateFormat(doc.date)}}</div>
          <div class="col">{{doc.number}}</div>
        </div>
        <div class="row">
          <div class="col">{{doc.store.name}}</div>
          <div class="col">{{doc.comment}}</div>
        </div>
      </div>

      <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useCommonMixin from "@/components/commonMixin.js";
import router from "../router";

export default {
  components: { },
  setup() {
    const spinnerLoading = ref(true);

    const docs = ref([]);
    const dateFrom = ref("");
    const dateTo = ref("");

    const { dateFormat, getDocsIncome } = useCommonMixin();

    const getDocs = async () => {
      docs.value = [];
      spinnerLoading.value = true;
      let result = await getDocsIncome(dateFrom.value, dateTo.value);
      if (result.status) {
        docs.value = result.data;
        spinnerLoading.value = false;
      }
    };

    const openDoc = (docId) => {
      router.push({ name: "docIncomeItem", params: { id: docId } });
    }


    onMounted(async () => {
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);
      await getDocs();
    });

    return {
      dateFrom,
      dateTo,
      getDocs,
      docs,
      spinnerLoading,
      dateFormat,
      openDoc,
    };
  },
};
</script>

<style>
.lh-20 {
  line-height: 20px;
}
</style>
