<template>
  <div :class="{ 'px-0': padd0, 'mx-0': padd0 }">
    <div
      class="accordion"
      id="accordionExample"
      v-for="order in ordersFiltered"
      :key="order._id"
    >
      <div
        v-if="
          ('reportWeightChecked' in order && !order.reportWeightChecked) ||
            !('reportWeightChecked' in order)
        "
        class="accordion-item"
      >
        <h2 class="accordion-header" :id="'heading' + order._id">
          <button
            :id="order._id"
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#collapse' + order._id"
            aria-expanded="true"
            :aria-controls="'collapse' + order._id"
          >
            <div class="row w-100">
              <div class="col">
                <b>
                  <p v-if="isOrder(order)" class="mb-0 fw-normal">
                    {{ dateFormat(order.deliveryDate).slice(0, 11) }}
                    {{ order.deliveryTimeFloor.slice(11, -3) }} -
                    {{ order.deliveryTimeCeil.slice(11, -3) }}

                    <span v-if="!order.income"
                      >Расход
                      <span class="fw-bold"
                        >({{ order.priority }})
                        <span v-if="order.selfShipp"> - Самовывоз</span>
                      </span>
                    </span>
                    <span v-else>Приход</span>
                    <span class="fw-bold">
                      - {{ order.weight.toFixed(0) }}кг -
                    </span>
                    {{ order.client }}

                    {{ order.store }}
                    - {{ order.lastLoaderName }}
                  </p>

                  <p v-if="isAkt(order)" class="mb-0 fw-bold">
                    {{ dateFormat(order.orderDate).slice(0, 11) }}

                    <span v-if="order.baseDocType == 'aktIncome'"
                      >Оприходование</span
                    >
                    <span v-if="order.baseDocType == 'aktWriteoff'"
                      >Списание</span
                    >
                    <span v-if="order.baseDocType == 'aktPeresort'"
                      >Пересортица</span
                    >
                    {{ order.store }}
                    - {{ order.author }} 

                  </p>

                  <p v-if="isAkt(order) && order.userNamePosted1С" class="mb-0 fw-normal">
                    Проведено 1C: {{order.userNamePosted1С}}, {{dateFormat(order.datePosted1C)}}
                  </p> 
                  <p v-if="isAkt(order) && order.userNamePosted" class="mb-0 fw-normal">
                    Проведено прил: {{order.userNamePosted }}, {{dateFormat(order.datePosted)}}
                  </p> 
                </b>
              </div>

              <div v-if="isAkt(order)" class="col" style="max-width: 60px;">
                <svg
                  v-if="order.isPosted"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="32"
                  height="32"
                >
                  <path
                    fill="#71f702"
                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                  />
                </svg>
              </div>
            </div>
          </button>
        </h2>

        <div
          :id="'collapse' + order._id"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + order._id"
          data-bs-parent="#accordionExample"
        >
          <!-- order data -->
          <div class="accordion-body p-1">
            <li>
              <span v-if="isOrder(order)">Номер ордера:</span>
              <span v-if="isAkt(order)">Номер акта:</span>
              {{ order.orderNumber }}
            </li>

            <li v-if="showRefundComment">
              Комментарий возврата: {{ order.commentRefund }}
            </li>
            <li v-if="order.comment">
              Комментарий сборки: {{ order.comment }}
            </li>
            <li>
              Товары. Всего: {{ order.goods.length }},
              <span v-if="isOrder(order)">
                Собрано: {{ collectedGoodsNumber(order) }}</span
              >
            </li>
            <button
              v-if="showWeightCheckBtn && isReportWeightChecked()"
              class="btn btn-warning"
              @click="setSkladOrderWeightChecked(order)"
            >
              Отметить ПРОВЕРЕНО
            </button>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead v-if="isOrder(order)">
                  <th>Наименование</th>
                  <th>Заказ</th>
                  <th>Факт</th>
                  <th>Готово</th>
                </thead>

                <thead v-if="isAkt(order)">
                  <th>Наименование</th>
                  <th>Кол-во</th>
                  <th>Цена</th>
                  <th>Сумма</th>
                </thead>

                <tbody v-if="isOrder(order)">
                  <tr
                    v-for="product in orderGoodsFilter(order)"
                    :key="product._id"
                  >
                    <td class="py-0 px-0">
                      <div class="row row-cols-1 mx-1 lh-sm">
                        <div class="col">
                          <div class="row">
                            {{ product.productName }}
                            <span
                              v-if="showRevision && product.existsInRevision"
                              class="bg-warning"
                            >
                              - РЕВИЗИЯ
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="min-width: 50px; max-width: 70px;" class="px-0">
                      {{ product.qtyOrder }}
                    </td>
                    <td style="min-width: 50px; max-width: 70px;" class="px-0">
                      {{ product.qtyFact }}
                    </td>
                    <td class="px-0" style="max-width: 150px;">
                      <div class="row">
                        <div class="col">
                          <div
                            class="
                                  form-check form-switch
                                  mb-2 ms-1
                                "
                          >
                            <input
                              style="width: 3rem; height: 1.5rem"
                              class="form-check-input"
                              type="checkbox"
                              v-model="product.collected"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody v-if="isAkt(order)">
                  <tr
                    v-for="product in orderGoodsFilter(order)"
                    :key="product._id"
                  >
                    <td class="py-0 px-0">
                      <div class="row row-cols-1 mx-1 lh-sm">
                        <div
                          v-if="order.baseDocType != 'aktPeresort'"
                          class="col"
                        >
                          <div class="row">
                            {{ product.productName }}
                          </div>
                        </div>

                        <div
                          v-if="order.baseDocType == 'aktPeresort'"
                          class="col"
                        >
                          <div class="row">
                            <span class="px-0">
                              <span class="fw-bold">-</span>
                              {{ product.productName }}
                            </span>
                          </div>
                          <div class="row">
                            <span class="px-0">
                              <span class="fw-bold">+</span>
                              {{ product.productNameIncome }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style="min-width: 60px; max-width: 70px;" class="px-0">
                      {{ product.qtyOrder }}
                    </td>
                    <td style="min-width: 50px; max-width: 70px;" class="px-0">
                      {{ product.price }}
                    </td>
                    <td style="min-width: 50px; max-width: 70px;" class="px-0">
                      {{ product.sum }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="d-flex justify-content-center m-1 btn-group"
              v-if="isAkt(order)"
            >
              <button  v-if="!order.spinner"
                type="button"
                class="btn btn-success"
                data-order="${order._id}"
                :disabled="order.isPosted"
                @click="postAkt(order)"
              >
                ПРОВЕСТИ В 1С
              </button>

              <div v-if="order.spinner"
                class="spinner-border text-primary d-flex justify-content-center"
                role="status"
              ></div>

              <p v-if="order.postingError">{{order.postingError}}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useCommonMixin from "@/components/commonMixin.js";
import { computed } from "vue";

export default {
  props: {
    orders: { type: Array, default: () => [] },
    showOnlyWeightDiff: { type: Boolean, default: false },
    padd0: { type: Boolean, default: false },
    showWeightCheckBtn: { type: Boolean, default: false },
    showRevision: { type: Boolean, default: false },
    showRefundComment: { type: Boolean, default: false },
  },
  setup(props) {
    const {
      collectedGoodsNumber,
      dateFormat,
      postSkladOrderWeightChecked,
      postSkladAkt1C,
    } = useCommonMixin();
    const ordersFiltered = computed(() => props.orders);

    const isReportWeightChecked = () => {
      return JSON.parse(localStorage.getItem("isReportWeightChecked"));
    };

    const orderGoodsFilter = computed(() => {
      return (order) => {
        let result = order.goods.filter(
          (row) => !props.showOnlyWeightDiff || row.qtyFact != row.qtyOrder
        );
        return result;
      };
    });

    const setSkladOrderWeightChecked = async (order) => {
      let data = {
        orderId: order._id,
        reportWeightChecked: true,
      };
      let response = await postSkladOrderWeightChecked(data);
      if (response.status) {
        order.reportWeightChecked = true;
      }
    };

    const isOrder = computed(() => {
      return (order) => {
        return !["aktIncome", "aktWriteoff", "aktPeresort"].includes(
          order.baseDocType
        );
      };
    });

    const isAkt = computed(() => {
      return (order) => {
        return ["aktIncome", "aktWriteoff", "aktPeresort"].includes(
          order.baseDocType
        );
      };
    });

    const postAkt = async (order) => {
      order.spinner = true;
      order.postingError = ""
      let resp = await postSkladAkt1C(order);
      console.log('postSkladAkt1C', resp);
      if (resp.status) {
        order.spinner = false;
        order.isPosted = true;
      } else {
        order.postingError = resp.error
      }
    };

    return {
      collectedGoodsNumber,
      ordersFiltered,
      dateFormat,
      orderGoodsFilter,
      setSkladOrderWeightChecked,
      isReportWeightChecked,
      isOrder,
      isAkt,
      postAkt,
    };
  },
};
</script>
