<template>
  <div>
    <div class="text-center container">
      <h1>ОТЧЕТ ПО ПОТЕРЯМ</h1>
      <div class="">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            Дата последней инвентаризации
            <input
              v-model="dateLastInventory"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <Multiselect
              v-model="currentStore"
              :options="stores"
              :loading="false"
              :internal-search="false"
              valueProp="_id"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
              :limit="10"
              placeholder="Склад"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <Multiselect
              v-model="currentLoader"
              :options="loaders"
              :loading="false"
              :internal-search="false"
              valueProp="_id"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
              :limit="10"
              placeholder="Грузчик"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getReportData">
              Сформировать
            </button>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <div class=" form-check form-switch mb-2 ms-1 ">
              <input
                style="width: 3rem; height: 1.5rem"
                class="form-check-input"
                type="checkbox"
                v-model="showAllGoods"
              />
              Показать все товары
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container mt-3">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#orders"
            aria-expanded="false"
            aria-controls="orders"
          >
            Ордера
          </button>
        </h2>
        <div
          id="orders"
          class="accordion-collapse collapse"
          data-bs-parent="#orders"
        >
          <div class="accordion-body">
            <SkladOrdersReadOnly
              :orders="reportData.orders"
              :show-only-weight-diff="!showAllGoods"
              :show-weight-check-btn="true"
              :show-revision="true"
            />
          </div>
        </div>
      </div>

      <div class="accordion-item mt-3">
        <h2 class="accordion-header">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#products"
            aria-expanded="false"
            aria-controls="products"
          >
            Товары
          </button>
        </h2>
        <div
          id="products"
          class="accordion-collapse collapse"
          data-bs-parent="#products"
        >
          <div class="accordion-body">
            <div class="row border border-1 mt-1 fw-bold">
              <div class="col-sm">Наименование</div>
              <div class="col-sm">Инвентаризация</div>
              <div class="col-sm">Грузчик</div>
              <div class="col-sm">Кол-во потерь</div>
              <div class="col-sm">Цена</div>
              <div class="col-sm">Сумма</div>
            </div>
            <div class="row border border-1 mt-1" v-for="(product, index) in reportData.totalProducts" :key="index">
              <div class="col-sm">{{product.productName}}</div>
              <div class="col-sm">№  {{product.inventoryNumber}} от {{dateFormat(product.inventoryDate).substr(0, 10)}}</div>
              <div class="col-sm">{{product.loaderName}}</div>
              <div class="col-sm">{{product.qtyLost}}</div>
              <div class="col-sm">{{product.price}}</div>
              <div class="col-sm">{{product.sum.toFixed(2)}}</div>
            </div>

            <h3 class="mt-3">ИТОГО:</h3>
            <div class="row border border-1 mt-1 fw-bold">
              <div class="col-sm"></div>
              <div class="col-sm"></div>
              <div class="col-sm"></div>
              <div class="col-sm">{{reportData.totalLost.qty.toFixed(2)}}</div>
              <div class="col-sm"></div>
              <div class="col-sm">{{reportData.totalLost.sum.toFixed(2)}}</div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import useUsersMixin from "@/components/usersMixin.js";
import Multiselect from "@vueform/multiselect";
import SkladOrdersReadOnly from "@/components/SkladOrdersReadOnly.vue";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  components: { Multiselect, SkladOrdersReadOnly },
  setup() {
    const spinnerLoading = ref(false);
    const showAllGoods = ref(false);

    const reportData = ref({ orders: [], totalProducts: [], totalLost: { qty: 0, sum: 0 } });
    const dateFrom = ref("");
    const dateTo = ref("");
    const currentLoader = ref("");
    const dateLastInventory = ref("");
    const { users, getUsers } = useUsersMixin();
    const { dateFormat, postUserHistoryByMetric } = useCommonMixin();

    const currentStore = ref("Склад №1 (ПТЗ)");
    const stores = ref([
      { name: "Все склады", _id: "" },
      { name: "Склад №1 (ПТЗ)", _id: "Склад №1 (ПТЗ)" },
      { name: "Склад №2 (МУРМАНСК)", _id: "Склад №2 (МУРМАНСК)" },
      { name: "Склад №3 (АПАТИТЫ)", _id: "Склад №3 (АПАТИТЫ)" },
    ]);

    const loaders = computed(() => {
      let usersAll = { _id: "ALL", name: "Все" };
      let usersLoaders = users.value.filter((user) => user.loader);
      usersLoaders.unshift(usersAll);
      return usersLoaders;
    });

    const getReportData = async () => {
      spinnerLoading.value = true;
      currentLoader.value = currentLoader.value ? currentLoader.value : "all";
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          `/getReportSkladWeight/loaderId/${currentLoader.value}/dateFrom/${dateFrom.value}/dateTo/${dateTo.value}/${currentStore.value}/byInventory/${dateLastInventory.value}`,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        reportData.value = await res.json();
        spinnerLoading.value = false;
      }
    };

    onMounted(async () => {
      getUsers();
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      var firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);
      dateLastInventory.value = firstDayOfLastMonth.toISOString().slice(0, 10);
      // await getReportData();
      postUserHistoryByMetric('Отчет по потерям')
    });

    return {
      dateFrom,
      dateTo,
      getReportData,
      reportData,
      spinnerLoading,
      currentLoader,
      loaders,
      showAllGoods,
      currentStore,
      stores,
      dateLastInventory,
      dateFormat,
    };
  },
};
</script>
