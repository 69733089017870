<template>
  <div class="">
    <div class="text-center">
      <h1>Оприходование</h1>
      <div class="d-flex justify-content-center m-3 btn-group"></div>
    </div>

    <div v-if="!spinnerLoading" class="container">
      <div class="row row-cols-1 mx-1 mt-1">
        <div class="col">
          <div class="row text-muted">Дата</div>
          <div class="row">
            <input
              v-model="doc.date"
              type="datetime-local"
              class="form-control"
              placeholder="Дата"
              @input="doc.edit = true"
            />
          </div>
        </div>
        <div class="col">
          <div class="row text-muted">Номер</div>
          <div class="row mx-3">{{ doc.number }}</div>
        </div>
      </div>

      <div class="row row-cols-1 mx-1 mt-1">
        <div class="col">
          <div class="row text-muted">Склад</div>
          <div class="row">
            <Multiselect
              v-model="doc.store"
              :options="stores"
              :loading="false"
              :internal-search="false"
              valueProp="_id"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
              @input="doc.edit = true"
            >
            </Multiselect>
          </div>
        </div>
      </div>

      <div class="row row-cols-1 mx-1 mt-1">
        <div class="col">
          <div class="row text-muted">Комментарий</div>
          <div class="row">
            <input
              v-model="doc.comment"
              type="text"
              class="form-control"
              @input="doc.edit = true"
            />
          </div>
        </div>
      </div>

      <div class="mt-3">
        Ячейки/Товары
        <button class="btn btn-light" @click="addPosition()">
          Добавить ячейку
        </button>
        <div
          v-for="(row, rowIndex) in doc.positions"
          :key="rowIndex"
          class="border mt-3"
        >
          <div class="row row-cols-2">
            <div class="col-sm">
              <Multiselect
                v-model="row.position"
                :options="positions"
                :loading="false"
                :internal-search="false"
                valueProp="_id"
                trackBy="name"
                label="name"
                :filterResults="true"
                :minChars="2"
                :resolveOnLoad="true"
                :searchable="true"
                noOptionsText="Не найден"
                @input="doc.edit = true"
              >
              </Multiselect>
            </div>
            <div class="col-sm">
              <button class="btn btn-light" @click="addProduct(row)">
                Добавить товар
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div
                v-for="(rowGood, rowGoodIndex) in row.goods"
                :key="rowGoodIndex"
                class="row row-cols-2"
              >
                <div class="col-9">
                  <Multiselect
                    v-model="rowGood.guid"
                    :options="products"
                    :loading="false"
                    :internal-search="false"
                    valueProp="guid"
                    trackBy="name"
                    label="name"
                    :filterResults="true"
                    :minChars="2"
                    :resolveOnLoad="true"
                    :searchable="true"
                    noOptionsText="Не найден"
                    @input="doc.edit = true"
                    placeholder="Товар"
                  >
                  </Multiselect>
                </div>
                <div class="col-3">
                  <input
                    v-model="rowGood.qty"
                    type="number"
                    class="form-control px-1"
                    @input="doc.edit = true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!doc.spinner" class=" mx-3 mt-3">
        <div class="row">
          <button v-if="doc.edit" class="btn btn-primary" @click="saveDoc()">
            Сохранить
          </button>
        </div>
        <div class="row mt-1">
          <router-link class="btn btn-secondary" :to="{ path: '/docIncomes' }"
            >НАЗАД</router-link
          >
        </div>
      </div>

      <div v-if="doc.spinner" class="d-flex justify-content-center mt-3">
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useCommonMixin from "@/components/commonMixin.js";
import Multiselect from "@vueform/multiselect";
import router from "../router";

export default {
  components: { Multiselect },
  props: { id: String },
  setup(props) {
    const spinnerLoading = ref(true);

    const stores = ref([]);
    const positions = ref([]);
    const products = ref([]);

    const doc = ref({
      date: new Date().toISOString().substring(0, 19),
      number: 0,
      comment: "",
      store: "",
    });

    const {
      dateFormat,
      getDocIncome,
      getStores,
      saveDocIncome,
      getPositions,
      getProducts,
    } = useCommonMixin();

    const getDoc = async () => {
      //doc.value = [];
      spinnerLoading.value = true;
      if (props.id && props.id != "__new__") {
        let result = await getDocIncome(props.id);
        if (result.status) {
          doc.value = result.data;
          spinnerLoading.value = false;
        }
      } else {
        spinnerLoading.value = false;
      }
    };

    const saveDoc = async () => {
      await saveDocIncome(doc.value);
      router.push({ name: "docIncomeItem", params: { id: doc.value._id } })
    };

    const addPosition = () => {
      if(!('positions' in doc.value)) doc.value.positions = []
      doc.value.positions.unshift({ position: "", goods: [] });
      doc.value.edit = true
    };

    const addProduct = (row) => {
      if(!('goods' in row)) row.goods = []
      row.goods.unshift({ guid: "", qty: 0 });
      doc.value.edit = true
    };


    onMounted(async () => {
      await getDoc();
      let responceStores = await getStores();
      if (responceStores.status) {
        stores.value = responceStores.data;
      }

      let responcePositions = await getPositions();
      if (responcePositions.status) {
        positions.value = responcePositions.data;
      }

      let responceProducts = await getProducts();
      if (responceProducts.status) {
        products.value = responceProducts.data;
      }
    });

    return {
      getDoc,
      doc,
      spinnerLoading,
      dateFormat,
      stores,
      saveDoc,
      positions,
      addPosition,
      products,
      addProduct,
    };
  },
};
</script>

<style>
.lh-20 {
  line-height: 20px;
}
</style>
