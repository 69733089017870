<template>
  <div v-if="userLoader() || userAdmin()">
    <div
      class="btn-group w-100 text-center"
      role="group"
      aria-label="Basic example"
    >
      <button
        type="button"
        class="btn"
        :class="{
          'btn-outline-secondary': orderTypeIncome || orderTypeTransfer,
          'btn-secondary': !orderTypeIncome && !orderTypeTransfer,
        }"
        @click="setOrderType(false)"
      >
        <h3>РАСХОД</h3>
      </button>
      <button
        v-if="userOrderIncome()"
        type="button"
        class="btn"
        :class="{
          'btn-outline-secondary': !orderTypeIncome,
          'btn-secondary': orderTypeIncome && !orderTypeTransfer,
        }"
        @click="setOrderType(true)"
      >
        <h3>ПРИХОД</h3>
      </button>

      <button
        v-if="isTransfer()"
        type="button"
        class="btn"
        :class="{
          'btn-outline-secondary': !orderTypeTransfer,
          'btn-secondary': orderTypeTransfer,
        }"
        @click="setOrderType('transfer')"
      >
        <h3>ПЕРЕМ</h3>
      </button>
    </div>

    <!-- Фильтр по курьерам -->
    <div v-if="userAdmin() && false" class="mb-3 d-flex justify-content-center">
      <select
        id="users"
        v-model="currentCourier"
        class="mb-1 border-1 fs-5 mx-3 form-select custom-select w-100"
      >
        <option value="">Все курьеры</option>
        <option v-for="user in users" :value="user" :key="user">
          {{ user }}
        </option>
      </select>
    </div>

    <!-- Фильтр по складам -->
    <div
      class="mb-3 mt-3 d-flex justify-content-center"
      v-if="statusFilter == 'showAll' || !orderTypeIncome"
    >
      <select
        id="stores"
        v-model="currentStore"
        class="mb-1 border-1 fs-5 mx-3 form-select custom-select w-100"
        @change="getOrders()"
      >
        <option value="ALL">Все склады</option>
        <option v-for="store in stores" :value="store" :key="store">
          {{ store }}
        </option>
      </select>
    </div>

    <!-- Фильтр по районам -->
    <div
      class="mb-3 mt-3 d-flex justify-content-center"
      v-if="!orderTypeIncome"
    >
      <select
        id="districts"
        v-model="currentDistrict"
        class="mb-1 border-1 fs-5 mx-3 form-select custom-select w-100"
      >
        <option value="">Все районы</option>
        <option v-for="district in districts" :value="district" :key="district">
          {{ district }}
        </option>
      </select>
    </div>

    <!-- Фильтр по помещениям -->
    <div
      class="mb-3 mt-3 d-flex justify-content-center"
      v-if="!orderTypeIncome"
    >
      <select
        id="rooms"
        v-model="currentRoom"
        class="mb-1 border-1 fs-5 mx-3 form-select custom-select w-100"
      >
        <option value="">Все помещения</option>
        <option v-for="room in rooms" :value="room.number" :key="room.number">
          {{ room.name }}
        </option>
      </select>
    </div>

    <!-- Фильтр по статусам -->
    <div class="d-flex justify-content-center">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-light">
          <input
            type="radio"
            name="options"
            value="showOpened"
            autocomplete="off"
            v-model="statusFilter"
          />
          Текущий
        </label>
        <label class="btn btn-light">
          <input
            type="radio"
            name="options"
            value="showClosed"
            autocomplete="off"
            v-model="statusFilter"
          />
          Закрытые
        </label>
        <label class="btn btn-light">
          <input
            type="radio"
            name="options"
            value="showAll"
            autocomplete="off"
            v-model="statusFilter"
          />
          Все
        </label>
      </div>
    </div>

    <!-- search -->
    <div
      id="searchGroup"
      class="d-flex justify-content-center m-3 btn-group"
      v-if="false"
    >
      <input
        v-model="searchText"
        type="text"
        class="form-control"
        placeholder="Поиск"
        @input="findOrders"
      />
    </div>

    <!-- Кнопка получить ордер -->
    <div>
      <div
        class="d-flex justify-content-center m-3 btn-group"
        v-if="statusFilter == 'showOpened'"
      >
        <input
          type="number"
          class="form-control px-1 w-50 fs-1"
          aria-label="Large"
          aria-describedby="inputGroup-sizing-sm"
          v-model="getOrderNumber"
        />

        <button
          type="button"
          class="btn btn-warning btn-lg fs-1 lh-sm"
          data-order="${order._id}"
          @click="buttonOnClick($event)"
          :disabled="currentOrder"
        >
          ПОЛУЧИТЬ ОРДЕР
          <div
            class="spinner-border text-primary"
            role="status"
            v-if="currentOrderLoading"
          ></div>
        </button>
      </div>
    </div>

    <!-- deliverDate -->
    <div
      class="d-flex justify-content-center m-3 btn-group"
      v-if="
        (statusFilter == 'showClosed' || statusFilter == 'showAll') &&
          !isSkladOrderListPeriod()
      "
    >
      <input
        v-model="deliverDate"
        type="date"
        class="form-control fs-5"
        placeholder="Дата доставки"
        @input="getOrders"
      />
      <button
        class="btn btn-primary"
        @click="getOrders"
        :disabled="spinnerGetOrders"
      >
        <span v-if="!spinnerGetOrders">Обновить </span>
        <span v-if="spinnerGetOrders">Идет загрузка </span>
      </button>
      <button
        v-if="false"
        class="btn btn-secondary"
        @click="clearAllOrderProductBlock()"
      >
        Снять блокировки
        <div
          class="spinner-border text-primary"
          role="status"
          v-if="clearAllOrderProductBlockSpinner"
        ></div>
      </button>
    </div>

    <div
      v-if="
        (statusFilter == 'showClosed' || statusFilter == 'showAll') &&
          isSkladOrderListPeriod()
      "
      class="d-flex justify-content-center m-3 btn-group"
    >
      <div class="row row-cols-2">
        <div class="col mt-1">
          <input
            v-model="dateFrom"
            type="date"
            class="form-control fs-5"
            placeholder="Дата"
          />
        </div>
        <div class="col mt-1">
          <input
            v-model="dateTo"
            type="date"
            class="form-control fs-5"
            placeholder="Дата"
          />
        </div>
        <div class="col mt-1">
          <button
            class="btn btn-primary w-100"
            @click="getOrders()"
            :disabled="spinnerGetOrders"
          >
            <span v-if="!spinnerGetOrders">Обновить </span>
            <span v-if="spinnerGetOrders">Идет загрузка </span>
          </button>
        </div>
      </div>
    </div>

    <!-- OrderSearchText -->
    <div class="justify-content-center m-1" v-if="statusFilter == 'showAll'">
      <div class="d-flex justify-content-center btn-group">
        <input
          v-model="orderSearchText"
          type="text"
          class="form-control fs-5 w-100"
          placeholder="Ордер"
        />
        <button
          type="button"
          class="btn btn-secondary"
          @click="clearOrderSearchText()"
        >
          Очистить
        </button>
      </div>
    </div>

    <!-- goodSearchText -->
    <div class="justify-content-center m-1" v-if="statusFilter == 'showAll'">
      <div class="d-flex justify-content-center btn-group">
        <input
          v-model="goodSearchTextTemp"
          type="text"
          class="form-control fs-5 w-100"
          placeholder="Товар"
          @change="goodSearchText = goodSearchTextTemp"
        />
        <button
          type="button"
          class="btn btn-secondary"
          @click="clearGoodSearchText()"
        >
          Очистить
        </button>
      </div>

      <table
        v-if="goodSearchOrders.length > 0"
        class="table table-bordered mx-0 px-0 mt-3"
      >
        <thead>
          <th>Наименование</th>
          <th>Заказ</th>
          <th>Факт</th>
          <th>Готово</th>
        </thead>
        <tbody v-for="order in goodSearchOrders" :key="order._id">
          <tr style="border-top: 1pt solid black">
            <td colspan="2">
              <span @click="openOrderByNumber(order)" class="fw-bold">
                {{ dateFormat(order.deliveryDate).slice(0, 11) }}
                {{ order.deliveryTimeFloor.slice(11, -3) }} -
                {{ order.deliveryTimeCeil.slice(11, -3) }}
                {{ order.client }}
              </span>
            </td>
            <td class="text-center" style="vertical-align: middle" colspan="2">
              <button
                v-if="!order.closed"
                type="button"
                class="btn btn-warning btn-sm"
                data-order="${order._id}"
                @click="buttonOnClick($event, order)"
              >
                Сохранить
                <div
                  class="spinner-border text-primary"
                  role="status"
                  v-if="order.spinner"
                ></div>
              </button>
            </td>
          </tr>
          <tr
            v-for="product in goodSearchProducts(order)"
            :key="product._id"
            @click="selectGoodSearchProduct(order._id, product._id)"
            :class="{
              'selected-product':
                isGoodSearchSelectedProduct(order._id, product._id) &&
                !addingBarcode,
              'adding-barcode-product':
                isGoodSearchSelectedProduct(order._id, product._id) &&
                addingBarcode,
            }"
          >
            <td class="py-0 px-0">
              <div class="row row-cols-1 mx-1 lh-sm">
                <div class="col mx-0 px-0">
                  <div class="row px-3">
                    <p class="mb-0">
                      {{ product.productName }}
                    </p>
                    <p class="mb-0 fw-bold">
                      {{ product.productCode }}
                    </p>
                    <span v-if="product.blockedMessage" class="text-danger">{{
                      product.blockedMessage
                    }}</span>
                  </div>

                  <div class="row mx-0 px-0 ">
                    <label
                      class="
                      col-form-label
                      text-muted
                      px-0
                      "
                    >
                      <button
                        v-if="!product.collected"
                        class="btn btn-light me-1"
                        @click="
                          product.showAllPositions = product.showAllPositions
                            ? false
                            : true
                        "
                        :disabled="
                          !isGoodSearchSelectedProduct(
                            order._id,
                            product._id
                          ) || product.collected
                        "
                      >
                        Ячейки
                      </button>
                    </label>
                  </div>
                  <div class="row mx-0 px-0 ">
                    <div
                      v-for="(position,
                      positionIndex) in product.productPositions"
                      :key="positionIndex"
                      class="row mb-1"
                    >
                      <div
                        class="btn-group w-100 px-0"
                        role="group"
                        aria-label="Basic example"
                        style="min-width: 160px;"
                        v-if="positionIndex == 0 || product.showAllPositions"
                      >
                        <div class="input-group-prepend">
                          <div class="input-group-text px-1" id="btnGroupAddon">
                            <button
                              class="btn btn-sm btn-light"
                              :disabled="
                                !isGoodSearchSelectedProduct(
                                  order._id,
                                  product._id
                                ) || product.collected
                              "
                              @click="
                                selectProductPosition(
                                  order,
                                  product._id,
                                  positionIndex
                                )
                              "
                            >
                              <span class="fw-bold fs-5">
                                {{
                                  product.productPositions[positionIndex]
                                    .positionName
                                }}
                              </span>
                            </button>
                          </div>
                        </div>

                        <input
                          v-model="
                            product.productPositions[positionIndex].total
                          "
                          disabled
                          type="number"
                          class="form-control px-1 text-center"
                          min="0"
                        />

                        <button
                          class="btn btn-outline-danger"
                          @click="
                            removeProductPosition(
                              order,
                              product._id,
                              positionIndex
                            )
                          "
                          :disabled="
                            product.collected ||
                              !isGoodSearchSelectedProduct(
                                order._id,
                                product._id
                              ) ||
                              order.closed ||
                              order.income ||
                              product.productPositions.length == 1
                          "
                        >
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td style="min-width: 50px" class="px-0">
              <input
                type="number"
                class="form-control px-1"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                v-model="product.qtyOrder"
                disabled
              />
              <span v-if="product.taraName" class="fw-bold"
                >{{ product.taraNumber }} {{ product.taraName }}</span
              >
            </td>
            <td style="min-width: 50px" class="px-0">
              <input
                type="number"
                class="form-control px-1"
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                v-model="product.qtyFact"
                :disabled="
                  !isGoodSearchSelectedProduct(order._id, product._id) ||
                    order.closed
                "
                @change="
                  fillCollectedGoodSearch(
                    goodSearchOrders,
                    order._id,
                    product._id,
                    true
                  )
                "
              />
              <span class=""> {{ productMeasure(product.productName) }} </span>
            </td>
            <td class="px-0 lh-1">
              <div
                class="
                  form-check form-switch
                  d-flex
                  justify-content-center
                  mb-3
                "
              >
                <input
                  style="width: 3rem; height: 1.5rem"
                  class="form-check-input"
                  type="checkbox"
                  v-model="product.collected"
                  @change="
                    fillCollectedGoodSearch(
                      goodSearchOrders,
                      order._id,
                      product._id
                    )
                  "
                  :disabled="
                    !isGoodSearchSelectedProduct(order._id, product._id) ||
                      order.closed
                  "
                />
              </div>
              <p class="text-center">
                ШК:
                <span class="fw-bold h3">{{ product.barcode.length }}</span>
                <button
                  class="btn btn-light"
                  @click="addingBarcode = addingBarcode ? false : true"
                  :disabled="
                    !isGoodSearchSelectedProduct(order._id, product._id)
                  "
                >
                  ШК+
                </button>
              </p>
              <span v-if="showClosedDate" class="fst-italic">
                {{ dateFormat(product.closedDate) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="goodSearchOrders.length > 0" class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#barcodes`"
          aria-expanded="true"
          :aria-controls="`collapseOne`"
        >
          Штрих-коды "{{ currentProduct.productName }}"
        </button>
      </h2>
      <div
        :id="`barcodes`"
        class="accordion-collapse collapse"
        aria-labelledby="headingOne"
        :data-bs-parent="`barcodes-accordion`"
      >
        <div class="accordion-body p-0">
          <p
            class="mb-0 mt-1"
            v-for="(barcode, index) in currentProduct.barcode"
            :key="index"
          >
            {{ barcode }}
            <button
              class="btn btn-sm btn-outline-danger"
              @click="deleteBarcode(currentProduct, index)"
            >
              Удалить
            </button>
          </p>
        </div>
      </div>
    </div>

    <!-- СКРЫТЬ/ПОКАЗАТЬ-->
    <div class="justify-content-center m-1" v-if="statusFilter == 'showAll'">
      <div class="d-flex justify-content-center btn-group">
        <button
          type="button"
          class="btn btn-secondary"
          @click="showSkladOrderGoods = showSkladOrderGoods ? false : true"
        >
          <span v-if="showSkladOrderGoods">СКРЫТЬ ВСЕ ТОВАРЫ</span>
          <span v-if="!showSkladOrderGoods">ПОКАЗАТЬ ВСЕ ТОВАРЫ </span>
        </button>
      </div>
    </div>
    <div v-if="showSkladOrderGoods">
      <SkladOrderGoods
        :delivery-date="dateFrom"
        :order-type-income="orderTypeIncome"
        :store="currentStore"
        :order-type="orderTypeCommon"
      />
    </div>

    <div class="justify-content-center m-1" v-if="statusFilter == 'showAll'">
      <div class="d-flex justify-content-center btn-group">
        <button
          type="button"
          class="btn btn-secondary"
          @click="showNewOrder = showNewOrder ? false : true"
        >
          <span v-if="showNewOrder">СКРЫТЬ НОВЫЙ ОРДЕР </span>
          <span v-if="!showNewOrder">ПОКАЗАТЬ НОВЫЙ ОРДЕР </span>
        </button>
      </div>
    </div>
    <div v-if="showNewOrder">
      <NewOrder
        v-model:clipboard-text="newOrderClipboard"
        :clipboard-active="showNewOrder && statusFilter == 'showAll'"
        :delivery-date="deliverDate"
        :show-closed-date="showClosedDate"
        :store="currentStore"
      />
    </div>
  </div>

  <div class="justify-content-center m-1" v-if="statusFilter == 'showAll'">
    <div class="d-flex justify-content-center btn-group">
      <button
        type="button"
        class="btn btn-secondary"
        @click="showOrders = showOrders ? false : true"
      >
        <span v-if="showOrders">СКРЫТЬ ОРДЕРА </span>
        <span v-if="!showOrders">ПОКАЗАТЬ ОРДЕРА </span>
      </button>
    </div>
  </div>

  <div
    class="justify-content-center m-1"
    v-if="statusFilter == 'showAll' && isShowClosedDate()"
  >
    <div class="d-flex justify-content-center btn-group">
      <button
        type="button"
        class="btn btn-secondary"
        @click="showClosedDate = showClosedDate ? false : true"
      >
        <span v-if="showClosedDate">СКРЫТЬ ВРЕМЯ ЗАКРЫТИЯ </span>
        <span v-if="!showClosedDate">ПОКАЗАТЬ ВРЕМЯ ЗАКРЫТИЯ </span>
      </button>
    </div>
  </div>

  <div
    class="justify-content-center m-1"
    v-if="statusFilter == 'showAll' && showRevisionInSkladOrder()"
  >
    <div class="d-flex justify-content-center btn-group">
      <button
        type="button"
        class="btn btn-secondary"
        @click="showRevision = showRevision ? false : true"
      >
        <span v-if="showRevision">СКРЫТЬ РЕВИЗИЮ</span>
        <span v-if="!showRevision">ПОКАЗАТЬ РЕВИЗИЮ</span>
      </button>
    </div>
  </div>

  <div
    class="justify-content-center m-1"
    v-if="statusFilter == 'showAll' && isShowClosedDate()"
  >
    <div class="d-flex justify-content-center btn-group">
      <button
        type="button"
        class="btn"
        :class="{
          'btn-secondary': goodsSort == 'product',
          'btn-outline-secondary': goodsSort != 'product',
        }"
        @click="setGoodsSort('product')"
      >
        <span>ТОВАРЫ</span>
      </button>

      <button
        type="button"
        class="btn"
        :class="{
          'btn-secondary': goodsSort == 'position',
          'btn-outline-secondary': goodsSort != 'position',
        }"
        @click="setGoodsSort('position')"
      >
        <span>ЯЧЕЙКИ</span>
      </button>
    </div>
  </div>

  <div class="m-3">
    Позиций Всего/Готово: {{ positionTotal.total }} /
    {{ positionTotal.collected }}
  </div>

  <div
    v-if="spinnerGetOrders"
    class="d-flex justify-content-center form-group ms-3 me-3 mt-3"
  >
    <div class="spinner-border text-primary" role="status"></div>
  </div>

  <!-- orders -->
  <div v-if="showOrders || statusFilter == 'showOpened'">
    <div
      v-for="task in transportTasksFiltered"
      :key="task._id"
      class="accordion"
      id="accordionExampleTask"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" :id="'heading' + task._id"></h2>
        <button
          :id="task._id"
          :style="{ 'background-color': true }"
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse' + task._id"
          aria-expanded="false"
          :aria-controls="'collapse' + task._id"
        >
          <div class="row row-cols-1">
            <div class="col fw-bold">
              {{ dateFormat(task.deliveryDate).substring(0, 10) }}
              {{ task.courier }}
              <span v-if="false" class="fs-5">({{ task.priority }})</span>
            </div>

            <div class="col fw-bold">ПРИОРИТЕТ - {{ task.priority }}</div>

            <div class="col fw-bold">
              {{ task.shippingZone }}
            </div>

            <div class="col">
              Ордеров Всего/Собрано: {{ task.orders.length }} /
              {{ task.ordersClosed }}
            </div>

            <div class="col">
              Позиций Всего/Готово: {{ positionTotalByTransportTask(task).total }} /
              {{ positionTotalByTransportTask(task).collected }}
            </div>

            <div class="col">
              Вес, кг: {{ task.weight ? task.weight.toFixed(2) : 0 }}
            </div>
          </div>
        </button>
        <div
          :id="'collapse' + task._id"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + task._id"
          data-bs-parent="#accordionExampleTask"
        >
          <!-- Task data -->
          <div class="accordion-body px-0 pt-0">
            <div>
              <div
                class="accordion"
                id="accordionExample"
                v-for="order in ordersFiltered(task)"
                :key="order._id"
              >
                <div class="accordion-item" v-if="orderVisiblity(order)">
                  <h2 class="accordion-header" :id="'heading' + order._id">
                    <button
                      :id="order._id"
                      :style="{ 'background-color': orderColor(order) }"
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapse' + order._id"
                      aria-expanded="true"
                      :aria-controls="'collapse' + order._id"
                    >
                      <div class="row w-100">
                        <div class="col">
                          <b>
                            <p class="mb-0 fw-normal">
                              <span class="fw-bold fs-3">
                                {{ order.driverPriority }} -
                              </span>

                              <span v-if="order.markDelete" class="fw-bold">
                                НЕ БРАТЬ!!!
                              </span>

                              {{ dateFormat(order.deliveryDate).slice(0, 11) }}
                              {{ order.deliveryTimeFloor.slice(11, -3) }} -
                              {{ order.deliveryTimeCeil.slice(11, -3) }}

                              <span v-if="!order.income">
                                <span
                                  v-if="order.baseDocType != 'orderTransfer'"
                                  >Расход</span
                                >
                                <span
                                  v-if="order.baseDocType == 'orderTransfer'"
                                  >Перемещ</span
                                >
                                <span
                                  v-if="order.baseDocType != 'orderTransfer'"
                                  class="fw-bold"
                                  >({{ order.priority }})
                                  <span v-if="order.selfShipp">
                                    - Самовывоз</span
                                  >
                                </span>

                                <span
                                  v-if="order.baseDocType == 'orderTransfer'"
                                  class="fw-bold"
                                  >({{ order.orderNumber }})
                                </span>
                              </span>
                              <span v-if="order.income">
                                <span
                                  v-if="
                                    order.baseDocType != 'orderIncomeByMove'
                                  "
                                  >Приход</span
                                >
                                <span
                                  v-if="
                                    order.baseDocType == 'orderIncomeByMove'
                                  "
                                  >Перемещ</span
                                >
                              </span>
                              <span class="fw-bold">
                                - {{ order.weight.toFixed(0) }}кг -
                              </span>
                              {{ order.client }}

                              {{ order.store }}
                              - {{ order.lastLoaderName }} /
                              <span v-if="'checkerName' in order">{{
                                order.checkerName
                              }}</span>
                            </p>
                            <p
                              v-if="showClosedDate"
                              class="mb-0 mt-1 fw-normal fst-italic"
                            >
                              Загружен: {{ order.createDate }}
                            </p>
                            <p
                              v-if="showClosedDate"
                              class="mb-0 fw-normal fst-italic"
                            >
                              Открыт/Закрыт на сборку:
                              {{ dateFormat(order.openDate) }} /
                              {{ dateFormat(order.closedDate) }}
                            </p>
                            <p
                              v-if="showClosedDate"
                              class="mb-0 fw-normal fst-italic"
                            >
                              Открыт/Закрыт на проверку:
                              {{ dateFormat(order.openCheckDate) }} /
                              {{ dateFormat(order.closedCheckDate) }}
                            </p>
                            <p
                              v-if="
                                showClosedDate &&
                                  order.checked &&
                                  'checkerName' in order
                              "
                              class="mb-0 fw-normal fst-italic"
                            ></p>
                          </b>
                        </div>
                        <div
                          v-if="false"
                          id="changableStatus"
                          class="col col-1"
                        >
                          <p v-if="!order.closed && !order.loadingStart">
                            {{ order.orderStatus }}
                          </p>
                          <p v-if="!order.taskDriver && order.closed">
                            {{ order.orderStatus }}:
                            {{ dateFormat(order.closedDate) }}
                          </p>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    :id="'collapse' + order._id"
                    class="accordion-collapse collapse"
                    :class="{ show: statusFilter == 'showOpened' }"
                    :aria-labelledby="'heading' + order._id"
                    data-bs-parent="#accordionExample"
                  >
                    <!-- order data -->
                    <div class="accordion-body p-1">
                      <li>Номер ордера: {{ order.orderNumber }}</li>
                      <li>
                        Товары. Всего: {{ order.goods.length }}, Собрано:
                        {{ collectedGoodsNumber(order) }}
                      </li>
                      <li v-if="'noWriteoff' in order && order.noWriteoff">
                        Довоз - не списывать
                      </li>
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <th>Наименование</th>
                            <th>Заказ</th>
                            <th>Факт</th>
                            <th>Готово</th>
                          </thead>
                          <tbody>
                            <tr v-if="order.comment && order.comment != '. '">
                              <td class="py-0 px-0 fw-bold">
                                {{ order.comment }}
                              </td>
                              <td
                                style="min-width: 50px; max-width: 70px;"
                                class="px-0"
                              ></td>
                              <td
                                style="min-width: 50px; max-width: 70px;"
                                class="px-0"
                              ></td>
                              <td class="px-0" style="max-width: 150px;">
                                <div class="row">
                                  <div class="col">
                                    <div
                                      class="
                                  form-check form-switch
                                  mb-2 ms-1
                                "
                                    >
                                      <input
                                        style="width: 3rem; height: 1.5rem"
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="order.commentChecked"
                                        :disabled="order.closed"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr
                              v-for="(product,
                              productIndex) in orderGoodsFilter(order)"
                              :key="product._id"
                              :class="{
                                'selected-product':
                                  productIndex == currentProductIndex &&
                                  !addingBarcode,
                                'adding-barcode-product':
                                  productIndex == currentProductIndex &&
                                  addingBarcode,
                              }"
                              @click="selectProduct(productIndex)"
                            >
                              <td class="py-0 px-0">
                                <div class="row row-cols-1 mx-1 lh-sm">
                                  <div class="col">
                                    <div class="row">
                                      <p class="mb-0">
                                        {{ product.productName }}
                                      </p>
                                      <p class="mb-0 fw-bold">
                                        {{ product.productCode }}
                                      </p>
                                      <span
                                        v-if="product.blockedMessage"
                                        class="text-danger"
                                        >{{ product.blockedMessage }}</span
                                      >
                                      <span
                                        v-if="
                                          showRevision &&
                                            product.existsInRevision
                                        "
                                        class="bg-warning"
                                      >
                                        ревизия -
                                        {{
                                          dateFormat(product.revisionDate)
                                        }}</span
                                      >
                                    </div>
                                    {{ getExpDate(product.productPositions) }}
                                    <label
                                      class="
                              col-form-label
                              text-muted
                              px-0
                            "
                                    >
                                      <button
                                        v-if="
                                          !product.collected &&
                                            order.baseDocType !=
                                              'orderIncomeByMove'
                                        "
                                        class="btn btn-light me-1"
                                        @click="
                                          product.showAllPositions = product.showAllPositions
                                            ? false
                                            : true
                                        "
                                        :disabled="
                                          productIndex != currentProductIndex ||
                                            product.collected ||
                                            order.closed
                                        "
                                      >
                                        Ячейки
                                      </button>
                                    </label>

                                    <button
                                      class="btn btn-light me-1"
                                      @click="setShowMarks(product)"
                                    >
                                      Марки
                                    </button>

                                    <div>
                                      <p
                                        v-for="(mark, index) in product.marks"
                                        :key="index"
                                        class="mb-0 text-bg-success"
                                        :class="{
                                          'bg-success': isMarkValid(mark),
                                          'bg-warning': !isMarkValid(mark),
                                        }"
                                      >
                                        {{ mark }}
                                      </p>
                                    </div>

                                    <div
                                      v-if="
                                        product.productPositions.length == 0 &&
                                          order.income &&
                                          order.baseDocType !=
                                            'orderIncomeByMove'
                                      "
                                    >
                                      <Multiselect
                                        v-model="product.newPositionGuid"
                                        :options="positions"
                                        :loading="false"
                                        :internal-search="false"
                                        valueProp="guid"
                                        trackBy="name"
                                        label="name"
                                        :filterResults="true"
                                        :minChars="2"
                                        :resolveOnLoad="true"
                                        :searchable="true"
                                        noOptionsText="Не найден"
                                        :disabled="
                                          productIndex != currentProductIndex ||
                                            product.collected ||
                                            order.closed
                                        "
                                        :limit="5"
                                      ></Multiselect>
                                    </div>

                                    <div
                                      v-for="(position,
                                      positionIndex) in product.productPositions"
                                      :key="positionIndex"
                                      class="row mb-1"
                                    >
                                      <div
                                        class="btn-group w-100 px-0"
                                        role="group"
                                        aria-label="Basic example"
                                        style="min-width: 160px;"
                                        v-if="
                                          (positionIndex == 0 ||
                                            product.showAllPositions) &&
                                            order.baseDocType !=
                                              'orderIncomeByMove'
                                        "
                                      >
                                        <div class="input-group-prepend">
                                          <div
                                            class="input-group-text px-1"
                                            id="btnGroupAddon"
                                          >
                                            <button
                                              class="btn btn-sm btn-light"
                                              :disabled="
                                                productIndex !=
                                                  currentProductIndex ||
                                                  product.collected
                                              "
                                              @click="
                                                selectProductPosition(
                                                  order,
                                                  product._id,
                                                  positionIndex
                                                )
                                              "
                                            >
                                              <span class="fw-bold fs-6">
                                                {{
                                                  product.productPositions[
                                                    positionIndex
                                                  ].positionName
                                                }}
                                              </span>
                                            </button>
                                          </div>
                                        </div>

                                        <input
                                          v-model="
                                            product.productPositions[
                                              positionIndex
                                            ].total
                                          "
                                          disabled
                                          type="number"
                                          class="form-control px-1 text-center"
                                          min="0"
                                        />

                                        <button
                                          class="btn btn-outline-danger"
                                          @click="
                                            removeProductPosition(
                                              order,
                                              product._id,
                                              positionIndex
                                            )
                                          "
                                          :disabled="
                                            productIndex !=
                                              currentProductIndex ||
                                              product.collected ||
                                              order.income ||
                                              product.productPositions.length ==
                                                1 ||
                                              order.closed
                                          "
                                        >
                                          -
                                        </button>
                                      </div>
                                      <p
                                        v-if="
                                          positionIndex == 0 ||
                                            product.showAllPositions
                                        "
                                      >
                                        {{
                                          product.productPositions[
                                            positionIndex
                                          ].expDate
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td
                                style="min-width: 55px; max-width: 70px;"
                                class="px-0"
                              >
                                <input
                                  type="number"
                                  class="form-control px-0"
                                  aria-label="Large"
                                  aria-describedby="inputGroup-sizing-sm"
                                  v-model="product.qtyOrder"
                                  disabled
                                />
                                <span v-if="product.taraName" class="fw-bold"
                                  >{{ product.taraNumber }}
                                  {{ product.taraName }}</span
                                >
                              </td>
                              <td
                                style="min-width: 55px; max-width: 70px;"
                                class="px-0"
                              >
                                <input
                                  type="number"
                                  class="form-control px-0"
                                  aria-label="Large"
                                  aria-describedby="inputGroup-sizing-sm"
                                  v-model="product.qtyFact"
                                  :disabled="
                                    productIndex != currentProductIndex ||
                                      (order.closed && !order.checking) ||
                                      (order.checking && product.collected)
                                  "
                                  @change="
                                    fillCollected(
                                      order.goods,
                                      productIndex,
                                      order,
                                      true
                                    )
                                  "
                                />
                                <span class="">
                                  {{
                                    productMeasure(product.productName)
                                  }}</span
                                >
                              </td>
                              <td class="px-0" style="max-width: 150px;">
                                <div class="row">
                                  <div class="col lh-1">
                                    <div
                                      class="
                                  form-check form-switch
                                  mb-2 ms-1
                                "
                                    >
                                      <input
                                        style="width: 3rem; height: 1.5rem"
                                        class="form-check-input"
                                        type="checkbox"
                                        v-model="product.collected"
                                        @change="
                                          fillCollected(
                                            order.goods,
                                            productIndex,
                                            order
                                          )
                                        "
                                        :disabled="
                                          (productIndex !=
                                            currentProductIndex ||
                                            order.closed) &&
                                            !order.checking
                                        "
                                      />
                                    </div>
                                    <span
                                      v-if="showClosedDate"
                                      class="fst-italic"
                                    >
                                      {{ dateFormat(product.closedDate) }}
                                    </span>
                                  </div>

                                  <div
                                    v-if="
                                      (product.needMarks &&
                                        order.client.indexOf('Интернет') >=
                                          0) ||
                                        product.needVSD
                                    "
                                    class="col"
                                  >
                                    <button
                                      class="btn px-1 ms-1"
                                      :class="{
                                        'btn-warning': isScanningProduct(
                                          productIndex
                                        ),
                                        'btn-danger':
                                          !isScanningProduct(productIndex) &&
                                          !isProductMarksCollected(product),
                                        'btn-success':
                                          !isScanningProduct(productIndex) &&
                                          isProductMarksCollected(product),
                                      }"
                                      data-order="${order._id}"
                                      @click="startScan(order, productIndex)"
                                      :disabled="
                                        productIndex != currentProductIndex
                                      "
                                    >
                                      <span
                                        v-if="
                                          product.needMarks &&
                                            order.client.indexOf('Интернет') >=
                                              0
                                        "
                                      >
                                        M
                                      </span>
                                      <span v-if="product.needVSD">
                                        В
                                      </span>

                                      {{
                                        "marks" in product
                                          ? product.marks.length
                                          : 0
                                      }}
                                    </button>
                                  </div>

                                  <div class="col">
                                    <button
                                      class="btn btn-light px-0 ms-1"
                                      @click="
                                        addingBarcode = addingBarcode
                                          ? false
                                          : true
                                      "
                                      :disabled="
                                        productIndex != currentProductIndex
                                      "
                                    >
                                      ШК({{ product.barcode.length }})+
                                    </button>
                                  </div>

                                  <div v-if="isCheckSkladOrder()" class="col">
                                    <div class="btn-group border">
                                      <button
                                        class="btn btn-sm"
                                        :class="{
                                          'btn-success': product.checked == '+',
                                        }"
                                        @click="
                                          checkRow(
                                            order.goods,
                                            productIndex,
                                            '+'
                                          )
                                        "
                                        :disabled="
                                          productIndex != currentProductIndex ||
                                            !order.closed
                                        "
                                      >
                                        +
                                      </button>
                                      <button
                                        class="btn btn-sm"
                                        :class="{
                                          'btn-secondary':
                                            product.checked == '0',
                                        }"
                                        @click="
                                          checkRow(
                                            order.goods,
                                            productIndex,
                                            '0'
                                          )
                                        "
                                        :disabled="
                                          productIndex != currentProductIndex ||
                                            !order.closed
                                        "
                                      >
                                        0
                                      </button>
                                      <button
                                        class="btn btn-sm"
                                        :class="{
                                          'btn-danger': product.checked == '-',
                                        }"
                                        @click="
                                          checkRow(
                                            order.goods,
                                            productIndex,
                                            '-'
                                          )
                                        "
                                        :disabled="
                                          productIndex != currentProductIndex ||
                                            !order.closed
                                        "
                                      >
                                        -
                                      </button>
                                    </div>

                                    <span
                                      v-if="showClosedDate"
                                      class="fst-italic"
                                    >
                                      {{ dateFormat(product.closedCheckDate) }}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div :id="`scanner-${order._id}`" class="scanner"></div>

                      <div
                        v-if="
                          currentOrder &&
                            currentProductIndex >= 0 &&
                            currentOrder.goods[currentProductIndex]
                        "
                      >
                        <span>
                          МАРКИ
                          {{
                            currentOrder.goods[currentProductIndex].productName
                          }}:
                        </span>
                        <p
                          v-for="(mark, index) in currentOrder.goods[
                            currentProductIndex
                          ].marks"
                          :key="index"
                          class="mb-0"
                        >
                          {{ mark }}
                        </p>
                      </div>
                    </div>

                    <div
                      v-if="statusFilter == 'showOpened'"
                      class="accordion-item"
                    >
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="`#barcodes`"
                          aria-expanded="true"
                          :aria-controls="`collapseOne`"
                        >
                          Штрих-коды "{{ currentProduct.productName }}"
                        </button>
                      </h2>
                      <div
                        :id="`barcodes`"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        :data-bs-parent="`barcodes-accordion`"
                      >
                        <div class="accordion-body p-0">
                          <p
                            class="mb-0 mt-1"
                            v-for="(barcode, index) in currentProduct.barcode"
                            :key="index"
                          >
                            {{ barcode }}
                            <button
                              class="btn btn-sm btn-outline-danger"
                              @click="deleteBarcode(currentProduct, index)"
                            >
                              Удалить
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="statusFilter == 'showOpened'"
                      class="d-flex justify-content-center form-group ms-3 me-3 mt-3"
                    >
                      <button
                        class="btn btn-sm btn-secondary"
                        data-order="${order._id}"
                        @click="buttonOnClick($event, order)"
                      >
                        СОХРАНИТЬ КОММЕНТАРИЙ
                      </button>
                      <textarea
                        type="text"
                        class="form-control"
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        v-model="order.loaderComment"
                      ></textarea>
                    </div>

                    <!-- Кнопки или спиннер -->
                    <div
                      v-if="order.spinner"
                      class="d-flex justify-content-center form-group ms-3 me-3 mt-3"
                    >
                      <div
                        class="spinner-border text-primary"
                        role="status"
                      ></div>
                    </div>

                    <div v-if="!order.spinner">
                      <div v-if="statusFilter == 'showOpened'">
                        <!-- Кнопка закрыть заказ -->
                        <div
                          class="d-flex justify-content-center m-3 btn-group"
                          v-if="!order.closed"
                        >
                          <button
                            type="button"
                            class="btn btn-success"
                            data-order="${order._id}"
                            @click="buttonOnClick($event, order)"
                            :disabled="
                              order.goods.length !=
                                collectedGoodsNumber(order) ||
                                ('comment' in order &&
                                  order.comment.length > 3 &&
                                  !order.commentChecked)
                            "
                          >
                            ЗАКРЫТЬ ОРДЕР

                            <span v-if="false && !isMarksCollected(order)">
                              - ОТСКАНИРУЙТЕ МАРКИ</span
                            >
                          </button>
                        </div>

                        <!-- Кнопка завершить проверку -->
                        <div
                          class="d-flex justify-content-center m-3 btn-group"
                          v-if="!order.checked && order.closed"
                        >
                          <button
                            type="button"
                            class="btn btn-warning"
                            data-order="${order._id}"
                            @click="buttonOnClick($event, order)"
                            :disabled="
                              order.goods.length != checkedGoodsNumber(order)
                            "
                          >
                            ЗАВЕРШИТЬ ПРОВЕРКУ
                          </button>
                        </div>

                        <div
                          class="d-flex justify-content-center m-3 btn-group"
                          v-if="!order.closed"
                        >
                          <button
                            type="button"
                            class="btn btn-light"
                            data-order="${order._id}"
                            @click="buttonOnClick($event, order)"
                          >
                            ОТКАЗАТЬСЯ ОТ ОРДЕРА
                          </button>
                        </div>
                      </div>

                      <div v-if="statusFilter == 'showAll'">
                        <div
                          class="d-flex justify-content-center m-1 btn-group"
                        >
                          <button
                            type="button"
                            class="btn btn-warning"
                            data-order="${order._id}"
                            @click="buttonOnClick($event, order)"
                            :disabled="
                              !allowToGetOrder(order) ||
                                order.closed ||
                                order.markDelete
                            "
                          >
                            <span v-if="!order.markDelete">
                              <span v-if="allowToGetOrder(order)"
                                >ВЗЯТЬ ОРДЕР</span
                              >
                              <span v-if="!allowToGetOrder(order)"
                                >ОРДЕР НА СБОРКЕ</span
                              >
                            </span>

                            <span v-if="order.markDelete">
                              НЕ БРАТЬ
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        v-if="isCheckSkladOrder() && statusFilter == 'showAll'"
                      >
                        <div
                          class="d-flex justify-content-center m-1 btn-group"
                        >
                          <button
                            type="button"
                            class="btn btn-light"
                            data-order="${order._id}"
                            @click="buttonOnClick($event, order)"
                            :disabled="!allowToCheckOrder(order)"
                          >
                            <span v-if="allowToCheckOrder(order)"
                              >ПРОВЕРИТЬ ОРДЕР</span
                            >
                            <span
                              v-if="!allowToCheckOrder(order) && order.checking"
                              >ОРДЕР НА ПРОВЕРКЕ</span
                            >
                            <span
                              v-if="
                                !allowToCheckOrder(order) &&
                                  !order.checking &&
                                  !order.checked
                              "
                              >ОРДЕР НЕ ГОТОВ К ПРОВЕРКЕ</span
                            >
                            <span
                              v-if="
                                !allowToCheckOrder(order) &&
                                  !order.checking &&
                                  order.checked
                              "
                              >ОРДЕР ПРОВЕРЕН</span
                            >
                          </button>
                        </div>
                      </div>

                      <div v-if="userStoreManager()">
                        <div
                          class="d-flex justify-content-center m-1 btn-group"
                        >
                          <button
                            type="button"
                            class="btn btn-info"
                            data-order="${order._id}"
                            @click="createRefund(order)"
                          >
                            <span v-if="!order.docCorrection"
                              >ДОБАВИТЬ ВОЗВРАТ</span
                            >
                            <span v-if="order.docCorrection"
                              >ОТКРЫТЬ ВОЗВРАТ</span
                            >
                          </button>
                        </div>
                      </div>

                      <div v-if="task.isSelfGiven">
                        <div
                          class="d-flex justify-content-center m-1 btn-group"
                        >
                          <button
                            type="button"
                            class="btn btn-primary"
                            data-order="${order._id}"
                            @click="setIsGiven(order)"
                            :disabled="order.isGiven"
                          >
                            <span v-if="!order.isGiven">ОТДАТЬ ЗАКАЗ</span>
                            <span v-if="order.isGiven">ОТДАН</span>
                          </button>
                        </div>
                      </div>

                      <div v-if="order.closed && openClosedSkladOrder()">
                        <div
                          class="d-flex justify-content-center m-1 btn-group"
                        >
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-order="${order._id}"
                            @click="openOrder(order)"
                          >
                            <span>ОТКРЫТЬ ОРДЕР</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div></div>
  <!-- <pre>
     {{ orders }} 
  </pre> -->
</template>

<script>
import { computed, ref, watch } from "vue";
import { onMounted, onUnmounted } from "vue";
//import useUsersMixin from "@/components/usersMixin.js";
import NewOrder from "@/components/NewOrder.vue";
import Multiselect from "@vueform/multiselect";
import SkladOrderGoods from "@/components/SkladOrderGoods.vue";
import useCommonMixin from "@/components/commonMixin.js";
import useScanMixin from "@/components/scanMixin.js";
import { useRouter } from "vue-router";

export default {
  components: { NewOrder, SkladOrderGoods, Multiselect },
  setup() {
    const userOrderIncome = () => {
      return JSON.parse(localStorage.getItem("isOrderIncome"));
    };

    const userStoreManager = () => {
      return JSON.parse(localStorage.getItem("isStoreManager"));
    };

    const isCheckSkladOrder = () => {
      return JSON.parse(localStorage.getItem("isCheckSkladOrder"));
    };

    const isTransfer = () => {
      return JSON.parse(localStorage.getItem("isTransfer"));
    };

    const skladPtz = () => {
      return JSON.parse(localStorage.getItem("skladPtz"));
    };

    const skladApatiti = () => {
      return JSON.parse(localStorage.getItem("skladApatiti"));
    };

    const skladMyrmansk = () => {
      return JSON.parse(localStorage.getItem("skladMyrmansk"));
    };

    const isSkladOrderListPeriod = () => {
      return JSON.parse(localStorage.getItem("isSkladOrderListPeriod"));
    };

    const openClosedSkladOrder = () => {
      return JSON.parse(localStorage.getItem("openClosedSkladOrder"));
    };

    const showRevisionInSkladOrder = () => {
      return JSON.parse(localStorage.getItem("showRevisionInSkladOrder"));
    };

    const router = useRouter();

    const {
      collectedGoodsNumber,
      checkedGoodsNumber,
      dateFormat,
      postProductToSkladOrderDraft,
      openSkladOrder,
      saveOrder,
      scrollToId,
      isMarksCollected,
      postUserHistoryByMetric,
    } = useCommonMixin();

    const deliverDate = ref("");
    const orders = ref([]);
    const statusFilter = ref("showAll");
    const tableOfResults = ref({});
    const searchText = ref("");
    const intervalId = ref("");
    const currentCourier = ref({
      name: "",
    });
    //const currentProductIndex = ref();
    const getOrderNumber = ref("");
    const currentProduct = ref([]);
    const currentOrderLoading = ref(false);
    const orderTypeIncome = ref(false);
    const orderTypeTransfer = ref(false);

    //useUsersMixin();
    //const { users } = useUsersMixin();
    const users = ref([]);
    const stores = ref([]);
    const currentStore = ref("Склад №1 (ПТЗ)");
    //const currentOrder = ref();
    const goodSearchText = ref("");
    const goodSearchTextTemp = ref("");
    const addingBarcode = ref(false);
    const positions = ref([]);
    const currentGoodSearchOrder = ref();
    const currentGoodSearchProduct = ref();
    const orderSearchText = ref("");
    const clearAllOrderProductBlockSpinner = ref(false);
    const showOrders = ref(true);
    const showNewOrder = ref(false);
    const newOrderClipboard = ref("");
    const showSkladOrderGoods = ref(false);
    const showClosedDate = ref(false);
    const currentDistrict = ref("");
    const districts = ref(["Склад №2 (МУРМАНСК)", "Склад №3 (АПАТИТЫ)"]);
    const currentRoom = ref("");
    const rooms = ref([
      {
        number: 1,
        name: "Помещение - 1",
      },
      {
        number: 2,
        name: "Помещение - 2",
      },
      {
        number: 3,
        name: "Помещение - 3",
      },
      {
        number: 4,
        name: "Помещение - 4",
      },
      {
        number: 777,
        name: "Помещение не задано",
      },
    ]);

    const goodsSort = ref("");

    const spinnerGetOrders = ref(false);

    const dateFrom = ref("");
    const dateTo = ref("");
    const showRevision = ref(false);

    const transportTasks = ref([]);

    const {
      settings,
      getSettings,
      scan,
      scanner,
      currentOrder,
      currentProductIndex,
      clearScanner,
    } = useScanMixin();

    const scanningMarks = ref(false);

    const scannerMode = computed(() => {
      let result = "camera";
      if (
        "scannerMode" in settings.value &&
        settings.value.scannerMode == "tsd"
      )
        result = "tsd";

      return result;
    });

    let Html5QrcodeObj = undefined;

    const startScan = async (order, productIndex) => {
      if (scannerMode.value == "camera") {
        // clearScanner.value =
        //   currentOrder.value && order._id == currentOrder.value._id
        //     ? false
        //     : true;

        currentOrder.value = order;
        currentProductIndex.value = productIndex;
        //clearScanner.value = true
        //if (clearScanner.value) {
        await scan();
        //}
        await scrollToId(`scanner-${order._id}`);
      }

      if (scannerMode.value == "tsd") {
        currentOrder.value = order;
        currentProductIndex.value = productIndex;
      }

      scanningMarks.value = true;
    };

    const stopScan = () => {
      scanningMarks.value = false;
    };

    const isScanningProduct = computed(() => {
      return (productIndex) => {
        return scanningMarks.value && currentProductIndex.value == productIndex;
      };
    });

    const isProductMarksCollected = computed(() => {
      return (product) => {
        return product.marks.length == product.qtyFact;
      };
    });

    const createRefund = (order) => {
      let invoiceGuid = "invoiceGuid" in order ? order.invoiceGuid : "";
      let docCorrectionId = order.docCorrection
        ? order.docCorrection
        : "__new__";
      router.push({
        name: "docCorrectionItem",
        params: {
          id: docCorrectionId,
          invoiceGuid: invoiceGuid,
          orderGuid: order.guid,
          orderObj: JSON.stringify(order),
        },
      });
    };

    const goodSearchOrders = computed(() => {
      //console.log('goodSearchOrders');
      let result = [];
      let allOrders = [];
      transportTasks.value.forEach((task) => {
        task.orders.forEach((taskOrder) => allOrders.push(taskOrder));
      });

      result = allOrders.filter(
        (order) => order.income == orderTypeIncome.value
      );
      result = result.filter((order) => {
        //поиск по наименованию
        let orderString = JSON.stringify(order).toLowerCase();
        let searchIndex = orderString.indexOf(
          goodSearchText.value.toLowerCase()
        );
        if (goodSearchText.value.length >= 3 && searchIndex >= 0) {
          return true;
        }

        //поиск по gtin
        let productIndex = order.goods.findIndex((product) => {
          return (
            product.gtin.length > 0 &&
            goodSearchText.value.toLowerCase().indexOf(product.gtin) >= 0
          );
        });
        if (productIndex >= 0) {
          return true;
        }
      });

      if (orderSearchText.value.length >= 2) {
        result = result.filter((order) => {
          return (
            JSON.stringify(order)
              .toLocaleLowerCase()
              .indexOf(orderSearchText.value.toLocaleLowerCase()) >= 0
          );
        });
      }

      return result;
    });

    const goodSearchProducts = (order) => {
      console.log("goodSearchProducts");
      let result = order.goods.filter((good) => {
        //поиск по наименованию
        let goodString = JSON.stringify(good).toLowerCase();
        let searchIndex = goodString.indexOf(
          goodSearchText.value.toLowerCase()
        );
        if (goodSearchText.value.length >= 3 && searchIndex >= 0) {
          return true;
        }

        //поиск по gtin
        return (
          good.gtin.length > 0 &&
          goodSearchText.value.toLowerCase().indexOf(good.gtin) >= 0
        );
      });
      return result;
    };

    const userAdmin = () => {
      return JSON.parse(localStorage.getItem("isAdmin"));
    };

    const userDriver = () => {
      return JSON.parse(localStorage.getItem("isDriver"));
    };

    const userLoader = () => {
      return JSON.parse(localStorage.getItem("isLoader"));
    };

    const userId = () => {
      return localStorage.getItem("userId");
    };

    const isShowClosedDate = () => {
      return JSON.parse(localStorage.getItem("isShowClosedDate"));
    };

    const allowToGetOrder = computed(() => {
      return (order) => {
        let MyrmanksApatiti = false; // Склад = ПТЗ, Клиент = Мурманкс или Апатиты
        if (
          order.store == "Склад №1 (ПТЗ)" &&
          (order.client == "Склад №2 (МУРМАНСК)" ||
            order.client == "Склад №3 (АПАТИТЫ)")
        ) {
          MyrmanksApatiti = true;
        }
        let result =
          !order.loading || order.loader == userId() || MyrmanksApatiti;
        return result;
      };
    });

    const allowToCheckOrder = computed(() => {
      return (order) => {
        let result =
          !order.checked &&
          order.closed &&
          (!order.checking || order.checkerUser == userId());
        return result;
      };
    });

    const getOrders = async () => {
      spinnerGetOrders.value = true;
      showNewOrder.value = false;
      showSkladOrderGoods.value = false;
      let res = { status: 500 };

      console.log("getOrders", currentStore.value);

      if (!isSkladOrderListPeriod()) {
        res = await fetch(
          process.env.VUE_APP_API_URL +
            //"/getSkladOrders/" + //"/getSkladOrders/" + //"/getSkladOrders/" +
            "/getTransportTasksSkladOrders/" +
            deliverDate.value +
            "/" +
            goodsSort.value +
            `?store=${currentStore.value}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa("username" + ":" + localStorage.getItem("password")),
            },
          }
        );
      }

      if (isSkladOrderListPeriod()) {
        res = await fetch(
          process.env.VUE_APP_API_URL +
            //`/getSkladOrders/byPeriod/dateFrom/${dateFrom.value}/dateTo/${dateTo.value}` +
            `/getTransportTasksSkladOrders/byPeriod/dateFrom/${dateFrom.value}/dateTo/${dateTo.value}` +
            "/" +
            goodsSort.value +
            `?store=${currentStore.value}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa("username" + ":" + localStorage.getItem("password")),
            },
          }
        );
      }

      console.log(res);
      if (res.status == 200) {
        //orders.value = await res.json();
        transportTasks.value = await res.json();
      }

      spinnerGetOrders.value = false;
      getOrdersCouriers();
      getOrdersStores();
      clearAllOrderProductBlock();
    };

    const blockOrderProduct = async (order, product, byId = false) => {
      //console.log("/blockOrderProduct");
      let result = { status: false, error: "" };
      let reqBlock = byId
        ? { orderId: order, productId: product }
        : { orderId: order._id, productId: product._id };
      //console.log('reqBlock', reqBlock);

      let res = await fetch(
        process.env.VUE_APP_API_URL + "/blockOrderProduct",
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(reqBlock),
        }
      );
      if (res.status == 200) {
        result = await res.json();
      }
      //console.log("blockOrderProduct result", result);

      return result;
    };

    const clearAllOrderProductBlock = async () => {
      clearAllOrderProductBlockSpinner.value = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/clearAllOrderProductBlock",
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      if (res.status == 200) {
        clearAllOrderProductBlockSpinner.value = false;
      }
    };

    const getOrderByNumber = async (baseDocType = "") => {
      console.log("/getSkladOrder/byNumber");
      let currentOrderTypeIncome =
        orderTypeIncome.value || baseDocType == "orderIncomeByMove";
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          "/getSkladOrder/byNumber/" +
          getOrderNumber.value +
          "/" +
          currentOrderTypeIncome +
          "/" +
          baseDocType +
          "/" +
          goodsSort.value,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      let success = false;
      if (res.status == 200) {
        currentOrder.value = await res.json();
        success = true;
      }
      return success;
    };

    const getOrderToCheckById = async (orderId) => {
      console.log("/getSkladOrderToCheck/byId");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getSkladOrderToCheck/byId/" + orderId,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log("/getSkladOrderToCheck/byId", res.status);

      let success = false;
      if (res.status == 200) {
        currentOrder.value = await res.json();
        currentOrder.value.savingCheck = true;
        success = true;
      }
      return success;
    };

    const getPositions = async () => {
      console.log("/getPositions");
      console.log(process.env.VUE_APP_API_URL + "/getPositions/");
      let res = await fetch(process.env.VUE_APP_API_URL + "/getPositions/", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      });
      console.log(res);
      if (res.status == 200) {
        positions.value = await res.json();
      }
    };

    const removeProductPosition = (order, productId, positionIndex) => {
      let productIndex = order.goods.findIndex((row) => row._id == productId);
      let productRow = order.goods[productIndex];
      let position = productRow.productPositions[positionIndex];

      if (!("writeoff" in productRow)) {
        productRow.writeoff = [];
      }

      let newWriteoff = {
        positionGuid: position.positionGuid,
        qty: position.total,
        productBalance: null,
        expDates: position.expDates,
      };

      productRow.writeoff.push(newWriteoff);
      productRow.productPositions.splice(positionIndex, 1);
    };

    const addProductPosition = (order, productId) => {
      let productIndex = order.goods.findIndex((row) => row._id == productId);
      let productRow = order.goods[productIndex];
      let newPosition = {
        positionGuid: "",
        positionName: "",
        total: 0,
        isNew: true,
      };
      productRow.productPositions.push(newPosition);
    };

    const selectProductPosition = (order, productId, positionIndex) => {
      let productIndex = order.goods.findIndex((row) => row._id == productId);
      let productRow = order.goods[productIndex];
      let position = productRow.productPositions[positionIndex];
      let positionTemp = JSON.parse(JSON.stringify(position));
      productRow.productPositions.splice(positionIndex, 1);
      productRow.productPositions.unshift(positionTemp);
      productRow.showAllPositions = false;
    };

    const changeProductPosition = async (
      order,
      productId,
      positionIndex,
      markDelete = false
    ) => {
      let productGuid = null;
      let positionGuid = null;
      let result = null;
      let _id = null;

      let productIndex = order.goods.findIndex((row) => row._id == productId);
      let product = order.goods[productIndex];
      productGuid = product.guid;
      positionGuid = product.productPositions[positionIndex].positionGuid;
      _id = product.productPositions[positionIndex]._id;

      let payload = {
        productGuid: productGuid,
        positionGuid: positionGuid,
        _id: _id,
        delete: markDelete,
      };
      console.log("_id", _id);
      console.log("productGuid, positionGuid", productGuid, positionGuid);

      let res = await fetch(
        process.env.VUE_APP_API_URL + "/saveProductPosition",
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(payload),
        }
      );

      if (res.status == 200) {
        if (!markDelete) {
          result = await res.json();
          product.productPositions[positionIndex] = result;
        }

        if (markDelete) {
          product.productPositions.splice(positionIndex, 1);
        }
      }
    };

    const returnOrder = async (order) => {
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/returnSkladOrder/" + order._id,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      let success = false;
      if (res.status == 200) {
        currentOrder.value = null;
        success = true;
      }
      return success;
    };

    const getExpDate = computed(() => {
      return (productPositions) => {
        let expDate = "";
        if (productPositions.length > 0) {
          expDate =
            "expDates" in productPositions[0] &&
            productPositions[0].expDates.length > 0
              ? productPositions[0].expDates[0]
              : "";
        }

        return expDate;
      };
    });

    watch(currentCourier, () => {
      console.log("watch");
    });

    const getOrdersCouriers = () => {
      users.value = [];
      transportTasks.value.forEach((transportTask) => {
        transportTask.orders.forEach((order) => {
          let userIndex = users.value.findIndex(
            (item) => item == order.courier
          );
          if (userIndex < 0) users.value.push(order.courier);
        });
      });
      currentCourier.value = userAdmin() ? "" : users.value[0];
    };

    const getOrdersStores = () => {
      stores.value = [];
      // orders.value.forEach((order) => {
      //   let storeIndex = stores.value.findIndex((item) => item == order.store);
      //   if (storeIndex < 0) stores.value.push(order.store);
      // });
      //currentStore.value = "";
      stores.value = [
        "Склад №1 (ПТЗ)",
        "Склад №2 (МУРМАНСК)",
        "Склад №3 (АПАТИТЫ)",
      ];
    };

    const ordersFiltered = computed(() => {
      return (transportTask) => {
        let result = [];
        if (statusFilter.value == "showOpened") {
          if (currentOrder.value) {
            let orderIndex = transportTask.orders.findIndex(
              (item) => item._id == currentOrder.value._id
            );
            if (orderIndex >= 0) result.push(currentOrder.value);
          }
        } else {
          result = transportTask.orders;

          if (currentDistrict.value && !orderTypeIncome.value) {
            result = result.filter((order) => {
              return order.client == currentDistrict.value;
            });
          }

          if (currentStore.value) {
            result = result.filter((order) => {
              return order.store == currentStore.value;
            });
          }

          let orderSearchTextLC = orderSearchText.value.toLocaleLowerCase();
          if (orderSearchText.value.length >= 2) {
            result = result.filter((order) => {
              return (
                JSON.stringify(order)
                  .toLocaleLowerCase()
                  .indexOf(orderSearchTextLC) >= 0
              );
            });
          }
        }

        // result = result.filter((order) => {
        //   if ("income" in order) {
        //     return order.income == orderTypeIncome.value;
        //   } else {
        //     return !orderTypeIncome.value;
        //   }
        // });

        result = result.filter((order) => {
          let show = false;
          let income = orderTypeIncome.value;
          let outcome = !orderTypeIncome.value && !orderTypeTransfer.value;
          let transfer = orderTypeTransfer.value;

          if (
            income &&
            order.income &&
            order.baseDocType != "orderIncomeByMove"
          )
            show = true;
          if (outcome && !order.income)
            // if (outcome && !order.income && order.baseDocType != "orderTransfer")
            show = true;
          if (transfer && !order.income && order.baseDocType == "orderTransfer")
            // Перемещение в расход
            show = true;
          if (
            transfer &&
            order.income &&
            order.baseDocType == "orderIncomeByMove"
          )
            show = true;
          return show;
        });

        result =
          statusFilter.value == "showAll" && goodSearchOrders.value.length > 0
            ? []
            : result;
        
        //result = goodSearchOrders.value.length > 0 ? [] : result;
        return result;
      };
    });

    const orderTypeCommon = computed(() => {
      let result = "";
      if (orderTypeIncome.value) result = "income";
      if (!orderTypeIncome.value && !orderTypeTransfer.value)
        result = "outcome";
      if (orderTypeTransfer.value) result = "transfer";
      return result;
    });

    const orderColor = (order) => {
      let color = "#ff9999";
      if (order.closed && order.orderStatus == "Собран") color = "#90EE90";
      if (order.closed && order.orderStatus == "Отменен") color = "#d7dbe1";
      if (order.closed && order.orderStatus == "Проверен") color = "white";
      if (order.loading) color = "#44ebe3";
      if (order.checking) color = "#ffc107";
      return color;
    };

    const orderVisiblity = (order) => {
      // Фильтр по статусам
      if (
        (statusFilter.value == "showOpened") &
        order.closed &
        !order.spinner &
        !order.checking
      )
        return false;
      if ((statusFilter.value == "showClosed") & !order.closed & !order.spinner)
        return false;

      // Поиск
      if (
        (searchText.value != "") &
        (searchText.value.length >= 3) &
        !order.search
      )
        return false;

      return true;
    };

    const fillCollected = (goods, index, order, postToDraftOnly = false) => {
      console.log("fillcollected");
      if (!postToDraftOnly) {
        //console.log('goods[index]', goods[index]);
        if (!goods[index].qtyFact && goods[index].collected) {
          goods[index].qtyFact = goods[index].qtyOrder;
        }
        //console.log('goods[index].qtyFact', goods[index].qtyFact);

        if (!goods[index].collected) {
          // order.checking - что бы шли движения в учет при изменении при проверке
          goods[index].writeoff = [];
        }

        if (goods[index].collected) {
          let CurrentTime = new Date();
          CurrentTime.setHours(CurrentTime.getHours() + 3);
          goods[index].closedDate = CurrentTime.toISOString().substring(0, 19);
        }
        goods[index].showAllPositions = false;
      }

      if (order.baseDocType != "orderIncomeByMove") {
        postProductToSkladOrderDraft({
          orderId: order._id,
          deliveryDate: order.deliveryDate,
          store: order.store,
          row: goods[index],
        });
      }

      saveOrder(order);
    };

    const checkRow = (goods, index, value) => {
      // console.log(goods[index]);
      // if (goods[index].checked) {
      goods[index].checked = value;
      let CurrentTime = new Date();
      CurrentTime.setHours(CurrentTime.getHours() + 3);
      goods[index].closedCheckDate = CurrentTime.toISOString().substring(0, 19);
      //}
    };

    const fillCollectedGoodSearch = async (
      orders,
      orderId,
      productId,
      postToDraftOnly = false
    ) => {
      //console.log(goods[index]);
      // console.log("fillCollectedGoodSearch - orders", orders);
      let orderIndex = orders.findIndex((item) => item._id == orderId);
      // console.log("orderIndex", orderIndex);

      let goods = orders[orderIndex].goods;
      let index = goods.findIndex((item) => item._id == productId);

      if (!postToDraftOnly) {
        if (!goods[index].qtyFact && goods[index].collected) {
          goods[index].qtyFact = goods[index].qtyOrder;
        }

        if (!goods[index].collected) {
          goods[index].writeoff = [];
        }

        if (goods[index].collected) {
          let CurrentTime = new Date();
          CurrentTime.setHours(CurrentTime.getHours() + 3);
          goods[index].closedDate = CurrentTime.toISOString().substring(0, 19);
        }

        goods[index].showAllPositions = false;
      }
      let order = orders[orderIndex];

      if (order.baseDocType != "orderIncomeByMove") {
        postProductToSkladOrderDraft({
          orderId: order._id,
          deliveryDate: order.deliveryDate,
          store: order.store,
          row: goods[index],
        });
      }

      await saveOrder(order);
    };

    const closeOrderButtonVisiblity = (order) => {
      return !order.closed & (order.chequeRecieved || order.bezcheka);
    };

    const openOrderButtonVisiblity = (order) => {
      // console.log("userAdmin()", userAdmin());
      return order.closed & order.bezcheka & userAdmin();
    };

    const buttonOnClick = async (event, order) => {
      if (order) {
        order.spinner = true;
      }
      let button = event.target;
      console.log(button.textContent);
      let btnTextContent = button.textContent.trim();

      if (btnTextContent == "ЗАКРЫТЬ ОРДЕР") {
        clearScanner.value = true;
        order.closed = true;
        order.closedDate = new Date();
        order.orderStatus = "Собран";
        order.loading = false;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
          currentOrder.value = null;
        }
        getOrders();
        statusFilter.value = "showAll";
        return;
      }

      if (btnTextContent == "ЗАВЕРШИТЬ ПРОВЕРКУ") {
        clearScanner.value = true;
        order.checked = true;
        order.closedCheckDate = new Date();
        order.orderStatus = "Проверен";
        order.checking = false;
        order.savingCheck = true;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
          currentOrder.value = null;
        }
        getOrders();
        statusFilter.value = "showAll";
        return;
      }

      if (btnTextContent == "ОТКРЫТЬ ОРДЕР") {
        clearScanner.value = true;
        order.closed = false;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }

      if (
        btnTextContent == "СОХРАНИТЬ КОММЕНТАРИЙ" ||
        btnTextContent == "Сохранить"
      ) {
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }

      if (btnTextContent == "ПОЛУЧИТЬ ОРДЕР") {
        currentOrderLoading.value = true;
        let status = await getOrderByNumber();
        currentOrderLoading.value = status ? false : true;
        console.log("ПОЛУЧИТЬ ОРДЕР", status);
        return;
      }

      if (btnTextContent == "ОТКАЗАТЬСЯ ОТ ОРДЕРА") {
        clearScanner.value = true;
        let status = await returnOrder(order);
        console.log("ОТКАЗАТЬСЯ ОТ ОРДЕРА", status);
        getOrders();
        statusFilter.value = "showAll";
        return;
      }

      if (btnTextContent == "ВЗЯТЬ ОРДЕР") {
        await openOrderByNumber(order);
        return;
      }

      if (btnTextContent == "ПРОВЕРИТЬ ОРДЕР") {
        await checkOrderByNumber(order);
        return;
      }
    };

    const openOrderByNumber = async (order) => {
      console.log("openOrderByNumber", order);
      if (currentOrder.value) await returnOrder(currentOrder.value);

      getOrderNumber.value = Number(order.orderNumber.split("-")[1]);

      currentOrderLoading.value = true;
      let status = await getOrderByNumber(order.baseDocType);
      currentOrderLoading.value = status ? false : true;
      if (status) {
        statusFilter.value = "showOpened";
        //getOrders();
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
        currentProduct.value = [];
        currentProductIndex.value = undefined;
      } else {
        getOrders();
      }
    };

    const checkOrderByNumber = async (order) => {
      console.log("checkOrderByNumber", order);
      if (currentOrder.value) await returnOrder(currentOrder.value);
      currentOrder.value = null;

      currentOrderLoading.value = true;
      let status = await getOrderToCheckById(order._id);
      currentOrderLoading.value = status ? false : true;

      if (status) {
        statusFilter.value = "showOpened";
        // getOrders();
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
        currentProduct.value = [];
        currentProductIndex.value = undefined;
      } else {
        getOrders();
      }
    };

    const findOrders = () => {
      if (searchText.value.length < 3) return;
      searchText.value = searchText.value.toLowerCase();
      let stringValue = "";
      for (const transportTask of transportTasks.value) {
        for (const order of transportTask.orders) {
          // console.log(order)
          order.search = false;
          for (const key in order) {
            stringValue = order[key].toString().toLowerCase();
            if (stringValue.indexOf(searchText.value) != -1) {
              order.search = true;
              // console.log(order);
            }
          }
        }
      }
    };

    onMounted(async () => {
      console.log("onMounted");
      console.log("Html5QrcodeObj", Html5QrcodeObj);
      clearScanner.value = true;
      await getSettings();

      if (scannerMode.value == "tsd") {
        document.addEventListener("paste", (event) => {
          let paste = (event.clipboardData || window.clipboardData).getData(
            "text"
          );
          handleClipboard(paste);
          event.preventDefault();
        });
      }

      let today = new Date().toISOString().slice(0, 10);
      deliverDate.value = today;
      dateFrom.value = today;
      dateTo.value = today;
      await getPositions();

      if (!userOrderIncome()) {
        orderTypeIncome.value = false;
      }

      if (skladPtz()) currentStore.value = "Склад №1 (ПТЗ)";
      if (skladApatiti() && !skladMyrmansk() && !skladPtz())
        currentStore.value = "Склад №3 (АПАТИТЫ)";
      if (skladMyrmansk() && !skladApatiti() && !skladPtz())
        currentStore.value = "Склад №2 (МУРМАНСК)";

      await getOrders();
      await postUserHistoryByMetric('Складские ордера')
    });

    const handleClipboard = (text) => {
      console.log("handleClipboard", text);
      if (statusFilter.value == "showAll") {
        if (showNewOrder.value) {
          newOrderClipboard.value = "";
          newOrderClipboard.value = text;
          return;
        }
        newOrderClipboard.value = "";

        console.log(
          currentGoodSearchOrder.value,
          currentGoodSearchProduct.value
        );
        //Добавляем ячейку
        if (
          (currentGoodSearchOrder.value > 0 ||
            currentGoodSearchOrder.value == 0) &&
          (currentGoodSearchProduct.value > 0 ||
            currentGoodSearchProduct.value == 0)
        ) {
          console.log("handleClipboard выделена позиция");
          let order = goodSearchOrders.value[currentGoodSearchOrder.value];
          //let product = order[currentGoodSearchProduct.value];

          // Это ячейка
          if (isPosition(text)) {
            addPositionGoodSearch(text);

            //Это не ячейка
          } else if (addingBarcode.value) {
            //Добавляем ШК
            addBarcode(text, order, currentGoodSearchProduct.value);
            addingBarcode.value = false;
          }

          //Добавляем текст поиска
        } else {
          goodSearchText.value = text;
          goodSearchTextTemp.value = text;
        }
      } else if (statusFilter.value == "showOpened" && text.length > 0) {
        // Это ячейка
        if (isPosition(text)) {
          addPosition(text);

          //Это не ячейка
        } else {
          if (addingBarcode.value) {
            addBarcode(text, currentOrder.value, currentProductIndex.value);
            addingBarcode.value = false;
          } else if (scanningMarks.value) {
            console.log("TSD text", text);
            if (text.length < 1) {
              // 33
              let confi = window.confirm(`Некорретная марка - ${text}`);
              console.log(confi);
              return;
            }
            if (currentOrder.value && currentProductIndex.value >= 0) {
              if (
                !(
                  "marks" in currentOrder.value.goods[currentProductIndex.value]
                )
              )
                currentOrder.value.goods[currentProductIndex.value].marks = [];
              let markIndex = currentOrder.value.goods[
                currentProductIndex.value
              ].marks.findIndex((item) => item == text);
              console.log("markIndex", markIndex);
              if (markIndex < 0) {
                currentOrder.value.goods[currentProductIndex.value].marks.push(
                  text
                );
                saveOrder(currentOrder.value);
              }
            }
          } else {
            let productIndex = currentOrder.value.goods.findIndex((product) => {
              return product.gtin.length > 0 && text.indexOf(product.gtin) >= 0;
            });

            if (productIndex < 0) {
              productIndex = currentOrder.value.goods.findIndex((product) => {
                let barcodeIndex = product.barcode.findIndex(
                  (barcodeItem) => barcodeItem == text
                );
                return barcodeIndex >= 0;
              });
            }

            if (productIndex >= 0) {
              selectProduct(productIndex);
            }
          }
        }
      }
    };

    onUnmounted(async () => {
      console.log("onUnmounted");
      clearInterval(intervalId.value);
      await clearAllOrderProductBlock();
    });

    const selectProduct = async (productIndex) => {
      console.log("selectProduct", productIndex);
      if (
        statusFilter.value == "showOpened" &&
        currentProductIndex.value != productIndex
      ) {
        let blockResult = await blockOrderProduct(
          currentOrder.value,
          currentOrder.value.goods[productIndex]
        );
        if (blockResult.status) {
          currentProduct.value = currentOrder.value.goods[productIndex];
          currentProductIndex.value = productIndex;
          currentOrder.value.goods[productIndex].blockedMessage = "";
          saveOrder(currentOrder.value);
        } else {
          currentOrder.value.goods[productIndex].blockedMessage =
            blockResult.error;
        }
        stopScan();
      }
    };

    const selectGoodSearchProduct = async (orderId, productId) => {
      let blockResult = await blockOrderProduct(orderId, productId, true);
      if (blockResult.status) {
        currentGoodSearchOrder.value = goodSeachOrderIndex(orderId);
        currentGoodSearchProduct.value = goodSeachProductIndex(
          orderId,
          productId
        );
        goodSearchOrders.value[goodSeachOrderIndex(orderId)].goods[
          goodSeachProductIndex(orderId, productId)
        ].blockedMessage = "";

        currentProduct.value =
          goodSearchOrders.value[goodSeachOrderIndex(orderId)].goods[
            goodSeachProductIndex(orderId, productId)
          ];
      } else {
        goodSearchOrders.value[goodSeachOrderIndex(orderId)].goods[
          goodSeachProductIndex(orderId, productId)
        ].blockedMessage = blockResult.error;
      }
    };

    const goodSeachProductIndex = (orderId, productId) => {
      let orderIndex = goodSeachOrderIndex(orderId);
      let productIndex = goodSearchOrders.value[orderIndex].goods.findIndex(
        (product) => product._id == productId
      );
      return productIndex;
    };

    const goodSeachOrderIndex = (orderId) => {
      let orderIndex = goodSearchOrders.value.findIndex(
        (order) => order._id == orderId
      );
      return orderIndex;
    };

    const isGoodSearchSelectedProduct = (orderId, productId) => {
      return (
        currentGoodSearchOrder.value == goodSeachOrderIndex(orderId) &&
        currentGoodSearchProduct.value ==
          goodSeachProductIndex(orderId, productId)
      );
    };

    const addBarcode = (code, order, productIndex) => {
      console.log("addBarcode", code);
      console.log("order", order);
      console.log("productIndex", productIndex);
      if (productIndex >= 0 && code.length > 0) {
        let currentProduct = order.goods[productIndex];
        console.log("currentProduct", currentProduct);
        let codeIndex = currentProduct.barcode.findIndex(
          (item) => item == code
        );
        if (codeIndex < 0) {
          currentProduct.barcode.push(code);
        }
      }
    };

    // const addBarcode = (code, order, productIndex) => {
    //   console.log("addBarcode", code);
    //   if (currentProductIndex.value >= 0 && code.length > 0) {
    //     let currentProduct =
    //       currentOrder.value.goods[currentProductIndex.value];
    //     let codeIndex = currentProduct.barcode.findIndex(
    //       (item) => item == code
    //     );
    //     if (codeIndex < 0) {
    //       currentProduct.barcode.push(code);
    //     }
    //   }
    // };

    const addPosition = (guid) => {
      let productId = currentOrder.value.goods[currentProductIndex.value]._id;
      let positionIndex = currentOrder.value.goods[
        currentProductIndex.value
      ].productPositions.findIndex(
        (positionItem) => positionItem.positionGuid == guid
      );
      if (
        positionIndex >= 0 &&
        !currentOrder.value.goods[currentProductIndex.value].collected
      ) {
        selectProductPosition(currentOrder.value, productId, positionIndex);
        currentOrder.value.goods[
          currentProductIndex.value
        ].showAllPositions = false;
      }
    };

    const addPositionGoodSearch = (guid) => {
      let product =
        goodSearchOrders.value[currentGoodSearchOrder.value].goods[
          currentGoodSearchProduct.value
        ];

      let productsPositionIndex = product.productPositions.findIndex(
        (item) => item.positionGuid == guid
      );

      if (productsPositionIndex >= 0 && !product.collected) {
        selectProductPosition(
          goodSearchOrders.value[currentGoodSearchOrder.value],
          product._id,
          productsPositionIndex
        );
        product.showAllPositions = false;
      }
    };

    const isPosition = (text) => {
      let positionIndex = positions.value.findIndex(
        (item) => item.guid == text
      );
      return positionIndex >= 0;
    };

    const clearOrderSearchText = () => {
      orderSearchText.value = "";
      clearAllOrderProductBlock();
    };

    const clearGoodSearchText = () => {
      goodSearchText.value = "";
      goodSearchTextTemp.value = "";
      clearAllOrderProductBlock();
    };

    watch(goodSearchOrders, () => {
      if (goodSearchOrders.value.length == 0) {
        currentGoodSearchOrder.value = null;
        currentGoodSearchProduct.value = null;
      }
    });

    const orderStoreList = (order) => {
      let orderStores = [];
      order.goods.forEach((productRow) => {
        productRow.positions.forEach((positionRow) => {
          let positionIndex = positions.value.findIndex(
            (posItem) => posItem.guid == positionRow
          );
          if (positionIndex >= 0) {
            let store = positions.value[positionIndex].name.split("-")[0];
            let orderStoreIndex = orderStores.findIndex(
              (orderStoreItem) => orderStoreItem == store
            );
            if (orderStoreIndex < 0) {
              orderStores.push(store);
            }
          }
        });
      });

      return orderStores.reduce((acc, curr) => acc + curr + ", ", "");
    };

    const deleteBarcode = async (productRow, barcodeIndex) => {
      let payload = {
        productGuid: productRow.guid,
        code: productRow.barcode[barcodeIndex],
      };

      let res = await fetch(process.env.VUE_APP_API_URL + "/deleteBarcode", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(payload),
      });

      if (res.status == 200) {
        console.log("barcode deleted", productRow.barcode[barcodeIndex]);
        productRow.barcode.splice(barcodeIndex, 1);
      }
    };

    const orderGoodsFilter = computed(() => {
      return (order) => {
        //console.log("orderNumber", order.orderNumber);
        //По умолчанию - все
        let result = order.goods;
        if (orderTypeIncome.value) {
          return result;
        }
        //Ячейки не определены
        if (currentRoom.value == 777) {
          console.log("Ячейки не определены", currentRoom.value);
          result = result.filter((row) => {
            let emptyPosition = true;
            row.positions.forEach((rowPosition) => {
              emptyPosition = rowPosition ? false : true;
            });
            return emptyPosition;
          });
        }

        //Ячейки заданы
        if (currentRoom.value > 0 && currentRoom.value <= 4) {
          result = result.filter((row) => {
            let rowInCurrentRoom = false;
            let rowPositions = row.positions;
            rowPositions.forEach((rowPosition) => {
              let positionIndex = positions.value.findIndex(
                (item) => item.guid == rowPosition
              );
              if (positionIndex >= 0) {
                let positionItem = positions.value[positionIndex];
                rowInCurrentRoom =
                  positionItem.name.split("-")[0] == String(currentRoom.value)
                    ? true
                    : false;
              }
            });
            //rowInCurrentRoom = true
            return rowInCurrentRoom;
          });
        }

        // if (goodsSort.value == "product") {
        //   //let resultTemp = JSON.parse(JSON.stringify(result));
        //   //let resultTemp = result
        //   result.sort((a, b) => {
        //     if (a.productName > b.productName) return 1;
        //     if (a.productName < b.productName) return -1;
        //     if (a.productName == b.productName) return 0;
        //   });
        //   //result = resultTemp;
        // }

        // if (goodsSort.value == "position") {
        //   //let resultTemp = JSON.parse(JSON.stringify(result));
        //   //let resultTemp = result
        //   result.sort((a, b) => {
        //     console.log('a, b', a.productName, b.productName);
        //     if (a.productPositions.length == 0) return 1;
        //     if (b.productPositions.length == 0) return 1;
        //     let aPositionName = a.productPositions[0].positionName;
        //     let bPositionName = b.productPositions[0].positionName;

        //     if (aPositionName > bPositionName) return 1;
        //     if (aPositionName < bPositionName) return -1;
        //     if (aPositionName == bPositionName) return 0;
        //   });
        //   //result = resultTemp;
        // }

        return result;
      };
    });

    const setOrderType = async (orderTypeIncomeValue) => {
      if (orderTypeIncomeValue == "transfer") {
        orderTypeTransfer.value = true;
        orderTypeIncome.value = false;
      } else {
        orderTypeIncome.value = orderTypeIncomeValue;
        orderTypeTransfer.value = false;
      }
      getOrders();
    };

    const productMeasure = (productName) => {
      let result = "";
      let productSplit = productName.split("-");
      if (productSplit.length >= 2) {
        result = productSplit[productSplit.length - 1];
      }
      return result;
    };

    const setGoodsSort = (value) => {
      goodsSort.value = value;
      getOrders();
    };

    const openOrder = async (order) => {
      let responce = await openSkladOrder(order._id);
      if (responce.status) {
        await getOrders();
      }
    };

    const setShowMarks = (product) => {
      product.showMarks = product.showMarks ? false : true;
    };

    const isMarkValid = computed(() => {
      return (mark) => {
        let result = false;
        let markClean = mark.replace(
          /[^a-zA-Z0-9"'.:;,<>[\]{}|~`!@#$%^&?*()_+-=]/g,
          ""
        );
        if (
          markClean.substring(16, 18) == "21" &&
          markClean.substring(0, 2) == "01"
        )
          result = true;
        return result;
      };
    });

    const setIsGiven = async (order) => {
      let confirm = window.confirm(
        `Отдать заказ ${order.client}?`
      );
      if (!confirm) {
        return;
      }

      order.isGiven = true
      saveOrder(order)
    }

    const transportTasksFiltered = computed(() => {
      let result = transportTasks.value;
      let baseDocType = "";

      if (!orderTypeTransfer.value & orderTypeIncome.value) {
        baseDocType = "orderSupplier";
      }

      if (orderTypeTransfer.value & !orderTypeIncome.value) {
        baseDocType = "orderTransfer";
      }

      result = result.filter((task) => {
        let result = false;
        if (!baseDocType) {
          result =
            !("baseDocType" in task) || task.baseDocType == "orderOutcome";
        } else {
          result = task.baseDocType == baseDocType;
        }
        return result;
      });
      return result;
    });

    const positionTotal = computed(() => {
      let result = { collected: 0, total: 0 };
      transportTasksFiltered.value.forEach((transportTask) => {
        transportTask.orders.forEach((order) => {
          order.goods.forEach((goodsRow) => {
            if (goodsRow.collected) result.collected += 1;
            result.total += 1;
          });
        });
      });

      return result;
    });

    const positionTotalByTransportTask = computed(() => {
      return (transportTask) => {
        let result = { collected: 0, total: 0 };
        transportTask.orders.forEach((order) => {
          order.goods.forEach((goodsRow) => {
            if (goodsRow.collected) result.collected += 1;
            result.total += 1;
          });
        });

        return result;
      };
    });

    return {
      deliverDate,
      orders,
      getOrders,
      orderColor,
      orderVisiblity,
      statusFilter,
      tableOfResults,
      closeOrderButtonVisiblity,
      openOrderButtonVisiblity,
      buttonOnClick,
      searchText,
      findOrders,
      userAdmin,
      dateFormat,
      users,
      currentCourier,
      ordersFiltered,
      stores,
      currentStore,
      collectedGoodsNumber,
      fillCollected,
      currentProductIndex,
      selectProduct,
      getOrderNumber,
      currentOrder,
      userDriver,
      userLoader,
      currentProduct,
      currentOrderLoading,
      orderTypeIncome,
      goodSearchText,
      goodSearchTextTemp,
      goodSearchOrders,
      goodSearchProducts,
      addingBarcode,
      positions,
      removeProductPosition,
      selectGoodSearchProduct,
      fillCollectedGoodSearch,
      openOrderByNumber,
      orderSearchText,
      currentGoodSearchOrder,
      currentGoodSearchProduct,
      clearOrderSearchText,
      isGoodSearchSelectedProduct,
      goodSeachOrderIndex,
      goodSeachProductIndex,
      blockOrderProduct,
      clearAllOrderProductBlockSpinner,
      clearAllOrderProductBlock,
      clearGoodSearchText,
      orderStoreList,
      deleteBarcode,
      showOrders,
      showNewOrder,
      newOrderClipboard,
      showSkladOrderGoods,
      currentDistrict,
      districts,
      currentRoom,
      rooms,
      orderGoodsFilter,
      allowToGetOrder,
      setOrderType,
      changeProductPosition,
      userOrderIncome,
      createRefund,
      userStoreManager,
      showClosedDate,
      isShowClosedDate,
      selectProductPosition,
      productMeasure,
      allowToCheckOrder,
      checkRow,
      checkedGoodsNumber,
      isCheckSkladOrder,
      orderTypeTransfer,
      isTransfer,
      addProductPosition,
      goodsSort,
      getExpDate,
      setGoodsSort,
      spinnerGetOrders,
      dateFrom,
      dateTo,
      isSkladOrderListPeriod,
      openClosedSkladOrder,
      openOrder,
      showRevisionInSkladOrder,
      showRevision,
      transportTasks,
      transportTasksFiltered,
      scanner,
      startScan,
      scanningMarks,
      isScanningProduct,
      isMarksCollected,
      isProductMarksCollected,
      setShowMarks,
      isMarkValid,
      orderTypeCommon,
      positionTotal,
      positionTotalByTransportTask,
      setIsGiven,
    };
  },
};
</script>

<style>
.selected-product {
  background-color: #00ff9d;
}
.adding-barcode-product {
  background-color: #e1f100a2;
}

@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro);
* {
  margin: 0px;
  padding: 0px;
}

.wrapper {
  margin: 100px 0;
}
.toggle_radio {
  position: relative;
  background: rgba(214, 205, 205, 0.068) !important;
  margin: 4px auto;
  overflow: hidden;
  padding: 0 !important;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  height: 26px;
  width: 318px;
}
.toggle_radio > * {
  float: left;
}
.toggle_radio input[type="radio"] {
  display: none;
  /*position: fixed;*/
}
.toggle_radio label {
  font: 90%/1.618 "Source Sans Pro";
  color: rgba(0, 0, 0, 0.9) !important;
  z-index: 0;
  display: block;
  width: 100px;
  height: 20px;
  margin: 3px 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1;
  /*background: rgba(0,0,0,.1);*/
  text-align: center;
  /*margin: 0 2px;*/
  /*background: blue;*/ /*make it blue*/
}
.toggle_option_slider {
  /*display: none;*/
  /*background: red;*/
  width: 100px;
  height: 20px;
  position: absolute;
  top: 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#first_toggle:checked ~ .toggle_option_slider {
  background: rgba(207, 12, 12, 0.445) !important;
  left: 3px;
}
#second_toggle:checked ~ .toggle_option_slider {
  background: rgba(143, 146, 144, 0.3) !important;
  left: 109px;
}
#third_toggle:checked ~ .toggle_option_slider {
  background: rgba(16, 221, 33, 0.507) !important;
  left: 215px;
}
</style>
