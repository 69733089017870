<template>
  <!-- NavBa r-->
  <nav
    id="navbar"
    class="navbar navbar-expand-lg navbar-light"
    style="background-color: #e3f2fd"
  >
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img
          class="img-fluid"
          src="/logo.png"
          alt=""
          width="100"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li v-if="userDriver() || userAdmin() || isDriverOrders()">
            <router-link class="dropdown-item" to="/">Заказы</router-link>
          </li>
          <li v-if="userLoader() || userAdmin()">
            <router-link class="dropdown-item" to="/skladInventoryList">Инвентаризации склада</router-link>
          </li>
          <!-- <li v-if="userLoader() || userAdmin()">
            <router-link class="dropdown-item" to="/inventoryList">Инвентаризации</router-link>
          </li> -->
          <li v-if="userAdmin() || isSkladRevision() || isSkladRevisionRO()">
            <router-link class="dropdown-item" to="/skladRevision">Ревизия</router-link>
          </li>
          <li v-if="userAdmin()">
            <router-link class="dropdown-item" to="/reportRefunds">Возвраты</router-link>
          </li>
          <li v-if="userAdmin()">
            <router-link class="dropdown-item" to="/income">Приход</router-link>
          </li>
          <li v-if="clientOrders()">
            <router-link class="dropdown-item" to="/clientOrders">Заказы клиентов</router-link>
          </li>
          <li v-if="userAdmin()">
            <router-link class="dropdown-item" to="/skladAkts">Складские акты</router-link>
          </li>
          <li v-if="userLoader() || userAdmin()">
            <router-link class="dropdown-item" to="/sklad">Складские ордера</router-link>
          </li>
          <li v-if="reportSkladLoaders()">
            <router-link class="dropdown-item" to="/reportSkladLoaders">Отчет по сборке ордеров</router-link>
          </li>
          <li v-if="userStoreManager() || userAdmin()">
            <router-link class="dropdown-item" to="/reportSkladLost">Отчет по потерям</router-link>
          </li>
          <li v-if="reportSkladBalance()">
            <router-link class="dropdown-item" to="/reportSkladBalance">Оcтатки на складах</router-link>
          </li>

          <li v-if="(userStoreManager() || userAdmin() || isСorrectionFull())">
            <router-link class="dropdown-item" to="/docCorrections">Корректировки документов</router-link>
          </li>
          <li v-if="userStoreManager() || userAdmin() || isProductBalanceRO()">
            <router-link class="dropdown-item" to="/productBalance">Учет товаров</router-link>
          </li>
          <li v-if="editUsers()">
            <router-link class="dropdown-item" to="/users"
              >Пользователи</router-link
            >
          </li>
          <li v-if="userDriver() || userAdmin()">
            <router-link class="dropdown-item" to="/kkmtasklist"
              >Чеки</router-link
            >
          </li>
          <li>
            <router-link class="dropdown-item" to="/settings"
              >Настройки</router-link
            >
          </li>
          <li>
            <div
              class="justify-content-center form-group"
              v-if="!authenticated"
            >
            
              <input
                v-model="password"
                type="text"
                class="form-control"
                placeholder="Код доступа"
              />
            </div>

            <div class="d-flex justify-content-center btn-group">
              <button id="loginButton" class="btn btn-primary" @click="login">
                {{ loginLabel }}
              </button>
              <button id="logOutButton" class="btn btn-danger" @click="logout">
                Выйти
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";

export default {
  setup() {
    const authenticated = ref(false);
    const loginLabel = ref("Войти");
    const username = ref("");
    const password = ref("");
    
    const userAdmin = () => {
      return JSON.parse(localStorage.getItem("isAdmin"));
    };

    const userDriver = () => {
      return JSON.parse(localStorage.getItem("isDriver"));
    };

    const userLoader = () => {
      return JSON.parse(localStorage.getItem("isLoader"));
    };

    const userPayManager = () => {
      return JSON.parse(localStorage.getItem("isPayManager"));
    };

    const userStoreManager = () => {
      return JSON.parse(localStorage.getItem("isStoreManager"));
    };

    const userOrderIncome = () => {
      return JSON.parse(localStorage.getItem("isOrderIncome"));
    };

    const isDriverOrders = () => {
      return JSON.parse(localStorage.getItem("isDriverOrders"));
    };
    
    const isСorrectionFull = () => {
      return JSON.parse(localStorage.getItem("isСorrectionFull"));
    };

    const isShowClosedDate = () => {
      return JSON.parse(localStorage.getItem("isShowClosedDate"));
    };

    const isProductBalanceRO = () => {
      return JSON.parse(localStorage.getItem("isProductBalanceRO"));
    };

    const isSkladRevision = () => {
      return JSON.parse(localStorage.getItem("isSkladRevision"));
    };

    const isSkladRevisionRO = () => {
      return JSON.parse(localStorage.getItem("isSkladRevisionRO"));
    };

    const editUsers = () => {
      return JSON.parse(localStorage.getItem("editUsers"));
    };

    const reportSkladLoaders = () => {
      return JSON.parse(localStorage.getItem("reportSkladLoaders"));
    };

    const clientOrders = () => {
      return JSON.parse(localStorage.getItem("clientOrders"));
    };

    const reportSkladBalance = () => {
      return JSON.parse(localStorage.getItem("reportSkladBalance"));
    };



    const login = async () => {
      if (!authenticated.value) {
        localStorage.setItem("username", username.value);
        localStorage.setItem("password", password.value);
        checkAuth();
        document.location.reload()
      }
    };

    const checkAuth = async () => {
      console.log("/login");
      let res = await fetch(process.env.VUE_APP_API_URL + "/login", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa(
              'user'+
                ":" +
                localStorage.getItem("password")
            ),
        },
      });
      loginLabel.value = "Войти";
      authenticated.value = false;
      console.log(res.status);
      if (res.status == 200) {
        let response = await res.json()
        console.log('response', response);
        loginLabel.value = response.name;
        authenticated.value = true;
        localStorage.setItem("username", loginLabel.value);
        localStorage.setItem("isAdmin", response.admin);
        localStorage.setItem("isDriver", response.driver);
        localStorage.setItem("isLoader", response.loader);
        localStorage.setItem("isPayManager", response.payManager);
        localStorage.setItem("isStoreManager", response.storeManager);
        localStorage.setItem("isOrderIncome", response.orderIncome);
        localStorage.setItem("isСorrectionFull", response.correctionFull);
        localStorage.setItem("isСorrectionLoaderError", response.correctionLoaderError);
        localStorage.setItem("isDriverOrders", response.driverOrders);
        localStorage.setItem("userId", response._id);
        localStorage.setItem("isShowClosedDate", response.showClosedDate);
        localStorage.setItem("isProductBalanceRO", response.productBalanceRO);
        localStorage.setItem("isOrderDraftWriteProduct", response.orderDraftWriteProduct);
        localStorage.setItem("isOrderDraftCheckProduct", response.orderDraftCheckProduct);
        localStorage.setItem("isCheckSkladOrder", response.checkSkladOrder);
        localStorage.setItem("isSkladRevision", response.skladRevision);
        localStorage.setItem("isSkladRevisionRO", response.skladRevisionRO);
        localStorage.setItem("isTransfer", response.transfer);
        localStorage.setItem("isDeleteProductBalance", response.deleteProductBalance);
        localStorage.setItem("isOrderListPeriod", response.orderListPeriod);
        localStorage.setItem("isSkladOrderListPeriod", response.skladOrderListPeriod);
        localStorage.setItem("isReportWeightChecked", response.reportWeightChecked);
        localStorage.setItem("skladPtz", response.skladPtz);
        localStorage.setItem("skladApatiti", response.skladApatiti);
        localStorage.setItem("skladMyrmansk", response.skladMyrmansk);
        localStorage.setItem("openClosedSkladOrder", response.openClosedSkladOrder);
        localStorage.setItem("showRevisionInSkladOrder", response.showRevisionInSkladOrder);
        localStorage.setItem("editUsers", response.editUsers);
        localStorage.setItem("reportSkladLoaders", response.reportSkladLoaders);
        localStorage.setItem("clientOrders", response.clientOrders);
        localStorage.setItem("reportSkladBalance", response.reportSkladBalance);
      }
    };

    const logout = async () => {
      console.log("logout");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("isDriver");
      localStorage.removeItem("isLoader");
      localStorage.removeItem("isPayManager");
      localStorage.removeItem("isOrderIncome");
      localStorage.removeItem("isСorrectionFull");
      localStorage.removeItem("isСorrectionLoaderError");
      localStorage.removeItem("isDriverOrders");
      localStorage.removeItem("userId");
      localStorage.removeItem("isShowClosedDate");
      localStorage.removeItem("isProductBalanceRO");
      localStorage.removeItem("isOrderDraftWriteProduct");
      localStorage.removeItem("isOrderDraftCheckProduct");
      localStorage.removeItem("isCheckSkladOrder");
      localStorage.removeItem("isSkladRevision");
      localStorage.removeItem("isSkladRevisionRO");
      localStorage.removeItem("isTransfer");
      localStorage.removeItem("isDeleteProductBalance");
      localStorage.removeItem("isOrderListPeriod");
      localStorage.removeItem("isSkladOrderListPeriod");
      localStorage.removeItem("isReportWeightChecked");
      localStorage.removeItem("skladMyrmansk");
      localStorage.removeItem("skladApatiti");
      localStorage.removeItem("skladPtz");
      localStorage.removeItem("openClosedSkladOrder");
      localStorage.removeItem("showRevisionInSkladOrder");
      localStorage.removeItem("editUsers");
      localStorage.removeItem("reportSkladLoaders");
      localStorage.removeItem("clientOrders");
      localStorage.removeItem("reportSkladBalance");
      checkAuth();
      document.location.reload()
    };

    onMounted(async () => {
      await checkAuth();
    });

    return {
      authenticated,
      username,
      password,
      login,
      logout,
      loginLabel,
      userAdmin,
      userDriver,
      userLoader,
      userPayManager,
      userStoreManager,
      userOrderIncome,
      isDriverOrders,
      isСorrectionFull,
      isShowClosedDate,
      isProductBalanceRO,
      isSkladRevision,
      isSkladRevisionRO,
      editUsers,
      reportSkladLoaders,
      clientOrders,
      reportSkladBalance,
    };
  },
};
</script>
