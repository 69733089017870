<template>
  <div>
    <div class="text-center">
      <h1>ИНВЕНТАРИЗАЦИЯ СКЛАДА</h1>
    </div>

    <div v-if="!spinnerLoading" class="container lh">
      <div class="row">
        <div class="col">
          <input
            v-model="doc.date"
            type="datetime-local"
            class="form-control"
            placeholder="Дата"
          />
        </div>
        <div class="col">
          <input
            v-model="doc.number"
            type="text"
            class="form-control"
            placeholder="Номер"
            disabled
          />
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <Multiselect
            v-model="doc.store"
            :options="stores"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="name"
            label="name"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
          >
          </Multiselect>
        </div>
      </div>

      <div class="row mt-3"></div>

      <div class="row row-cols-3">
        <div class="col mt-1">
          Не показывать предыдущие за период:
          <input
              style="width: 1rem; height: 1.0rem"
              class="form-check-input"
              type="checkbox"
              v-model="doc.hideGoodsInPeriod"
            />
        </div>

        <div class="col mt-1">
          <input
            v-model="doc.dateFromHide"
            type="date"
            class="form-control"
            placeholder="Дата"
          />
        </div>
        <div class="col mt-1">
          <input
            v-model="doc.dateToHide"
            type="date"
            class="form-control"
            placeholder="Дата"
          />
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <input
            v-model="doc.comment"
            type="text"
            class="form-control"
            placeholder="Комментарий"
          />
        </div>
        <div class="col">
          <span v-if="doc.user && doc.user.name">{{ doc.user.name }}</span>
        </div>
      </div>
      <div v-if="doc && doc.goods" class="row mt-1 mx-1">
        Дата остатков из 1C:
        {{ dateBalance }}
      </div>

      <div v-if="!spinnerSave" class="mt-2">
        <div class="row">
          <span class="text-danger">{{ errorSaving }}</span>
        </div>
        <div class="d-flex justify-content-center btn-group">
          <button
            class="btn btn-primary w-100"
            @click="saveDoc()"
            :disabled="doc.closed"
          >
            СОХРАНИТЬ
          </button>
          <button
            class="btn btn-warning w-100"
            @click="getStoreBalance()"
            :disabled="!getStoreBalanceActive || doc.closed"
          >
            СОХР И ЗАПОЛНИТЬ
          </button>
          <button
            class="btn btn-info w-100"
            @click="closeDoc()"
            :disabled="!getStoreBalanceActive || doc.closed"
          >
            ЗАВЕРШИТЬ
          </button>
        </div>

        <router-link
          class="btn btn-secondary w-100 mt-1"
          :to="{ name: 'skladInventoryList' }"
          >НАЗАД</router-link
        >
        <div class="d-flex justify-content-center btn-group mt-1">
          <input
            v-model="searchTextTemp"
            type="text"
            class="form-control "
            placeholder="Поиск"
            @change="searchText = searchTextTemp"
            @focus="searchTextFocused = true"
            @blur="searchTextFocused = false"
          />

          <button class="btn btn-outline-secondary" @click="clearSearchText()">
            ОЧИСТИТЬ
          </button>
        </div>

        <div class="row mt-3">
          <div class="col">
            <input
              style="width: 1rem; height: 1.0rem"
              class="form-check-input"
              type="checkbox"
              v-model="showNullBalance"
            />
            Показать нулевые остатки
          </div>
        </div>

        <div class="row  mt-3">
          <div class="col-sm">
            <label for="daysLastSale">Не продавался более:</label>

            <input
              id="daysLastSale"
              class="form-control"
              type="number"
              v-model="daysLastSale"
            />
          </div>
        </div>

        <div class="d-flex justify-content-center btn-group mt-1">
          <button
            class="btn btn-light mx-1"
            @click="addRow()"
            :disabled="doc.closed"
          >
            Строка +
          </button>
          <button
            class="btn btn-secondary mx-1"
            @click="hideShowPositionArtikul()"
          >
            Показать Ячейку/Артикул
          </button>
          <button v-if="false" class="btn btn-light mx-1" @click="addProduct()">
            Номенклатура +
          </button>
        </div>
      </div>
      <div v-if="spinnerSave" class="d-flex justify-content-center mt-3">
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
        ></div>
      </div>

      <div class="mt-2">
        <div class="row fw-bold">
          <div class="col-5 ">Номенклатура</div>

          <div class="col">
            <div class="row">Ост 1С</div>
            <div class="row">Ост факт</div>
          </div>

          <div class="col">
            <div class="row">
              <div class="col" v-if="showPositionArtikul">Артикул</div>
              <div class="col">Дней прод</div>
            </div>
            <div class="row" v-if="showPositionArtikul">
              <div class="col">Ячейки</div>
            </div>
          </div>
        </div>

        <div class="row  ">
          <div class="col">
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('up')"
            >
              Показать еще
            </button>
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('start')"
            >
              В начало
            </button>
          </div>
        </div>

        <div>
          <div
            v-for="goodsRow in docGoodsFilter"
            :key="goodsRow._id"
            :id="`inventory-row-${goodsRow._id}`"
            class="row cols-4 border mb-2"
            @click="selectRow(goodsRow._id)"
            :class="{
              'selected-row':
                goodsRow._id == currentRowId && !fillProductByBarcode,
              'adding-barcode-product':
                goodsRow._id == currentRowId && addingBarcode,
              'fill-product-by-barcode':
                goodsRow._id == currentRowId && fillProductByBarcode,
            }"
          >
            <div class="col-5 px-1">
              <div class="row p-1 ms-1">
                <span v-if="!goodsRow.newRow">{{ goodsRow.productName }}</span>
                <div v-if="goodsRow.newRow">
                  <Multiselect
                    v-model="goodsRow.product"
                    :options="products"
                    :loading="false"
                    :internal-search="false"
                    valueProp="_id"
                    trackBy="name"
                    label="name"
                    :filterResults="true"
                    :minChars="2"
                    :resolveOnLoad="true"
                    :searchable="true"
                    noOptionsText="Не найден"
                    :limit="10"
                    placeholder="Товар"
                    :class="{ 'product-input-wide': isMobile }"
                    @change="onProductChange(goodsRow)"
                  >
                    <template v-slot:singlelabel="option">
                      <div class="multiselect-single-label">
                        <span
                          class="multiselect-single-label-text1 single-label"
                          >{{ option.value.name }}</span
                        >
                      </div>
                    </template>
                  </Multiselect>

                  <button
                    v-if="false"
                    class="btn btn-secondary btn-sm"
                    @click="
                      fillProductByBarcode = fillProductByBarcode ? false : true
                    "
                  >
                    Заполнить по ШК
                  </button>
                </div>
              </div>
              <div class="row p-1 ms-1">
                <div
                  class="
                  form-check form-switch
                  d-flex
                  justify-content-start
                  mb-3
                "
                >
                  <input
                    style="width: 3rem; height: 1.5rem"
                    class="form-check-input"
                    type="checkbox"
                    v-model="goodsRow.collected"
                    :disabled="goodsRow.newRow || doc.closed"
                  />
                </div>
              </div>
            </div>

            <div class="col ps-2 fw-bold">
              <div class="row px-0">
                <input
                  v-model="goodsRow.balance"
                  type="number"
                  class="form-control px-0"
                  placeholder=""
                  disabled
                />
              </div>
              <div class="row px-0">
                <input
                  v-model="goodsRow.balanceFact"
                  type="number"
                  class="form-control px-0"
                  placeholder=""
                  @change="setBalanceFactToPositions(goodsRow)"
                  :disabled="goodsRow.collected || doc.closed"
                />
              </div>
            </div>

            <div class="col ps-2">
              <div class="row ps-1">
                <div class="col-sm px-0" v-if="showPositionArtikul">
                  <input
                    v-model="goodsRow.artikul"
                    type="text"
                    class="form-control px-0"
                    placeholder=""
                  />
                </div>
                <div class="col-sm px-0">
                  <input
                    v-model="goodsRow.daysLastSale"
                    type="number"
                    class="form-control px-0 mx-1"
                    placeholder=""
                    disabled
                  />
                </div>
              </div>

              <div class="row mt-1 mb-1">
                <div class="col-sm" v-if="showPositionArtikul">
                  <button
                    class="btn btn-light m-0 p-0"
                    @click="showPositions(goodsRow)"
                  >
                    Ячейки {{ goodsRow.positions.length }}
                  </button>
                  <button
                    class="btn btn-light m-0 p-1"
                    @click="addPositions(goodsRow)"
                    :disabled="goodsRow.collected || doc.closed"
                  >
                    +
                  </button>
                </div>

                <div class="col-sm">
                  <button
                    class="btn btn-light m-0 p-0"
                    @click="addingBarcode = addingBarcode ? false : true"
                  >
                    ШК {{ goodsRow.barcodes.length }}
                  </button>
                </div>

                <div class="col-sm" v-if="goodsRow.edit">
                  <button
                    class="btn btn-primary m-0 p-0"
                    @click="saveGoodsRow(goodsRow)"
                    :disabled="!doc._id || goodsRow.saving"
                  >
                    <span v-if="!goodsRow.saving">
                      Сохр
                    </span>
                    <Spinner v-if="goodsRow.saving" />
                  </button>
                </div>
              </div>

              <div v-if="goodsRow.showPositions" class="row px-0">
                <div
                  v-for="positionRow in goodsRow.positions"
                  :key="positionRow._id"
                  class="row"
                >
                  <div class="col-sm pe-0">
                    <Multiselect
                      v-model="positionRow.position"
                      :options="positions"
                      :loading="false"
                      :internal-search="false"
                      valueProp="_id"
                      trackBy="name"
                      label="name"
                      :filterResults="true"
                      :minChars="2"
                      :resolveOnLoad="true"
                      :searchable="true"
                      noOptionsText="Не найден"
                      @change="goodsRow.edit = true"
                      :disabled="goodsRow.collected || doc.closed"
                    >
                    </Multiselect>
                  </div>
                  <div class="col-sm pe-0">
                    <input
                      v-model="positionRow.total"
                      type="text"
                      class="form-control"
                      placeholder=""
                      @change="goodsRow.edit = true"
                      :disabled="goodsRow.collected || doc.closed"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row  ">
          <div class="col">
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('down')"
            >
              Показать еще
            </button>
            <button
              class="btn btn-outline-secondary w-50"
              @click="showMore('start')"
            >
              В начало
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, nextTick } from "vue";
//import { onMounted, onUnmounted } from "vue";
import Multiselect from "@vueform/multiselect";
import router from "../router";
import { v4 as uuidv4 } from "uuid";
import useCommonMixin from "@/components/commonMixin.js";
import Spinner from "@/components/Spinner.vue";

export default {
  components: { Multiselect, Spinner },
  props: { id: { type: String, default: null } },
  setup(props) {
    const spinnerSave = ref(false);
    const spinnerLoading = ref(false);

    const products = ref([]);
    const stores = ref([]);
    const positions = ref([]);
    const addingBarcode = ref(false);
    const searchText = ref("");
    const searchTextTemp = ref("");
    const fillProductByBarcode = ref(false);

    const filterCursor = ref(10);
    const filterStep = ref(10);
    const filterMax = ref(20);
    const errorSaving = ref("");
    const searchTextFocused = ref(false);
    const showPositionArtikul = ref(false);
    const lastInventories = ref([]);
    const showNullBalance = ref(false);
    const daysLastSale = ref(0);

    const {
      dateFormat,
      isMobile,
      getStores,
      getPositions,
      getProducts,
      getSkladInventory,
      getSkladInventoryBalance,
      saveSkladInventory,
      getSkladInventoryBalanceByProduct,
      saveSkladInventoryGoodsRow,
    } = useCommonMixin();

    const showNotCollectedOnly = ref(false);

    const skladWarning = () => {
      let result = doc.value.store && doc.value.store.length > 1;
      if (!result) {
        window.confirm(`Неоходимо выбрать склад.`);
      }
      return result;
    };

    const dateBalance = computed(() => {
      let result = "";
      if (doc.value.goods.length >= 2)
        result = dateFormat(
          doc.value.goods[doc.value.goods.length - 1].dateBalance
        );
      return result;
    });

    const getDoc = async () => {
      console.log("props.id", props.id);
      let resp = await getSkladInventory(props.id);
      if (resp.status) {
        doc.value = await resp.data;
      }
    };

    const initNewDoc = async () => {
      let date = new Date();
      date.setTime(date.getTime() + 1000 * 3 * 60 * 60);
      doc.value = {
        number: 0,
        date: date.toISOString().substring(0, 19),
        comment: "",
        store: "1",
        hideGoodsInPeriod: true,
        goods: [],
      };

      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      doc.value.dateFromHide = firstDayOfMonth.toISOString().slice(0, 10);
      doc.value.dateToHide = lastDayOfMonth.toISOString().slice(0, 10);

    };

    const saveDoc = async () => {
      if (!skladWarning()) return;
      spinnerSave.value = true;
      errorSaving.value = "";
      let res = await saveSkladInventory(doc.value);

      if (res.status) {
        spinnerSave.value = false;
        doc.value = await res.data;
        router.push({
          name: "skladInventoryItem",
          params: { id: doc.value._id },
        });
      } else {
        errorSaving.value = await res.data;
        spinnerSave.value = false;
      }
    };

    const closeDoc = async () => {
      if (!skladWarning()) return;
      doc.value.closed = true;
      await saveDoc();
    };

    const getStoreBalance = async () => {
      spinnerSave.value = true;
      await saveDoc();

      let resp = await getSkladInventoryBalance(doc.value._id);
      if (resp.status) {
        doc.value.goods = await resp.data;
        spinnerSave.value = false;
      }
    };

    const getProductName = (productId) => {
      let name = "";
      let productIndex = products.value.findIndex(
        (product) => product._id == productId
      );
      name = productIndex >= 0 ? products.value[productIndex].name : "";
      return name;
    };

    const doc = ref([]);

    const fillCollected = (row) => {
      if (!row.fact && row.collected) {
        row.fact = row.plan;
      }
    };

    const currentRow = ref(null);
    const currentRowId = ref(null);
    const selectRow = (rowId, productIndex = null) => {
      let rowIndex = doc.value.goods.findIndex((item) => item._id == rowId);
      currentRow.value = rowIndex;
      currentRowId.value = rowId;
      if (productIndex) {
        filterCursor.value = productIndex;
      }
    };

    const isPosition = (text) => {
      let positionIndex = positions.value.findIndex(
        (item) => item.guid == text
      );
      return positionIndex >= 0;
    };

    const addPosition = (guid) => {
      if (currentRow.value >= 0) {
        let product = doc.value.goods[currentRow.value];
        let productsPositionIndex = product.productPositions.findIndex(
          (item) => item.positionGuid == guid
        );

        if (productsPositionIndex < 0) {
          product.productPositions.unshift({
            _id: null,
            productGuid: product.guid,
            positionGuid: guid,
          });
          changeProductPosition(product._id, 0);
        }
      }
    };

    const addBarcode = (code, productIndex) => {
      if (productIndex >= 0 && code.length > 0) {
        let currentProduct = doc.value.goods[productIndex];
        let codeIndex = currentProduct.barcodes.findIndex(
          (item) => item == code
        );
        if (codeIndex < 0) {
          currentProduct.barcodes.push(code);
        }
      }
    };

    const scrollToRow = async (rowId) => {
      await nextTick();
      console.log("scrollToRow", `inventory-row-${rowId}`);
      let rowEl = document.getElementById(`inventory-row-${rowId}`);
      rowEl.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const handleClipboard = (text) => {
      console.log("handleClipboard", text);
      if (searchTextFocused.value) {
        searchTextTemp.value = text;
        searchText.value = text;
        //searchTextFocused.value = false
      } else if (isPosition(text)) {
        // console.log("isPosition");
        addPosition(text);
      } else {
        if (fillProductByBarcode.value) {
          let productIndex = products.value.findIndex((product) => {
            let barcodeIndex = product.barcodes.findIndex(
              (barcodeItem) => text.indexOf(barcodeItem) >= 0
            );
            return barcodeIndex >= 0;
          });
          if (productIndex >= 0) {
            doc.value.goods[currentRow.value].product =
              products.value[productIndex]._id;
          }
          fillProductByBarcode.value = false;
        } else if (addingBarcode.value) {
          // console.log("addingBarcode");
          addBarcode(text, currentRow.value);
          addingBarcode.value = false;
        } else {
          let rowIndex = doc.value.goods.findIndex((row) => {
            let barcodeIndex = row.barcodes.findIndex(
              (barcodeItem) => text.indexOf(barcodeItem) >= 0
            );
            return barcodeIndex >= 0;
          });

          console.log("rowIndex", rowIndex);
          let rowGoods = doc.value.goods[rowIndex];
          // let rowFilterIndex = docGoodsFilter.value.findIndex((row) => row._id == rowGoods._id)
          // console.log("rowFilterIndex", rowFilterIndex);

          if (rowIndex >= 0) {
            selectRow(rowGoods._id, rowIndex);
            scrollToRow(rowGoods._id);
          } else {
            searchTextTemp.value = text;
            searchText.value = text;
            // console.log("adding searchText");
          }
        }
      }
    };

    const spinnerSearch = ref(true);

    const docGoodsFilter = computed(() => {
      let result = [];
      if (doc.value && Array.isArray(doc.value.goods)) result = doc.value.goods;

      let searchT = searchText.value.toLowerCase();
      if (searchText.value.length >= 3) {
        result = result.filter(
          (row) =>
            JSON.stringify(row)
              .toLowerCase()
              .indexOf(searchT) >= 0
        );
      }

      if (showNotCollectedOnly.value) {
        result = result.filter((row) => !row.collected);
      }

      if (!showNullBalance.value) {
        result = result.filter((row) => row.balance > 0);
      }

      if (daysLastSale.value > 0)
        result = result.filter((row) => row.daysLastSale > daysLastSale.value);

      if (result && result.length > filterMax.value) {
        let filterStart =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
        let filterEnd = filterCursor.value + filterStep.value;

        result = result.slice(filterStart, filterEnd);
      }

      return result;
    });

    const showMore = (direction) => {
      if (direction == "up") {
        filterCursor.value =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
      }
      if (direction == "down") {
        filterCursor.value =
          filterCursor.value + filterStep.value < doc.value.goods.length
            ? filterCursor.value + filterStep.value
            : doc.value.goods.length - filterStep.value;
      }

      if (direction == "start") {
        filterCursor.value = filterStep.value;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    const addRow = () => {
      if (!skladWarning()) return;

      let newRow = {
        product: null,
        productName: "",
        balance: 0,
        balanceFact: 0,
        position: null,
        artirul: "",
        daysLastSale: 0,
        barcodes: [],
        newRow: true,
        _id: uuidv4(),
      };
      doc.value.goods.unshift(newRow);
    };

    const clearSearchText = () => {
      searchText.value = "";
      searchTextTemp.value = "";
    };

    const getStoreBalanceActive = computed(() => {
      return doc.value.store && doc.value.store.length == 24;
    });

    const hideShowPositionArtikul = () => {
      showPositionArtikul.value = showPositionArtikul.value ? false : true;
    };

    const onProductChange = async (row) => {
      await nextTick();
      console.log("doc.value.store, row.product", doc.value.store, row.product);
      let resp = await getSkladInventoryBalanceByProduct(
        doc.value.store,
        row.product
      );

      if (!resp.status) return;
      row.balance = resp.data.balance;
      row.balanceFact = resp.data.balanceFact;
      row.artikul = resp.data.artikul;
      row.position = resp.data.position;
      row.positions = resp.data.positions;
      row.daysLastSale = resp.data.daysLastSale;
      row.barcodes = resp.data.barcodes;
    };

    const showPositions = (goodsRow) => {
      goodsRow.showPositions = goodsRow.showPositions ? false : true;
    };

    const saveGoodsRow = async (goodsRow) => {
      goodsRow.saving = true;
      let data = {
        goodsRow: goodsRow,
        skladInventoryId: doc.value._id,
      };
      let resp = await saveSkladInventoryGoodsRow(data);
      console.log("resp", resp);
      if (!resp.status) return;

      goodsRow.balanceFact = resp.data.balanceFact;
      goodsRow.positions = resp.data.positions;
      goodsRow.edit = false;
      goodsRow.saving = false;
    };

    const addPositions = (goodsRow) => {
      goodsRow.positions.push({
        position: null,
        productBalance: null,
        total: 0,
      });
      goodsRow.showPositions = true;
    };

    const setBalanceFactToPositions = async (goodsRow) => {
      await nextTick();
      let balanceFact = goodsRow.balanceFact;
      if (goodsRow.positions.length == 1) {
        let position = goodsRow.positions[0];
        position.total = balanceFact;
        await saveGoodsRow(goodsRow);
      }

      if (goodsRow.positions.length == 0) {
        addPositions(goodsRow);
        let position = goodsRow.positions[0];
        position.total = balanceFact;
        await saveGoodsRow(goodsRow);
      }

      if (goodsRow.positions.length > 1) {
        let position = goodsRow.positions[0];
        position.total = balanceFact;
        for (let index = 1; index < goodsRow.positions.length; index++) {
          let positionToDelete = goodsRow.positions[index];
          positionToDelete.delete = true;
        }
        await saveGoodsRow(goodsRow);
      }

      goodsRow.showPositions = false;
    };

    onMounted(async () => {
      document.addEventListener("paste", (event) => {
        let paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );
        handleClipboard(paste);
        // addBarcode(paste);
        event.preventDefault();
      });



      spinnerLoading.value = true;
      let storesRes = await getStores();
      stores.value = storesRes.status ? storesRes.data : storesRes.value;

      let positionsRes = await getPositions();
      let productsRes = await getProducts();
      positions.value = positionsRes.status
        ? positionsRes.data
        : positions.value;
      products.value = productsRes.status ? productsRes.data : products.value;

      if (props.id == "__new__") {
        await initNewDoc();
      } else {
        await getDoc();
      }

      spinnerLoading.value = false;
    });

    const removeProductPosition = (productId, positionIndex) => {
      console.log("removeProductPosition");
      changeProductPosition(productId, positionIndex, true);
    };

    const changeProductPosition = async (
      productId,
      positionIndex,
      markDelete = false
    ) => {
      let productGuid = null;
      let positionGuid = null;
      let result = null;
      let _id = null;

      let productIndex = doc.value.goods.findIndex(
        (row) => row._id == productId
      );
      let product = doc.value.goods[productIndex];
      productGuid = product.guid;
      positionGuid = product.productPositions[positionIndex].positionGuid;
      _id = product.productPositions[positionIndex]._id;

      let payload = {
        productGuid: productGuid,
        positionGuid: positionGuid,
        _id: _id,
        delete: markDelete,
      };
      console.log("_id", _id);
      console.log("productGuid, positionGuid", productGuid, positionGuid);

      let res = await fetch(
        process.env.VUE_APP_API_URL + "/saveProductPosition",
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(payload),
        }
      );

      if (res.status == 200) {
        if (!markDelete) {
          result = await res.json();
          product.productPositions[positionIndex] = result;
        }

        if (markDelete) {
          product.productPositions.splice(positionIndex, 1);
        }
      }
    };

    return {
      getDoc,
      doc,
      dateFormat,
      saveDoc,
      spinnerSave,
      stores,
      getStoreBalance,
      products,
      getProductName,
      spinnerLoading,
      positions,
      removeProductPosition,
      fillCollected,
      selectRow,
      currentRow,
      addingBarcode,
      searchText,
      docGoodsFilter,
      addRow,
      fillProductByBarcode,
      showMore,
      filterCursor,
      searchTextTemp,
      clearSearchText,
      currentRowId,
      searchTextFocused,
      spinnerSearch,
      errorSaving,
      getStoreBalanceActive,
      scrollToRow,
      changeProductPosition,
      lastInventories,
      dateBalance,
      showNotCollectedOnly,
      isMobile,
      showPositionArtikul,
      hideShowPositionArtikul,
      onProductChange,
      showPositions,
      saveGoodsRow,
      addPositions,
      showNullBalance,
      daysLastSale,
      closeDoc,
      setBalanceFactToPositions,
    };
  },
};
</script>

<style>
:root {
  --ms-px: 0.4rem;
}

.lh {
  line-height: 1;
}

.selected-row {
  background-color: #00ff9d;
}
.adding-barcode-product {
  background-color: #e1f100a2;
}
.fill-product-by-barcode {
  background-color: #1000f149;
}
.multiselect-single-label {
  padding-right: 10px !important;
}

.form-group {
  margin: 0 auto 1.5rem auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
