<template>
  <div>
    <div class="text-center container">
      <h1>ОТЧЕТ ПО ВЕСУ ОРДЕРОВ</h1>
      <div class="">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <Multiselect
              v-model="currentStore"
              :options="stores"
              :loading="false"
              :internal-search="false"
              valueProp="_id"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
              :limit="10"
              placeholder="Склад"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <Multiselect
              v-model="currentLoader"
              :options="loaders"
              :loading="false"
              :internal-search="false"
              valueProp="_id"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
              :limit="10"
              placeholder="Грузчик"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getReportData">
              Сформировать
            </button>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <div class=" form-check form-switch mb-2 ms-1 ">
              <input
                style="width: 3rem; height: 1.5rem"
                class="form-check-input"
                type="checkbox"
                v-model="showAllGoods"
              />
              Показать все товары
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container mt-3">
      <SkladOrdersReadOnly
        :orders="reportData"
        :show-only-weight-diff="!showAllGoods"
        :show-weight-check-btn="true"
        :show-revision="true"
      />

    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>

</div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import useUsersMixin from "@/components/usersMixin.js";
import Multiselect from "@vueform/multiselect";
import SkladOrdersReadOnly from "@/components/SkladOrdersReadOnly.vue";

export default {
  components: { Multiselect, SkladOrdersReadOnly },
  setup() {
    const spinnerLoading = ref(false);
    const showAllGoods = ref(false);

    const reportData = ref([]);
    const dateFrom = ref("");
    const dateTo = ref("");
    const currentLoader = ref("");
    const { users, getUsers } = useUsersMixin();

    const currentStore = ref("");
    const stores = ref([
      {name: "Все склады", _id: ""},
      {name: "Склад №1 (ПТЗ)", _id: "Склад №1 (ПТЗ)"},
      {name: "Склад №2 (МУРМАНСК)", _id: "Склад №2 (МУРМАНСК)"},
      {name: "Склад №3 (АПАТИТЫ)", _id: "Склад №3 (АПАТИТЫ)"},
    ]);

    
    const loaders = computed(() => {
      let usersAll = { _id: "ALL", name: "Все" };
      let usersLoaders = users.value.filter((user) => user.loader);
      usersLoaders.unshift(usersAll)
      return usersLoaders
    });

    const getReportData = async () => {
      spinnerLoading.value = true;
      currentLoader.value = currentLoader.value ? currentLoader.value : "all";
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          `/getReportSkladWeight/loaderId/${currentLoader.value}/dateFrom/${dateFrom.value}/dateTo/${dateTo.value}/${currentStore.value}`,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        reportData.value = await res.json();
        spinnerLoading.value = false;
      }
    };

    onMounted(async () => {
      getUsers();
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);
      // await getReportData();
    });

    return {
      dateFrom,
      dateTo,
      getReportData,
      reportData,
      spinnerLoading,
      currentLoader,
      loaders,
      showAllGoods,
      currentStore, 
      stores
    };
  },
};
</script>
