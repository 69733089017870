<template>
  <div>
    <Multiselect
      v-model="selectedItem"
      :options="items"
      :loading="false"
      :internal-search="false"
      valueProp="Ref_Key"
      trackBy="Description"
      label="Description"
      :filterResults="true"
      :minChars="2"
      :resolveOnLoad="true"
      :searchable="true"
      noOptionsText="Не найден"
      :limit="20"
      :placeholder="placeholder"
      @search-change="onInput"
      @select="changeSelectedItem(value)"
      @open="onOpen"
      :class="{ 'product-input-wide': isMobile && inputWide }"
      :disabled="disabled"
    >
      <template v-if="inputWide" v-slot:singlelabel="option">
        <div class="multiselect-single-label">
          <span class="multiselect-single-label-text1 single-label">{{
            option.value.Description
          }}</span>
        </div>
      </template>
    </Multiselect>
    <span v-if="showPlaceholder" class="text-muted placeholder-font">{{
      placeholder
    }}</span>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  components: { Multiselect },
  props: {
    placeholder: String,
    type: String,
    item: String,
    fetchAll: Boolean,
    showPlaceholder: Boolean,
    filterOdata: String,
    inputWide: Boolean,
    disabled: Boolean,
    preloadedItems: Array,
    usePreloadedItems: Boolean,
  },
  setup(props, { emit }) {
    const items = ref([]);
    const selectedItem = ref("");
    const { getCatlg1C, isMobile } = useCommonMixin();

    const changeSelectedItem = () => {
      emit("update:item", selectedItem.value);
      emit("change");
    };

    const getCatlgs = async (
      filterDescription = false,
      searchText = "",
      init = false
    ) => {

      if (props.usePreloadedItems) {
        items.value = props.preloadedItems
      }
      
      let reqData = {
        type: props.type,
      };

      if (filterDescription) reqData.filterDescription = searchText;

      if (init && !props.fetchAll /*&& !props.filterOdata*/)
        reqData.filterRefKey = props.item;
      if (props.filterOdata) reqData.filterOdata = props.filterOdata;

      console.log('getCatlgs', props.type, props.item, init, reqData);
      let resp = await getCatlg1C(reqData);
      if (resp.status) {
        items.value = resp.data.value;
      }
    };

    const onInput = async (value) => {
      if (!value || value.length < 3) return;
      let filterDescription = !props.fetchAll;
      getCatlgs(filterDescription, value);
    };

    const onOpen = () => {
      if (!props.fetchAll && !props.filterOdata) {
        let selectedItemObj = null;
        let index = items.value.findIndex(
          (item) => item.Ref_Key == selectedItem.value
        );
        if (index >= 0) selectedItemObj = items.value[index];
        items.value = [];
        if (selectedItemObj) items.value.push(selectedItemObj);
      }
    };

    watch(
      () => props.item,
      () => {
        console.log("watch props.item", props.item);
        selectedItem.value = props.item;
        getCatlgs(false, "", true);
      }
    );

    watch(
      () => selectedItem.value,
      () => {
        console.log("watch selectedItem", selectedItem.value);
        //changeSelectedItem()
        emit("update:item", selectedItem.value);
      }
    );

    watch(
      () => props.filterOdata,
      () => {
        console.log("watch filterOdata", props.filterOdata);
        getCatlgs(false, "", true);
      }
    );

    onMounted(async () => {
      selectedItem.value = props.item;
      await getCatlgs(false, "", true);
    });

    return {
      items,
      selectedItem,
      onInput,
      changeSelectedItem,
      onOpen,
      isMobile,
    };
  },
};
</script>

<style>
.placeholder-font {
  font-size: small;
}
</style>
