<template>
  <div>
    <div class="text-center">
      <h1>ОТЧЕТ ПО СБОРКЕ ОРДЕРОВ</h1>
      <div class="d-flex justify-content-center btn-group">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
        </div>
      </div>

      <!-- Фильтр по складам -->
      <div class="mt-1 d-flex justify-content-center row">
        <div class="col-5 mt-1">
          <select
            id="stores"
            v-model="currentStore"
            class="mb-1 border-1 fs-5 mx-3 form-select custom-select"
          >
            <option value="">Все склады</option>
            <option v-for="store in stores" :value="store" :key="store">
              {{ store }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="mt-1 d-flex justify-content-center row">
      <div class="col-5">
        <button class="btn btn-primary w-100" @click="getReportData">
          Сформировать
        </button>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container" style="font-size: small">
      <div class="row row-cols-6 border mx-1 mt-1">
        <div class="col mx-0 px-0">
          <div class="row fw-bold">Грузчик</div>
          <div class="row">Приход</div>
          <div class="row">Расход</div>
        </div>
        <div class="col fw-bold">Ордеров, шт</div>
        <div class="col fw-bold">Вес, кг</div>
        <div class="col fw-bold">Позиций, шт</div>

        <div class="col mx-0 px-0">
          <div class="row fw-bold">Ошибки</div>
          <div class="row">Сборка</div>
          <div class="row">Проверка</div>
        </div>

        <div class="col mx-0 px-0">
          <div class="row fw-bold">Проверка</div>
          <div class="row">+</div>
          <div class="row">-</div>
        </div>
      </div>

      <div
        v-for="(row, index) in reportData"
        :key="index"
        class="row row-cols-6 border mx-1 mt-1"
      >
        <div class="col mx-0 px-0 ">{{ row.loaderName }}</div>
        <div class="col">
          <div class="row">{{ row.ordersIn }}</div>
          <div class="row">{{ row.ordersOut }}</div>
        </div>
        <div class="col">
          <div class="row">{{ row.weightIn.toFixed(0) }}</div>
          <div class="row">{{ row.weightOut.toFixed(0) }}</div>
        </div>
        <div class="col">
          <div class="row">{{ row.goodsIn }}</div>
          <div class="row">{{ row.goodsOut }}</div>
        </div>
        <div class="col">
          <div class="row">{{ row.loaderErrorPositions }}</div>
          <div class="row">{{ row.checkErrorPositions }}</div>
        </div>

        <div class="col">
          <div class="row">{{ row.checkedPlus }}</div>
          <div class="row">{{ row.checkedMinus }}</div>
        </div>
      </div>

      <div class="row row-cols-6 border mx-1 mt-1">
        <div class="col mx-0 px-0 ">ИТОГО</div>
        <div class="col">
          <div class="row">{{ reportTotal.ordersIn }}</div>
          <div class="row">{{ reportTotal.ordersOut }}</div>
        </div>
        <div class="col">
          <div class="row">{{ reportTotal.weightIn.toFixed(0) }}</div>
          <div class="row">{{ reportTotal.weightOut.toFixed(0) }}</div>
        </div>
        <div class="col">
          <div class="row">{{ reportTotal.goodsIn }}</div>
          <div class="row">{{ reportTotal.goodsOut }}</div>
        </div>
        <div class="col">
          <div class="row">{{ reportTotal.loaderErrorPositions }}</div>
          <div class="row">{{ reportTotal.checkErrorPositions }}</div>
        </div>
        <div class="col">
          <div class="row">{{ reportTotal.checkedPlus }}</div>
          <div class="row">{{ reportTotal.checkedMinus }}</div>
        </div>
      </div>
    </div>
    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useCommonMixin from "@/components/commonMixin.js";

export default {
  components: {},
  setup() {
    const { postUserHistoryByMetric } = useCommonMixin();

    const spinnerLoading = ref(false);

    const reportData = ref([]);
    const reportTotal = ref({
      loaderErrorPositions: 0,
      checkErrorPositions: 0,
      weightOut: 0,
      goodsOut: 0,
      ordersOut: 0,
      weightIn: 0,
      goodsIn: 0,
      ordersIn: 0,
    });
    const dateFrom = ref("");
    const dateTo = ref("");
    const currentStore = ref("");
    const stores = ref([
      "Склад №1 (ПТЗ)",
      "Склад №2 (МУРМАНСК)",
      "Склад №3 (АПАТИТЫ)",
    ]);

    const getReportData = async () => {
      spinnerLoading.value = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          `/getReportSkladLoaders/dateFrom/${dateFrom.value}/dateTo/${dateTo.value}/${currentStore.value}`,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        let reportResult = await res.json();
        reportData.value = reportResult.rows;
        reportTotal.value = reportResult.total;
        spinnerLoading.value = false;
      }
    };

    const reportSkladLoaders = () => {
      return JSON.parse(localStorage.getItem("reportSkladLoaders"));
    };

    onMounted(async () => {
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);
      // await getReportData();
      postUserHistoryByMetric('Отчет по сборке ордеров')
    });

    return {
      dateFrom,
      dateTo,
      getReportData,
      reportTotal,
      reportData,
      spinnerLoading,
      currentStore,
      stores,
      reportSkladLoaders,
    };
  },
};
</script>
