<template>
  <div>
    <div class="text-center">
      <h1>ИНВЕНТАРИЗАЦИИ</h1>
      <div class="d-flex justify-content-center m-3 btn-group">
        <input
          v-model="searchDate"
          type="date"
          class="form-control fs-5"
          placeholder="Дата"
          @input="getDocs"
        />
        <button class="btn btn-primary" @click="getDocs">Обновить</button>
      </div>
      <div class="d-flex justify-content-center m-3 btn-group">
        <router-link
          class="btn btn-warning"
          :to="{ name: 'inventoryItem', params: { id: '__new__' } }"
          >НОВАЯ</router-link
        >
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container">
      <div
        v-for="doc in docs"
        :key="doc._id"
        class="row row-cols-2 border mx-1 mt-1"
        @click="openDoc(doc._id)"
      >
        <div class="col">{{ dateFormat(doc.date) }}</div>
        <div class="col ">
          {{ doc.number }}
        </div>
        <div class="col">
          <span v-if="doc.store && doc.store.name"> {{ doc.store.name }}</span>
        </div>
        <div class="col">
          <span> {{ doc.comment }}</span>
        </div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
//import { onMounted, onUnmounted } from "vue";
import router from "../router";

export default {
  components: {},
  setup() {
    const searchDate = ref("");
    const spinnerLoading = ref(true);

    const dateFormat = (date) => {
      let dateFormated = new Date(date);
      return dateFormated.toLocaleString();
    };

    const getDocs = async () => {
      spinnerLoading.value = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          "/getInventory/byDate/" +
          searchDate.value,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        docs.value = await res.json();
        spinnerLoading.value = false;
      }
    };
    const docs = ref([]);

    const openDoc = (id) => {
      router.push({ name: "inventoryItem", params: { id: id } });
    };

    onMounted(async () => {
      let today = new Date().toISOString().slice(0, 10);
      searchDate.value = today;
      await getDocs();
    });

    return {
      searchDate,
      getDocs,
      docs,
      dateFormat,
      openDoc,
      spinnerLoading,
    };
  },
};
</script>
