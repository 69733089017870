<template>
  <div>
    <div class="text-center">
      <h1 v-if="!item._id">Возврат</h1>
    </div>

    <div class="">
      <div class="row border mx-1 mt-2">
        <div class="row">
          <div class="col">{{ dateFormat(docItem.date) }}</div>
          <div class="col">{{ docItem.number }}</div>
          <div class="col">
            <button class="btn btn-light" @click="showItem()">
              <span v-if="!docItem.show">+</span>
              <span v-if="docItem.show">-</span>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class=" form-check form-switch  mb-3 ">
              <label class="mx-1">Согласовано</label>
              <input
                style="width: 3rem; height: 1.5rem"
                class="form-check-input"
                type="checkbox"
                v-model="docItem.acceptSklad"
                @change="docItem.edit = true"
                :disabled="docItem.acceptSklad || !isСorrectionFull()"
              />
            </div>
          </div>
        </div>

        <div v-if="docItem.show">
          <div class="row row-cols-1">
            <div class="col">
              <div class=" form-check form-switch mb-3 ">
                <label class="mx-1">Ошибка</label>
                <input
                  style="width: 3rem; height: 1.5rem"
                  class="form-check-input"
                  type="checkbox"
                  v-model="docItem.loaderError"
                  @change="docItem.edit = true"
                  :disabled="!isСorrectionLoaderError()"
                />
              </div>
            </div>
            <div class="col">
              Грузчика
              <input
                class="form-control"
                type="number"
                v-model="docItem.loaderErrorNumber"
                @change="docItem.edit = true"
                :disabled="
                  !docItem.loaderError ||
                    !isСorrectionLoaderError() ||
                    !isСorrectionFull()
                "
              />
            </div>

            <div class="col">
              Проверяющего
              <input
                class="form-control"
                type="number"
                v-model="docItem.checkerErrorNumber"
                @change="docItem.edit = true"
                :disabled="
                  !docItem.loaderError ||
                    !isСorrectionLoaderError() ||
                    !isСorrectionFull()
                "
              />
            </div>
          </div>

          <div v-if="docItem.receivedRequest" class="row row-cols-1">
            <div class="col">
              <div class=" form-check form-switch mb-3 ">
                <label class="mx-1">Товар получен</label>
                <input
                  style="width: 3rem; height: 1.5rem"
                  class="form-check-input"
                  type="checkbox"
                  v-model="docItem.receivedStatus"
                  @change="docItem.edit = true"
                  :disabled="docItem.receivedStatus || !isСorrectionFull()"
                />
              </div>
            </div>
            <div class="col">
              <input
                class="form-control"
                type="text"
                v-model="docItem.receivedComment"
                @change="docItem.edit = true"
                :disabled="docItem.receivedStatus || !isСorrectionFull()"
                placeholder="Товар получен комментарий"
              />
            </div>
          </div>

          <div class="row mt-1">
            <div class="col lh-20">
              <Multiselect
                v-model="docItem.driverGuid"
                :options="persons"
                :loading="false"
                :internal-search="false"
                valueProp="guid"
                trackBy="name"
                label="name"
                :filterResults="true"
                :minChars="2"
                :resolveOnLoad="true"
                :searchable="true"
                noOptionsText="Не найден"
                :limit="5"
                placeholder="Водитель"
                @select="docItem.edit = true"
                :disabled="docItem.acceptSklad || !isСorrectionFull()"
              >
              </Multiselect>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col lh-20">
              <Multiselect
                v-model="docItem.loaderGuid"
                :options="persons"
                :loading="false"
                :internal-search="false"
                valueProp="guid"
                trackBy="name"
                label="name"
                :filterResults="true"
                :minChars="2"
                :resolveOnLoad="true"
                :searchable="true"
                noOptionsText="Не найден"
                :limit="5"
                placeholder="Грузчик"
                @select="docItem.edit = true"
                :disabled="docItem.acceptSklad || !isСorrectionFull()"
              >
              </Multiselect>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col lh-20">
              <Multiselect
                v-model="docItem.checkerGuid"
                :options="persons"
                :loading="false"
                :internal-search="false"
                valueProp="guid"
                trackBy="name"
                label="name"
                :filterResults="true"
                :minChars="2"
                :resolveOnLoad="true"
                :searchable="true"
                noOptionsText="Не найден"
                :limit="5"
                placeholder="Проверяющий"
                @select="docItem.edit = true"
                :disabled="docItem.acceptSklad || !isСorrectionFull()"
              >
              </Multiselect>
            </div>
          </div>

          <div class="row">
            <div class="col fw-bold">{{ docItem.commentBuh }}</div>
          </div>

          <div class="row px-0 mx-0 ">
            <div class="col">
              <input
                type="text"
                class="form-control"
                v-model="docItem.commentSklad"
                @input="docItem.edit = true"
                :disabled="docItem.acceptSklad || !isСorrectionFull()"
              />
            </div>
          </div>

          <div class="row px-0 mx-0 mt-1">
            <div class="accordion-item px-0">
              <h2 class="accordion-header " id="headingOne">
                <button
                  class="accordion-button p-2 "
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapseOne-${docItem._id}`"
                  aria-expanded="true"
                  :aria-controls="`collapseOne-${docItem._id}`"
                >
                  Товары
                </button>
              </h2>
              <div
                :id="`collapseOne-${docItem._id}`"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-0">
                  <button
                    class="btn btn-light"
                    @click="docItem.goods.push({ guid: '', qty: 0 })"
                    :disabled="docItem.acceptSklad || !isСorrectionFull()"
                  >
                    Добавить товар
                  </button>
                  <div
                    v-for="goodsRow in docItem.goods"
                    :key="goodsRow._id"
                    class="row lh-20  border mx-0 py-1 "
                  >
                    <div class="col border1">
                      <Multiselect
                        v-model="goodsRow.guid"
                        :options="products"
                        :loading="false"
                        :internal-search="false"
                        valueProp="guid"
                        trackBy="name"
                        label="name"
                        :filterResults="true"
                        :minChars="2"
                        :resolveOnLoad="true"
                        :searchable="true"
                        noOptionsText="Не найден"
                        :limit="10"
                        @select="docItem.edit = true"
                        :disabled="docItem.acceptSklad || !isСorrectionFull()"
                      >
                      </Multiselect>
                    </div>
                    <div class="col-3 border1">
                      <input
                        type="number"
                        class="form-control"
                        v-model="goodsRow.qty"
                        @input="docItem.edit = true"
                        :disabled="docItem.acceptSklad || !isСorrectionFull()"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row px-0 mx-0 mt-1">
            <SkladOrdersReadOnly
              v-if="docItem.orderObj"
              :orders="[docItem.orderObj]"
              padd0
            />
          </div>

          <div v-if="docItem.edit" class="row px-0 mx-0 mt-1">
            <button
              v-if="!docItem.spinner"
              class="btn btn-info"
              @click="saveDocCorrection(docItem)"
            >
              СОХРАНИТЬ
            </button>
            <div
              v-if="docItem.spinner"
              class="d-flex justify-content-center mt-3"
            >
              <div
                class="spinner-border text-primary d-flex justify-content-center"
                role="status"
              ></div>
            </div>
          </div>
        </div>

        <div v-if="!list" class="row px-0 mx-0 mt-1">
          <button class="btn btn-secondary" @click="router.back()">
            НАЗАД
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import useCommonMixin from "@/components/commonMixin.js";
import SkladOrdersReadOnly from "@/components/SkladOrdersReadOnly.vue";
import Multiselect from "@vueform/multiselect";
import { useRouter } from "vue-router";

export default {
  components: { SkladOrdersReadOnly, Multiselect },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          _id: null,
          commentSklad: "",
          goods: [],
          commentBuh: "",
          loaderGuid: "",
          driverGuid: "",
          loaderError: false,
          acceptSklad: false,
          date: new Date(),
          number: "",
          guid: "",
        };
      },
    },
    id: { type: String, default: "" },
    invoiceGuid: { type: String, default: "" },
    orderGuid: { type: String, default: "" },
    orderObj: { type: Object, default: () => {} },
    list: { type: Boolean, default: false },
    productsList:  {type: Array, default: () => [] },
  },
  setup(props) {
    const docItem = ref(props.item);
    const persons = ref([]);
    const productsLocal = ref([]);
    const router = useRouter();

    const isСorrectionFull = () => {
      return JSON.parse(localStorage.getItem("isСorrectionFull"));
    };

    const isСorrectionLoaderError = () => {
      return JSON.parse(localStorage.getItem("isСorrectionLoaderError"));
    };

    const {
      dateFormat,
      saveDocCorrection,
      getPersons,
      getProducts,
      getDocCorrection,
    } = useCommonMixin();

    const showItem = () => {
      docItem.value.show = docItem.value.show ? false : true;
    };

    const products = computed(() => {
      return [...props.productsList, ...productsLocal.value];
    });

    onMounted(async () => {
      let personsResult = await getPersons();
      if (personsResult.status) {
        persons.value = personsResult.data;
      }

      if (props.invoiceGuid) {
        docItem.value.invoiceGuid = props.invoiceGuid;
      }
      if (props.orderGuid) {
        docItem.value.orderGuid = props.orderGuid;
        docItem.value.orderObj = JSON.parse(props.orderObj);
      }

      if ((props.id && props.id == "__new__") || !props.id) {
        let order = JSON.parse(props.orderObj)
        docItem.value.loaderGuid = order.loaderGuid;
        if ('checkerGuid' in order) {
          docItem.value.checkerGuid = order.checkerGuid;    
        }
        if ('driverGuid' in order) {
          docItem.value.driverGuid = order.driverGuid;    
        }
      }

      if (props.id && props.id != "__new__") {
        let result = await getDocCorrection(props.id);
        if (result.status) {
          docItem.value = result.data;
        }
      }

      if (props.id && props.id == "__new__") {
        docItem.value.show = true;
        let productsResult = await getProducts();
        if (productsResult.status) {
          productsLocal.value = productsResult.data;
        }
      }
    });

    return {
      dateFormat,
      saveDocCorrection,
      docItem,
      persons,
      router,
      showItem,
      isСorrectionFull,
      isСorrectionLoaderError,
      products
    };
  },
};
</script>

<style>
.lh-20 {
  line-height: 20px;
}
</style>
