<template>
  <div v-if="userDriver() || userAdmin() || isDriverOrders()">
    <!-- deliverDate -->
    <div
      v-if="!isOrderListPeriod()"
      class="d-flex justify-content-center m-3 btn-group"
    >
      <input
        v-model="deliverDate"
        type="date"
        class="form-control fs-5"
        placeholder="Дата доставки"
        @input="getTransportTasks()"
      />
      <button class="btn btn-primary" @click="getTransportTasks()">
        Обновить
      </button>
    </div>

    <div
      v-if="isOrderListPeriod()"
      class="d-flex justify-content-center m-3 btn-group"
    >
      <div class="row">
        <div class="col mt-1">
          <input
            v-model="dateFrom"
            type="date"
            class="form-control fs-5"
            placeholder="Дата"
          />
        </div>
        <div class="col mt-1">
          <input
            v-model="dateTo"
            type="date"
            class="form-control fs-5"
            placeholder="Дата"
          />
        </div>
        <div class="col mt-1">
          <button class="btn btn-primary w-100" @click="getTransportTasks()">
            Обновить
          </button>
        </div>
      </div>
    </div>

    <!-- Фильтр по курьерам -->
    <div
      v-if="userAdmin() || isDriverOrders()"
      class="mb-3 d-flex justify-content-center"
    >
      <select
        id="users"
        v-model="currentCourier"
        class="mb-1 border-1 fs-5 mx-3 form-select custom-select w-100"
        @change="calculatetableOfResults"
      >
        <option value="">Все курьеры</option>
        <option v-for="user in users" :value="user" :key="user">
          {{ user }}
        </option>
      </select>
    </div>

    <!-- Фильтр по складам -->
    <div class="mb-3 d-flex justify-content-center">
      <select
        id="stores"
        v-model="currentStore"
        class="mb-1 border-1 fs-5 mx-3 form-select custom-select w-100"
        @change="calculatetableOfResults"
      >
        <option value="">Все склады</option>
        <option v-for="store in stores" :value="store" :key="store">
          {{ store }}
        </option>
      </select>
    </div>

    <div class="mb-3 ms-3 fs-5 d-flex justify-content-left">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexCheckDefault"
          v-model="internetPokypatel"
          @change="calculatetableOfResults"
        />
        <label class="form-check-label " for="flexCheckDefault">
          ИнтернетПокупатель
        </label>
      </div>
    </div>

    <!-- Фильтр по статусам -->
    <div class="d-flex justify-content-center">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-light">
          <input
            type="radio"
            name="options"
            value="showAll"
            autocomplete="off"
            v-model="statusFilter"
          />
          Все
        </label>
        <label class="btn btn-light">
          <input
            type="radio"
            name="options"
            value="showOpened"
            autocomplete="off"
            v-model="statusFilter"
          />
          Открытые
        </label>
        <label class="btn btn-light">
          <input
            type="radio"
            name="options"
            value="showClosed"
            autocomplete="off"
            v-model="statusFilter"
          />
          Закрытые
        </label>
        <label class="btn btn-light">
          <input
            type="radio"
            name="options"
            value="showDraft"
            autocomplete="off"
            v-model="statusFilter"
          />
          Планируемые
        </label>
      </div>
    </div>

    <!-- search -->
    <div id="searchGroup" class="d-flex justify-content-center m-3 btn-group">
      <input
        v-model="searchText"
        type="text"
        class="form-control"
        placeholder="Поиск"
        @input="findOrders"
      />
    </div>

    <!-- Получить по номеру накладной -->
    <div
      id="searchGroup"
      class="d-flex justify-content-center m-3 btn-group"
      v-if="true"
    >
      <input
        v-model="searchInvoiceNumber"
        type="number"
        class="form-control w-50"
        placeholder="Номер накладной"
      />
      <button class="btn btn-primary" @click="getOrderByInvoice">
        Получить заказ
      </button>
    </div>

    <!-- Получить по номеру накладной -->
    <div
      class="d-flex justify-content-center m-3"
      v-if="ordersNoCheque.length > 0"
    >
      <button class="btn btn-danger" @click="setOrdersNoCheque()">
        <span class="text-white fs-3"
          >Показать оплаченные заказы без чеков</span
        >
      </button>
    </div>

    <div id="tableOfResults" class="m-3" v-if="statusFilter == 'showAll'">
      <ul class="list-group">
        <li class="list-group-item list-group-item-primary">
          Всего заказов: <b>{{ tableOfResults.qtyOfOrders }}</b>
        </li>

        <li class="list-group-item list-group-item-primary">
          Всего клиентов: <b>{{ tableOfResults.totalClients }}</b>
        </li>

        <li v-if="false" class="list-group-item list-group-item-primary">
          Оплачено / Сумма:
          <b
            >{{ tableOfResults.totalPaid.toLocaleString() }} /
            {{ tableOfResults.totalCost.toLocaleString() }}</b
          >
        </li>

        <li v-if="false" class="list-group-item list-group-item-primary">
          Доставлено заказов:
          <b>{{
            tableOfResults.qtyOfClosedOrders -
              tableOfResults.qtyOfCancelledOrders
          }}</b>
        </li>
        <li v-if="false" class="list-group-item list-group-item-primary">
          Отменено заказов: <b>{{ tableOfResults.qtyOfCancelledOrders }}</b>
        </li>
        <li v-if="false" class="list-group-item list-group-item-primary">
          Планируется заказов: <b>{{ tableOfResults.qtyOfDraftOrders }}</b>
        </li>
      </ul>
    </div>

    <!-- transportTasks -->
    <div
      v-for="task in transportTasksFiltered"
      :key="task._id"
      class="accordion"
      id="accordionExampleTask"
    >
      <div class="accordion-item">
        <h2 class="accordion-header" :id="'heading' + task._id"></h2>
        <button
          :id="task._id"
          :style="{ 'background-color': true }"
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#collapse' + task._id"
          aria-expanded="false"
          :aria-controls="'collapse' + task._id"
        >
          <div class="row row-cols-1">
            <div class="col fw-bold">
              {{ dateFormat(task.deliveryDate).substring(0, 10) }}
              {{ task.courier }}
            </div>

            <div class="col fw-bold">
              {{ task.shippingZone }}
            </div>
            
            <div class="col">
              Заказов: {{ task.orders.length }} /
              {{ taskOrdersClosed(task.orders) }} /
              {{ taskOrdersRefund(task.orders) }}
            </div>

            <div v-if="false" class="col">
              Сумма заказов: {{ taskOrdersPaidTotal(task.orders) }} /
              {{ taskOrdersSumTotal(task.orders) }}
            </div>

            <div v-if="false" class="col">
              Стоимость, ч/Часов/Всего, руб: {{ task.costHour }} /
              {{ task.totalHours ? task.totalHours.toFixed(2) : 0 }} /
              {{ task.totalSum }}
            </div>

            <div class="col">
              Вес, кг: {{ task.weight ? task.weight.toFixed(2) : 0 }}
            </div>
          </div>
        </button>
        <div
          :id="'collapse' + task._id"
          class="accordion-collapse collapse"
          :aria-labelledby="'heading' + task._id"
          data-bs-parent="#accordionExampleTask"
        >
          <!-- Task data -->
          <div class="accordion-body">
            <div>
              <button
                v-if="!task.edit"
                class="btn btn-primary"
                @click="setDriverPriority(task)"
              >
                Установить приоритет
              </button>
              <button
                v-if="task.edit"
                class="btn btn-light"
                @click="saveDriverPriority(task)"
              >
                Сохранить
              </button>
              <div
                class="accordion"
                id="accordionExample"
                v-for="order in ordersFiltered(task.orders)"
                :key="order._id"
              >
                <div class="accordion-item" v-if="orderVisiblity(order)">
                  <h2 class="accordion-header" :id="'heading' + order._id">
                    
                    <button
                      :id="order._id"
                      :style="{ 'background-color': orderColor(order) }"
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#collapse' + order._id"
                      aria-expanded="false"
                      :aria-controls="'collapse' + order._id"
                    >
                      <div class="row w-100">
                        <div class="col col-2 px-0">
                          <input
                            v-model="order.driverPriority"
                            type="text"
                            class="form-control px-0"
                            placeholder=""
                            :disabled="!task.edit"
                            style="max-width: 150px;"
                          />
                        </div>
                        <div class="col col-8">
                          <b>
                            <p class="mb-0">
                              {{ order.deliveryTimeFloor.slice(11, -3) }} -
                              {{ order.deliveryTimeCeil.slice(11, -3) }}
                              {{ order.client }}
                            </p>
                            <p class="mb-0 fw-normal">
                              {{ order.deliveryAdress }}
                            </p>
                            <p class="mb-0 fw-normal">
                              {{ order.totalPaid }} / {{ order.totalCost }}
                            </p>
                            <p class="mb-0 fw-normal">
                              Вес, кг:
                              {{
                                "weight" in order ? order.weight.toFixed(2) : 0
                              }}
                            </p>

                            <p>{{ order.loaderName }}</p>
                          </b>
                        </div>
                        <div id="changableStatus" class="col col-1">
                          <p
                            v-if="!order.closed && !order.loadingStart"
                            class="mb-1"
                          >
                            {{ order.orderStatus }}
                          </p>
                          <p
                            v-if="!order.taskDriver && order.closed"
                            class="mb-1"
                          >
                            {{ order.orderStatus }}:
                            {{ dateFormat(order.closedDate) }}
                          </p>
                          <span v-if="order.taskDriver">
                            <p v-if="order.loadingStart" class="mb-1">
                              Начало погрузки:
                              {{ dateFormat(order.loadingStart) }}
                            </p>
                            <p
                              v-if="order.loadingStart && order.closed"
                              class="mb-1"
                            >
                              Конец погрузки: {{ dateFormat(order.closedDate) }}
                            </p>
                            <p
                              v-if="order.closed && !order.loadingStart"
                              class="mb-1"
                            >
                              {{ order.orderStatus }}:
                              {{ dateFormat(order.closedDate) }}
                            </p>
                          </span>
                          <span v-if="order.isPaid">ОПЛАЧЕН</span>
                          <p v-if="order.refund" class="my-0">Есть возврат</p>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    :id="'collapse' + order._id"
                    class="accordion-collapse collapse"
                    :aria-labelledby="'heading' + order._id"
                    data-bs-parent="#accordionExample"
                  >
                    <!-- order data -->
                    <div class="accordion-body">
                      <li>
                        Комментарий: <b>{{ order.comment }}</b>
                      </li>
                      <li>Контакт: {{ order.deliveryAdressComment }}</li>
                      <li>Инф по доставке: {{ order.deliveryInfo }}</li>
                      <li>Тип оплаты: {{ order.paymentWay }}</li>
                      <li>E-mail: {{ order.email }}</li>
                      <li>Номер заказа: {{ order.orderNumber }}</li>
                      <li v-if="!order.taskDriver">
                        Номер накладной: {{ order.invoiceNumber }}
                      </li>
                      <li v-if="!order.taskDriver">
                        Дата накладной: {{ dateFormat(order.invoiceDate) }}
                      </li>
                      <li>Склад: {{ order.store }}</li>
                      <li>Водитель: {{ order.courier }}</li>
                      <li>Перевозчик: {{ order.company }}</li>
                      <li v-if="!order.taskDriver">
                        Итоговая сумма накладной: {{ order.totalCost }} р.
                      </li>

                      <div
                        v-if="!order.taskDriver"
                        class="accordion"
                        :id="`goods-accordion-${order._id}`"
                      >
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              :data-bs-target="`#goods-${order._id}`"
                              aria-expanded="true"
                              :aria-controls="`collapseOne`"
                            >
                              Товары
                            </button>
                          </h2>
                          <div
                            :id="`goods-${order._id}`"
                            class="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            :data-bs-parent="`goods-accordion-${order._id}`"
                          >
                            <div class="accordion-body p-0">
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <th>Наименование</th>
                                    <th>Цена</th>
                                    <th>Кол-во</th>
                                    <th>Итого</th>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="product in order.goods"
                                      :key="product._id"
                                    >
                                      <td>{{ product.productName }}</td>
                                      <td>{{ product.productPrice }}</td>
                                      <td>{{ product.productQty }}</td>
                                      <td>{{ product.productSum }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="d-flex justify-content-center form-group ms-3 me-3 mt-3"
                    >
                      <button
                        class="btn btn-sm btn-secondary"
                        data-order="${order._id}"
                        @click="buttonOnClick($event, order)"
                      >
                        СОХРАНИТЬ КОММЕНТАРИЙ
                      </button>
                      <textarea
                        type="text"
                        class="form-control"
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        v-model="order.driverComment"
                      ></textarea>
                    </div>

                    <div class="form-check ms-3" v-if="userAdmin()">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="order.needCheque"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Пробивается чек
                      </label>
                    </div>

                    <!-- Кнопки или спиннер -->
                    <div
                      v-if="order.spinner"
                      class="d-flex justify-content-center form-group ms-3 me-3 mt-3"
                    >
                      <div
                        class="spinner-border text-primary"
                        role="status"
                      ></div>
                    </div>

                    <div v-if="!order.spinner">
                      <!-- Кнопка ОПЛАТА -->
                      <div
                      class="d-flex justify-content-center m-3 btn-group"
                      v-if="QRAcceptOrgs.includes(order.orgInn)"
                      >
                      <p v-if="!order.isMarksCollected" class="fw-bold fs-2">ОТСУТСВУЮТ МАРКИ </p>
                     </div>

                      <div
                        class="d-flex justify-content-center m-3 btn-group"
                        v-if="QRAcceptOrgs.includes(order.orgInn)"
                      >
                        <button
                          type="button"
                          class="btn btn-warning btn-lg fs-1"
                          data-order="${order._id}"
                          @click="checkPayment(order)"
                        >
                          ОПЛАТА QR ({{ getQrPaidTotal(order) }} из
                          {{ order.totalCost }})
                        </button>
                      </div>

                      <div
                        v-if="order.showQrPayment"
                        class="d-flex justify-content-center m-3"
                      >
                        <div class="col-3">
                          <div
                            v-for="(qr, index) in order.qrCodes"
                            :key="qr._id"
                            class="row cols-3 mb-1"
                          >
                            <div class="col">
                              <button
                                class="btn btn-warning"
                                @click="setCurrrentQR(order, qr)"
                              >
                                {{ qr.amount }} руб
                              </button>
                            </div>
                            <div class="col">
                              <span
                                v-if="qr.amount == qr.paid"
                                class="text-success"
                              >
                                ОПЛАЧЕН
                              </span>
                              <span v-else class="text-danger">
                                НЕ ОПЛАЧЕН
                              </span>
                            </div>

                            <div class="col">
                              <button
                                v-if="fiscalizeButtonVisiblityQr(order, index)"
                                type="button"
                                class="btn btn-primary"
                                data-order="${order._id}"
                                @click="
                                  buttonOnClick($event, order, {
                                    kkmQrIndex: index,
                                  })
                                "
                              >
                                ПРОБИТЬ ЧЕК
                              </button>
                              <button
                                v-if="showChequeButtonVisiblityQr(order, index)"
                                type="button"
                                class="btn show-cheque"
                                data-order="${order._id}"
                                @click="
                                  buttonOnClick($event, order, {
                                    kkmQrIndex: index,
                                  })
                                "
                              >
                                ПОКАЗАТЬ ЧЕК
                              </button>
                              <button
                                v-if="qr.paid == 0"
                                type="button"
                                class="btn btn-danger"
                                data-order="${order._id}"
                                @click="cancelQR(order, qr.id)"
                              >
                                УДАЛИТЬ
                              </button>
                            </div>
                          </div>

                          <div class="row cols-2">
                            <div class="col">
                              <input
                                v-model="amountNewQR"
                                type="Number"
                                class="form-control"
                                placeholder="Сумма"
                              />
                            </div>
                            <div class="col">
                              <button
                                class="btn btn-primary"
                                @click="registerQR(order)"
                              >
                                Добавить QR
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="text-center" v-if="order.showQR">
                        <div v-if="!qrReceived">
                          <p class="h1 text-danger">ОШИБКА ЗАГРУЗКИ КОДА</p>
                        </div>
                        <div v-else>
                          <p class="m-0" v-if="true">
                            <a
                              :href="
                                `whatsapp://send?text=${encodeURIComponent(
                                  order.currentQR.payload
                                )}`
                              "
                              data-action="share/whatsapp/share"
                            >
                              <img
                                width="70"
                                height="70"
                                src="@/assets/whatsapp.png"
                                alt=""
                              />
                            </a>

                            <a
                              :href="
                                `https://telegram.me/share/url?url=${encodeURIComponent(
                                  order.currentQR.payload
                                )}`
                              "
                            >
                              <img
                                width="55"
                                height="55"
                                src="@/assets/telegram.svg"
                                alt=""
                              />
                            </a>

                            <a
                              :href="
                                `mailto:${
                                  order.email
                                }?subject=Ссылка для оплаты по накладной ${
                                  order.invoiceNumber
                                } от ${dateFormat(order.invoiceDate).slice(
                                  0,
                                  10
                                )}&body=${encodeURIComponent(
                                  order.currentQR.payload
                                )}`
                              "
                            >
                              <img
                                width="70"
                                height="70"
                                src="@/assets/email.png"
                                alt=""
                              />
                            </a>

                            <button
                              class="btn"
                              @click="copyToClipBoard(order.currentQR)"
                            >
                              <img
                                width="50"
                                height="50"
                                src="@/assets/copy-icon.png"
                                alt=""
                              />
                            </button>
                          </p>

                          <qrcode-vue
                            :value="order.currentQR.payload"
                            :size="170"
                          />
                          <p
                            v-if="
                              order.currentQR.paid != order.currentQR.amount
                            "
                            class="h1 text-danger"
                          >
                            {{ order.currentQR.amount }} руб - НЕ ОПЛАЧЕН
                          </p>
                          <p
                            v-if="
                              order.currentQR.paid == order.currentQR.amount
                            "
                            class="h1 text-success"
                          >
                            {{ order.currentQR.amount }} руб - ОПЛАЧЕН
                          </p>
                        </div>
                      </div>

                      <!-- Кнопка ВОЗВРАТЫ -->
                      <div
                        class="d-flex justify-content-center m-3 btn-group"
                        v-if="
                          QRAcceptOrgs.includes(order.orgInn) && userAdmin()
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-info btn-lg fs-1"
                          data-order="${order._id}"
                          @click="checkRefund(order)"
                        >
                          ВОЗВРАТЫ ({{ getRefundPaidTotal(order) }} из
                          {{ order.totalCost }})
                        </button>
                      </div>

                      <div
                        v-if="order.showRefund"
                        class="d-flex justify-content-center m-3"
                      >
                        <div class="col-3">
                          <div
                            v-for="(refund, refundIndex) in order.refunds"
                            :key="refund._id"
                            class="row mt-1"
                          >
                            <div class="col">{{ refund.amount }} руб</div>
                            <div class="col">
                              <span
                                v-if="
                                  refund.paid == refund.amount &&
                                    !refund.checkOnly
                                "
                                class="text-success"
                                >ВОЗВРАЩЕНО</span
                              >
                              <span v-if="refund.checkOnly" class="text-success"
                                >ТОЛЬКО ЧЕК</span
                              >
                              <span
                                v-if="
                                  refund.paid != refund.amount &&
                                    !refund.checkOnly
                                "
                                class="text-danger"
                                >НЕ ВОЗВРАЩЕНО</span
                              >
                            </div>
                            <div class="col">
                              <button
                                v-if="
                                  fiscalizeButtonVisiblityQr(
                                    order,
                                    refundIndex,
                                    true
                                  )
                                "
                                type="button"
                                class="btn btn-primary"
                                data-order="${order._id}"
                                @click="
                                  buttonOnClick($event, order, {
                                    kkmQrIndex: refundIndex,
                                    refund: true,
                                  })
                                "
                              >
                                ПРОБИТЬ ЧЕК
                              </button>
                              <button
                                v-if="
                                  showChequeButtonVisiblityQr(
                                    order,
                                    refundIndex,
                                    true
                                  )
                                "
                                type="button"
                                class="btn show-cheque"
                                data-order="${order._id}"
                                @click="
                                  buttonOnClick($event, order, {
                                    kkmQrIndex: refundIndex,
                                    refund: true,
                                  })
                                "
                              >
                                ПОКАЗАТЬ ЧЕК
                              </button>
                            </div>
                          </div>
                          <div class="row cols-2 mt-1">
                            <div class="col">
                              <input
                                v-model="amountNewRefund"
                                type="Number"
                                class="form-control"
                                placeholder="Сумма"
                              />
                            </div>
                            <div class="col">
                              <button
                                class="btn btn-primary"
                                @click="registerRefund(order)"
                              >
                                Добавить возврат
                              </button>
                              <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="newRefundCheckOnly"
                              />
                              <label class="form-check-label">
                                Только чек
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Кнопка начала погрузки -->
                      <div
                        v-if="order.taskDriver && !order.closed && userDriver()"
                      >
                        <div
                          class="d-flex justify-content-center m-3 btn-group"
                        >
                          <button
                            type="button"
                            class="btn btn-primary btn-lg fs-1"
                            data-order="${order._id}"
                            :disabled="order.loadingStart"
                            @click="buttonOnClick($event, order)"
                          >
                            <span v-if="!order.loadingStart"
                              >НАЧАТЬ ПОГРУЗКУ</span
                            >
                            <span v-else
                              >Начало погрузки:
                              {{ dateFormat(order.loadingStart) }}</span
                            >
                          </button>
                        </div>
                      </div>

                      <!-- Кнопка закрыть заказ -->
                      <div
                        class="d-flex justify-content-center m-3 btn-group"
                        v-if="!order.closed && userDriver()"
                      >
                        <button
                          type="button"
                          class="btn btn-success btn-lg fs-1"
                          data-order="${order._id}"
                          @click="buttonOnClick($event, order)"
                        >
                          ЗАКРЫТЬ ЗАКАЗ
                        </button>
                      </div>

                      <!-- Кнопка Есть возврат -->
                      <div
                        class="d-flex justify-content-center m-3 btn-group"
                        v-if="!order.closed && userDriver()"
                      >
                        <button
                          type="button"
                          class="btn btn-info btn-lg fs-1"
                          data-order="${order._id}"
                          @click="buttonOnClick($event, order)"
                        >
                          ЕСТЬ ВОЗВРАТ
                        </button>
                      </div>

                      <div
                        v-if="userStoreManager()"
                        class="d-flex justify-content-center m-1 btn-group"
                      >
                        <button
                          type="button"
                          class="btn btn-info btn-lg fs-1"
                          data-order="${order._id}"
                          @click="createRefund(order)"
                        >
                          <span v-if="!order.docCorrection"
                            >ДОБАВИТЬ ВОЗВРАТ</span
                          >
                          <span v-if="order.docCorrection"
                            >ОТКРЫТЬ ВОЗВРАТ</span
                          >
                        </button>
                      </div>
                    </div>

                    <!-- Кнопка открыть заказ только для admin -->
                    <div
                      class="d-flex justify-content-center m-3 btn-group"
                      v-if="openOrderButtonVisiblity(order)"
                    >
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-order="${order._id}"
                        @click="buttonOnClick($event, order)"
                      >
                        ОТКРЫТЬ ЗАКАЗ
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- orders -->
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { onMounted, onUnmounted } from "vue";
//import useUsersMixin from "@/components/usersMixin.js";
import * as sbpSettings from "../sbpSettings.json";
import QrcodeVue from "qrcode.vue";
import useCommonMixin from "@/components/commonMixin.js";
import { useRouter } from "vue-router";

export default {
  components: {
    QrcodeVue,
  },
  setup() {
    const deliverDate = ref("");
    const orders = ref([]);
    const statusFilter = ref("showOpened");
    const tableOfResults = ref({});
    const checkKkmTasksButtonVisiblity = ref(false);
    const searchText = ref("");
    const intervalId = ref("");
    const currentCourier = ref({
      name: "",
    });
    const qrReceived = ref(true);
    const searchInvoiceNumber = ref("");
    const ordersNoCheque = ref([]);
    const QRAcceptOrgs = ref(["1001348586"]);
    const amountNewQR = ref(0);
    const amountNewRefund = ref(0);
    const newRefundCheckOnly = ref(false);
    const dateFrom = ref("");
    const dateTo = ref("");
    const transportTasks = ref([]);

    //useUsersMixin();
    //const { users } = useUsersMixin();
    const users = ref([]);
    const stores = ref([]);
    const currentStore = ref("");
    const userAdmin = () => {
      return JSON.parse(localStorage.getItem("isAdmin"));
    };
    const userDriver = () => {
      return JSON.parse(localStorage.getItem("isDriver"));
    };
    const userLoader = () => {
      return JSON.parse(localStorage.getItem("isLoader"));
    };
    const userPayManager = () => {
      return JSON.parse(localStorage.getItem("isPayManager"));
    };
    const isDriverOrders = () => {
      return JSON.parse(localStorage.getItem("isDriverOrders"));
    };
    const isOrderListPeriod = () => {
      return JSON.parse(localStorage.getItem("isOrderListPeriod"));
    };
    const userStoreManager = () => {
      return JSON.parse(localStorage.getItem("isStoreManager"));
    };

    const { getSkladOrderByInvoiceGuid, postDriverPriority, postUserHistoryByMetric } = useCommonMixin();
    const router = useRouter();

    const internetPokypatel = ref(false);

    const getOrders = async () => {
      let res = { status: 500 };

      if (!isOrderListPeriod()) {
        res = await fetch(
          process.env.VUE_APP_API_URL + "/getOrders/" + deliverDate.value,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa("username" + ":" + localStorage.getItem("password")),
            },
          }
        );
      }

      if (isOrderListPeriod()) {
        res = await fetch(
          process.env.VUE_APP_API_URL +
            `/getOrders/byPeriod/dateFrom/${dateFrom.value}/dateTo/${dateTo.value}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa("username" + ":" + localStorage.getItem("password")),
            },
          }
        );
      }

      console.log(res.status);

      if (res.status == 200) {
        orders.value = await res.json();
        calculatetableOfResults();
      }
      getOrdersCouriers();
      getOrdersStores();
      getOrdersNoCheque();
    };

    const getTransportTasks = async () => {
      console.log("getTransportTasks");
      let res = { status: 500 };

      if (!isOrderListPeriod()) {
        res = await fetch(
          process.env.VUE_APP_API_URL +
            "/getTransportTasks/" +
            deliverDate.value,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa("username" + ":" + localStorage.getItem("password")),
            },
          }
        );
      }

      if (isOrderListPeriod()) {
        res = await fetch(
          process.env.VUE_APP_API_URL +
            `/getTransportTasks/byPeriod/dateFrom/${dateFrom.value}/dateTo/${dateTo.value}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa("username" + ":" + localStorage.getItem("password")),
            },
          }
        );
      }

      console.log("getTransportTasks status", res.status);

      if (res.status == 200) {
        transportTasks.value = await res.json();
        calculatetableOfResults();
      }
      getOrdersCouriers();
      getOrdersStores();
      getOrdersNoCheque();
    };

    watch(currentCourier, () => {
      console.log("watch");
      calculatetableOfResults();
    });

    const getOrdersCouriers = () => {
      users.value = [];
      transportTasks.value.forEach((order) => {
        let userIndex = users.value.findIndex((item) => item == order.courier);
        //console.log("order.courier", order.courier);
        //console.log("users.value", users.value);
        if (userIndex < 0) users.value.push(order.courier);
      });
      currentCourier.value =
        userAdmin() || isDriverOrders() ? "" : users.value[0];
    };

    const getOrdersStores = () => {
      stores.value = [];
      stores.value.push("Склад №1 (ПТЗ)");
      stores.value.push("Склад №2 (МУРМАНСК)");
      stores.value.push("Склад №3 (АПАТИТЫ)");
      // orders.value.forEach((order) => {
      //   let storeIndex = stores.value.findIndex((item) => item == order.store);
      //   if (storeIndex < 0) stores.value.push(order.store);
      // });
      //currentStore.value = "";
    };

    const ordersFiltered = computed(() => {
      return (taskOrders) => {
        //console.log("taskOrders", taskOrders);
        let result = [];
        if (currentCourier.value) {
          //console.log("currentCourier.value", currentCourier.value);
          result = taskOrders.filter((order) => {
            return order.courier == currentCourier.value;
          });
        } else {
          result = taskOrders;
        }
        //console.log("result1", result);

        if (currentStore.value) {
          result = result.filter((order) => {
            return order.store == currentStore.value;
          });
        }
        //console.log("result2", result);

        if (internetPokypatel.value) {
          result = result.filter((order) => {
            return order.client.indexOf("ИнтернетПокупатель") >= 0;
          });
        }
        //console.log("result3", result);

        if (searchText.value.length >= 3) {
          result = result.filter((order) => {
            let index = order.client
              .toLocaleLowerCase()
              .indexOf(searchText.value.toLocaleLowerCase());
            return index >= 0;
          });
        }

        //console.log("ordersFiltered", result);
        return result;
      };
    });

    function calculatetableOfResults() {
      tableOfResults.value.qtyOfOrders = 0;
      tableOfResults.value.qtyOfClosedOrders = 0;
      tableOfResults.value.qtyOfCancelledOrders = 0;
      tableOfResults.value.qtyOfDraftOrders = 0;
      tableOfResults.value.nal = 0;
      tableOfResults.value.karta = 0;
      tableOfResults.value.qr = 0;
      tableOfResults.value.bezcheka = 0;
      tableOfResults.value.bottlesReturned = 0;
      tableOfResults.value.bottles = 0;
      tableOfResults.value.bottlesDelivered = 0;
      tableOfResults.value.vodaKzh = 0;
      tableOfResults.value.vodaVart = 0;
      tableOfResults.value.vodaKl = 0;
      tableOfResults.value.relatedProducts = {};
      tableOfResults.value.totalPaid = 0;
      tableOfResults.value.totalCost = 0;
      tableOfResults.value.totalClients = 0;

      let allClients = [];

      //console.log("ordersFiltered.value", ordersFiltered.value);
      transportTasksFiltered.value.forEach((task) => {
        ordersFiltered.value(task.orders).forEach((order) => {
          tableOfResults.value.qtyOfOrders++;

          tableOfResults.value.totalPaid += order.totalPaid;
          tableOfResults.value.totalCost += order.totalCost;
          let clientIndex = allClients.findIndex(
            (item) => item == order.client
          );
          if (clientIndex < 0) allClients.push(order.client);
        });

        tableOfResults.value.totalClients = allClients.length;
      });
    }

    const orderColor = (order) => {
      let color = "#ff9999";
      if (order.closed && order.orderStatus == "Доставлен") color = "#90EE90";
      //if (order.closed && order.orderStatus == "Отменен") color = "#d7dbe1";
      //if (order.draft) color = "#FFE188";
      if (order.isPaid) color = "#ffc10787";
      return color;
    };

    const orderVisiblity = (order) => {
      // Фильтр по статусам
      if (
        (statusFilter.value == "showOpened") &
        (order.closed || order.draft) &
        !order.spinner
      )
        return false;
      if ((statusFilter.value == "showClosed") & !order.closed & !order.spinner)
        return false;
      if ((statusFilter.value == "showDraft") & !order.draft & !order.spinner)
        return false;

      // Поиск
      // if (
      //   (searchText.value != "") &
      //   (searchText.value.length >= 3) &
      //   !order.search
      // )
      //   return false;

      return true;
    };

    const payTypeVisiblity = (order) => {
      return !order.needCheque & !order.closed;
    };

    const fiscalizeButtonVisiblity = (order) => {
      // return (order.nal || order.karta || order.qr) & !order.needCheque;
      return (
        (getQrPaidTotal.value(order) == order.totalCost) & !order.needCheque
      );
    };

    const fiscalizeButtonVisiblityQr = (order, qrIndex, refund = false) => {
      // return (order.nal || order.karta || order.qr) & !order.needCheque;
      let qr = !refund ? order.qrCodes[qrIndex] : order.refunds[qrIndex];
      return (
        (qr.paid >= qr.amount) &
        (!qr.chequeUuid || qr.kkmTaskError) &
        !qr.confirmKkmTask
      );
    };

    const showChequeButtonVisiblity = (order) => {
      return order.needCheque & order.chequeRecieved;
    };

    const showChequeButtonVisiblityQr = (order, qrIndex, refund = false) => {
      let qr = !refund ? order.qrCodes[qrIndex] : order.refunds[qrIndex];
      return qr.chequeRecieved;
    };

    const closeOrderButtonVisiblity = (order) => {
      return !order.closed & (order.chequeRecieved || order.bezcheka);
    };

    const openOrderButtonVisiblity = (order) => {
      //console.log("userAdmin()", userAdmin());
      return order.closed & order.bezcheka & userAdmin();
    };

    const buttonOnClick = async (event, order, options = null) => {
      order.spinner = true;
      let button = event.target;
      console.log(button.textContent);
      let btnTextContent = button.textContent.trim();
      if (
        btnTextContent == "НАЛ" ||
        btnTextContent == "КАРТА" ||
        btnTextContent == "QR" ||
        btnTextContent == "БЕЗ ЧЕКА"
      ) {
        order.orderStatus = btnTextContent;
        order["nal"] = false;
        order["karta"] = false;
        order["qr"] = false;
        order["bezcheka"] = false;
        order[button.dataset.paytype] = true;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
          //console.log(savedOrder)
        }
        return;
      }

      if (btnTextContent == "ПРОБИТЬ ЧЕК") {
        button.textContent = "ПОДТВЕРДИТЬ ЧЕК";
        button.style.background = "red";
        order.spinner = false;
        return;
      }

      if (btnTextContent == "ПОДТВЕРДИТЬ ЧЕК") {
        checkKkmTasksButtonVisiblity.value = true;
        //order.needCheque = true;
        order.kkmQrIndex = options.kkmQrIndex;
        if ("refund" in options && options.refund) {
          order.refunds[options.kkmQrIndex].needCheque = true;
          order.refunds[options.kkmQrIndex].confirmKkmTask = true;
          order.refund = true;
        } else {
          order.qrCodes[options.kkmQrIndex].needCheque = true;
          order.qrCodes[options.kkmQrIndex].confirmKkmTask = true;
          order.refund = false;
        }

        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          await sendTaskToKkm(order);
          order.spinner = false;
          //console.log(kkmTaskResData);
        }
        return;
      }

      if (btnTextContent == "ПОКАЗАТЬ ЧЕК") {
        let prop = options.refund ? "refunds" : "qrCodes";
        order.spinner = false;
        window.open(
          "/cheque/" + order[prop][options.kkmQrIndex].chequeId + "/" + prop,
          "_blank"
        );
        return;
      }

      if (btnTextContent == "ЗАКРЫТЬ ЗАКАЗ") {
        order.closed = true;
        order.closedDate = new Date();
        order.orderStatus = "Доставлен";
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }

      if (btnTextContent == "ЕСТЬ ВОЗВРАТ") {
        order.refund = true;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }

      if (btnTextContent == "ОТКРЫТЬ ЗАКАЗ") {
        order.closed = false;
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }

      if (btnTextContent == "СОХРАНИТЬ КОММЕНТАРИЙ") {
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }

      if (btnTextContent == "НАЧАТЬ ПОГРУЗКУ") {
        order.loadingStart = new Date();
        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
        }
        return;
      }

      if (btnTextContent == "ПОКАЗАТЬ QR") {
        if (order.qrId) {
          order.spinner = false;
          order.showQR = true;
          let checkRes = await checkQR(order);
          if (checkRes && checkRes.code == "SUCCESS") {
            if (checkRes.paymentStatus == "SUCCESS") {
              order.qrPaid = checkRes.amount;
              order.qrPaidDate = checkRes.transactionDate;
            }
          }
        } else {
          let res = await getQR(order);
          if (res && res.code == "SUCCESS") {
            order.qrId = res.qrId;
            order.qrUrl = res.qrUrl;
            order.qrPayload = res.payload;
          } else {
            qrReceived.value = false;
          }
        }

        let savedOrder = await saveOrder(order);
        if (savedOrder) {
          order.spinner = false;
          order.showQR = true;
        }

        return;
      }
    };

    const registerQR = async (order) => {
      order.spinner = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          `/registerOrderQR/${order._id}/${amountNewQR.value}/`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );

      if (res.status == 200) {
        order.qrCodes = await res.json();
        order.currentQR = order.qrCodes[order.qrCodes.length - 1];
        order.showQR = true;
        amountNewQR.value = 0;
      }
      order.spinner = false;
    };

    const cancelQR = async (order, qrId) => {
      order.spinner = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL + `/cancelOrderQR/${order._id}/${qrId}/`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );

      if (res.status == 200) {
        order.qrCodes = await res.json();
        order.showQR = true;
        order.currentQR = order.qrCodes[0];
      }
      order.spinner = false;
    };

    const registerRefund = async (order) => {
      let confirm = window.confirm(
        `Вы действительно хотите сделать возврат ${amountNewRefund.value} руб по заказу № ${order.orderNumber}?`
      );
      if (!confirm) {
        return;
      }
      let refundType = newRefundCheckOnly.value ? "checkOnly" : "full";

      order.spinner = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          `/registerOrderRefund/${order._id}/${amountNewRefund.value}/${refundType}`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );

      if (res.status == 200) {
        order.refunds = await res.json();
      }
      amountNewRefund.value = 0;
      order.spinner = false;
    };

    const setCurrrentQR = (order, qr) => {
      order.currentQR = qr;
      order.showQR = true;
      checkPayment(order, false);
    };

    const getQrPaidTotal = computed(() => {
      return (order) => {
        let totalPaid = 0;
        order.qrCodes.forEach((item) => {
          totalPaid += item.paid;
        });
        return totalPaid;
      };
    });

    const getRefundPaidTotal = computed(() => {
      return (order) => {
        let totalPaid = 0;
        order.refunds.forEach((item) => {
          totalPaid += item.paid;
        });
        return totalPaid;
      };
    });

    const checkPayment = async (order, useSpinner = true) => {
      if (useSpinner) order.spinner = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL + `/checkOrderQR/${order._id}/`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );

      if (res.status == 200) {
        order.qrCodes = await res.json();
      }
      order.spinner = false;
      order.showQrPayment = true;
    };

    const checkRefund = async (order, useSpinner = true) => {
      if (useSpinner) order.spinner = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL + `/checkOrderRefund/${order._id}/`,
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );

      if (res.status == 200) {
        order.refunds = await res.json();
      }
      order.spinner = false;
      order.showRefund = true;
    };

    const getQR = async (order) => {
      if (order.invoiceGuid == "" || !order.invoiceGuid) {
        return undefined;
      }

      let qrReq = {
        //additionalInfo: "Доп. информация",
        amount: order.totalCost,
        currency: "RUB",
        order: order.invoiceGuid,
        paymentDetails: `Оплата по заказу ${
          order.invoiceNumber
        } от ${dateFormat(order.invoiceDate)}`,
        qrType: "QRDynamic",
        qrExpirationDate: "+129600M", //90 дней - это максимум
        sbpMerchantId: sbpSettings.id,
      };

      let res = await fetch(sbpSettings.url + "v1/qr/register", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sbpSettings.token,
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(qrReq),
      });

      let resJson = undefined;
      if (res.status == 200) {
        resJson = await res.json();
      }
      console.log("SBP reg", resJson);
      return resJson;
    };

    const checkQR = async (order) => {
      let res = await fetch(
        `${sbpSettings.url}/v1/qr/${order.qrId}/payment-info`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sbpSettings.token,
            "Content-Type": "application/json;charset=utf-8",
          },
        }
      );

      let resJson = undefined;
      if (res.status == 200) {
        resJson = await res.json();
      }
      console.log("SBP check", resJson);
      return resJson;
    };

    async function saveOrder(order) {
      console.log("/saveOrder");
      let res = await fetch(process.env.VUE_APP_API_URL + "/saveOrder", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(order),
      });
      console.log(res.status);
      let resJson = undefined;
      if (res.status == 200) {
        resJson = await res.json();
      }
      return resJson;
    }

    async function sendTaskToKkm(order) {
      console.log("/sendTaskToKkm");
      let res = await fetch(process.env.VUE_APP_API_URL + "/sendTaskToKkm", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(order),
      });
      console.log(res.status);
      let resJson = undefined;
      if (res.status == 200) {
        resJson = await res.json();
      }
      return resJson;
    }

    const checkKkmTasks = async () => {
      checkKkmTasksButtonVisiblity.value = false;
      //console.log("/checkKkmTasks");
      let res = await fetch(process.env.VUE_APP_API_URL + "/checkKkmTasks", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      });
      //console.log(res.status);
      //let updatedKkmTasks = undefined;
      if (res.status == 200) {
        await res.json();
        //console.log(updatedKkmTasks);
        // if (
        //   updatedKkmTasks.length > 0 ||
        //   (orders.value.length == 0 && !searchInvoiceNumber.value)
        // )
        //   await getOrders();
      }
    };

    const findOrders = () => {
      // if (searchText.value.length < 3) return;
      // searchText.value = searchText.value.toLowerCase();
      // for (const order of orders.value) {
      //   order.search =
      //     JSON.stringify(order)
      //       .toLowerCase()
      //       .indexOf(searchText.value) >= 0;
      // }
      calculatetableOfResults();
    };

    const dateFormat = (date) => {
      let dateFormated = new Date(date);
      return dateFormated.toLocaleString();
    };

    const getOrderByInvoice = async () => {
      console.log("/getOrderByInvoice/");
      console.log(
        process.env.VUE_APP_API_URL +
          "/getOrderByInvoice/" +
          searchInvoiceNumber.value
      );
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          "/getOrderByInvoice/" +
          searchInvoiceNumber.value,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        let ordersByInvoice = await res.json();
        transportTasks.value = [
          {
            store: "",
            deliveryDate: dateFrom.value,
            guid: "ПоискЗаказа",
            courier: "Поиск заказа",
            orders: ordersByInvoice,
            show: true,
          },
        ];

        calculatetableOfResults();
        statusFilter.value = "showAll";
        if (ordersByInvoice.length > 0) {
          deliverDate.value = ordersByInvoice[0].deliveryDate.slice(0, 10);
        }
      }
      getOrdersCouriers();
      getOrdersStores();

      currentCourier.value = "";
    };

    const getOrdersNoCheque = async () => {
      console.log("/getOrdersNoCheque/");
      console.log(process.env.VUE_APP_API_URL + "/getOrdersNocheque/");
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/getOrdersNoCheque/",
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        ordersNoCheque.value = await res.json();
      }
    };

    const setOrdersNoCheque = async () => {
      await getOrdersNoCheque();
      transportTasks.value = [
        {
          store: "",
          deliveryDate: dateFrom.value,
          guid: "ОплаченоБезЧеков",
          courier: "Оплачено без чеков",
          orders: ordersNoCheque.value,
          show: true,
        },
      ];
      //orders.value = ordersNoCheque.value;
      calculatetableOfResults();
      getOrdersCouriers();
      getOrdersStores();
      statusFilter.value = "showAll";
    };

    onMounted(async () => {
      console.log("onMounted");
      let today = new Date().toISOString().slice(0, 10);
      deliverDate.value = today;

      today = new Date();
      // var lastDayOfMonth = new Date(
      //   today.getFullYear(),
      //   today.getMonth() + 1,
      //   1
      // );
      // var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      let lastDayOfMonth = today;
      let firstDayOfMonth = today;
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);

      //await getOrders();
      await getTransportTasks();
      intervalId.value = window.setInterval(() => {
        checkKkmTasks();
      }, 5000);

      postUserHistoryByMetric('Заказы')
    });

    onUnmounted(async () => {
      console.log("onUnmounted");
      clearInterval(intervalId.value);
    });

    const copyToClipBoard = async (currentQR) => {
      await navigator.clipboard.writeText(currentQR.payload);
      window.confirm(`Ссылка на оплату ${currentQR.amount}руб скопирована`);
    };

    const setTaskShow = (task) => {
      console.log("setTaskShow");
      let show = true;
      if ("show" in task) {
        show = task.show ? false : true;
      }
      return (task.show = show);
    };

    const transportTasksFiltered = computed(() => {
      let result = transportTasks.value;
      if (currentCourier.value) {
        result = result.filter((item) => item.courier == currentCourier.value);
      }

      if (currentStore.value) {
        result = result.filter((item) => item.store == currentStore.value);
      }

      return result;
    });

    const taskOrdersClosed = computed(() => {
      return (orders) => {
        let result = 0;
        orders.forEach((order) => {
          if (order.closed) result += 1;
        });
        return result;
      };
    });

    const taskOrdersRefund = computed(() => {
      return (orders) => {
        let result = 0;
        orders.forEach((order) => {
          if (order.refund) result += 1;
        });
        return result;
      };
    });

    const taskOrdersSumTotal = computed(() => {
      return (orders) => {
        let result = 0;
        orders.forEach((order) => {
          result += order.totalCost;
        });
        return result.toFixed(2);
      };
    });

    const taskOrdersPaidTotal = computed(() => {
      return (orders) => {
        let result = 0;
        orders.forEach((order) => {
          result += order.totalPaid;
        });
        return result.toFixed(2);
      };
    });

    const createRefund = async (order) => {
      let skladOrder = {};
      let skladOrderResponse = await getSkladOrderByInvoiceGuid(
        order.invoiceGuid
      );
      if (skladOrderResponse.status) {
        skladOrder = skladOrderResponse.data;
      }

      let invoiceGuid = "invoiceGuid" in order ? order.invoiceGuid : "";
      let docCorrectionId = order.docCorrection
        ? order.docCorrection
        : "__new__";
      router.push({
        name: "docCorrectionItem",
        params: {
          id: docCorrectionId,
          invoiceGuid: invoiceGuid,
          orderGuid: skladOrder.guid,
          orderObj: JSON.stringify(skladOrder),
        },
      });
    };

    const setDriverPriority = (task) => {
      task.edit = true;
    };

    const saveDriverPriority = async (task) => {
      let ordersToSave = []
      task.orders.forEach(order => {
        ordersToSave.push({_id: order._id, driverPriority: order.driverPriority})
      })

      let resp = await postDriverPriority(ordersToSave)
      console.log(resp);
      if (resp.status) task.edit = false;

    };

    return {
      deliverDate,
      orders,
      getOrders,
      orderColor,
      orderVisiblity,
      statusFilter,
      tableOfResults,
      checkKkmTasksButtonVisiblity,
      payTypeVisiblity,
      fiscalizeButtonVisiblity,
      showChequeButtonVisiblity,
      closeOrderButtonVisiblity,
      openOrderButtonVisiblity,
      buttonOnClick,
      checkKkmTasks,
      searchText,
      findOrders,
      userAdmin,
      dateFormat,
      users,
      currentCourier,
      ordersFiltered,
      calculatetableOfResults,
      stores,
      currentStore,
      userLoader,
      userDriver,
      qrReceived,
      searchInvoiceNumber,
      getOrderByInvoice,
      userPayManager,
      ordersNoCheque,
      setOrdersNoCheque,
      QRAcceptOrgs,
      registerQR,
      amountNewQR,
      setCurrrentQR,
      getQrPaidTotal,
      checkPayment,
      fiscalizeButtonVisiblityQr,
      showChequeButtonVisiblityQr,
      checkRefund,
      amountNewRefund,
      registerRefund,
      getRefundPaidTotal,
      newRefundCheckOnly,
      cancelQR,
      copyToClipBoard,
      isDriverOrders,
      internetPokypatel,
      dateFrom,
      dateTo,
      isOrderListPeriod,
      getTransportTasks,
      transportTasks,
      transportTasksFiltered,
      setTaskShow,
      taskOrdersClosed,
      taskOrdersRefund,
      taskOrdersPaidTotal,
      taskOrdersSumTotal,
      createRefund,
      userStoreManager,
      setDriverPriority,
      saveDriverPriority,
    };
  },
};
</script>

<style scoped>
.show-cheque {
  background-color: #3f51b5;
  color: white;
}
</style>
