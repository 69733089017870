<template>
  <div class="container">
    <div id="scanner"></div>

    <h1 class="text-center">Учет товаров</h1>
    <div class="row">
      <div class="col">
        <button
          v-if="!isProductBalanceRO()"
          class="btn btn-primary w-100"
          @click="addProductBalance()"
        >
          Добавить
        </button>
      </div>
      <div class="col">
        <button class="btn btn-info w-100" @click="fetchProductBalance()">
          Обновить
        </button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <button
          v-if="false && !isProductBalanceRO()"
          class="btn btn-outline-danger w-100"
          @click="removeProductBalance()"
        >
          Удалить все историю и остатки
        </button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <div class="input-group">
          <input
            v-model="searchTextTmp"
            type="text"
            class="form-control px-1"
            min="0"
            @change="searchText = searchTextTmp"
            placeholder="Поиск"
          />
          <div class="input-group-append">
            <button class="btn btn-secondary" @click="clearSearchText()">
              Очистить
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-cols-1 mt-1">
      <div class="col">
        <select
          id="stores"
          v-model="currentStore"
          class="border-1 form-select custom-select w-100"
          @change="onChangeCurrentStore()"
        >
          <option value="">Все склады</option>
          <option v-for="store in stores" :value="store" :key="store">
            {{ store }}
          </option>
        </select>
      </div>
    </div>

    <div class="row row-cols-1 mt-1">
      <div class="col">
        <Multiselect
          v-model="currentPosition"
          :options="positions"
          :loading="false"
          :internal-search="false"
          valueProp="_id"
          trackBy="name"
          label="name"
          :filterResults="true"
          :minChars="2"
          :resolveOnLoad="true"
          :searchable="true"
          noOptionsText="Не найден"
          :limit="5"
          placeholder="Ячейка"
        />
      </div>
      <div v-if="false" class="col-3 ps-0">
        <button class="btn btn-warning w-100" @click="startScan()">Скан</button>
      </div>
    </div>

    <div class="row row-cols-1 mt-1">
      <div class="col">
        <Multiselect
          v-model="currentProduct"
          :options="products"
          :loading="false"
          :internal-search="false"
          valueProp="_id"
          trackBy="name"
          label="name"
          :filterResults="true"
          :minChars="2"
          :resolveOnLoad="true"
          :searchable="true"
          noOptionsText="Не найден"
          :limit="50"
          placeholder="Товар"
          @change="setProductBalance1C()"
        >
          <template v-slot:singlelabel="option">
            <div class="multiselect-single-label">
              <span class="multiselect-single-label-text1 single-label">{{
                option.value.name
              }}</span>
            </div>
          </template>
        </Multiselect>
      </div>
      <div v-if="false" class="col-3 ps-0">
        <button class="btn btn-warning w-100" @click="startScan()">Скан</button>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <span class="fw-bold">ИТОГО ТСД: {{ totalQty().toFixed(3) }}</span>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col">
        <span class="fw-bold">ИТОГО 1С: {{ totalQty1C.toFixed(3) }}</span>
      </div>
    </div>

    <spinner v-if="spinnerGetProductBalance" />

    <div class="row  mt-2">
      <div class="col">
        <button class="btn btn-outline-secondary w-50" @click="showMore('up')">
          Показать еще
        </button>
        <button
          class="btn btn-outline-secondary w-50"
          @click="showMore('start')"
        >
          В начало
        </button>
      </div>
    </div>

    <div
      v-for="(productRow, productRowIndex) in productBalanceFilter"
      :key="productRowIndex"
      class="mt-2 border"
      :class="{ 'selected-row': selectedRowId == productRow._id }"
      @click="selectRow(productRow)"
    >
      <div class="row row-cols-3">
        <div class="col-6 product-label">
          <Multiselect
            v-if="
              !productRow._id ||
                productRow.changeProduct ||
                productRow._id.indexOf('new_') >= 0
            "
            v-model="productRow.product"
            :options="products"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="name"
            label="name"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
            :limit="30"
            :class="{ 'product-input-wide': isMobile }"
          >
            <template v-slot:singlelabel="option">
              <div class="multiselect-single-label">
                <span class="multiselect-single-label-text1 single-label">{{
                  option.value.name
                }}</span>
              </div>
            </template>
          </Multiselect>

          <span v-if="productRow._id && !productRow.changeProduct" class="">{{
            productRow.productName
          }}</span>

          <div class="row">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                class="btn btn-light"
                @click="setExpDateVisible(productRow)"
              >
                Сроки
              </button>
              <button
                class="btn btn-loght fw-bold"
                @click="addExpDate(productRow)"
              >
                +
              </button>
            </div>

            <p v-if="firstExpDate(productRow)">
              {{ firstExpDate(productRow) }}
            </p>

            <div v-if="productRow.showExpDates">
              <input
                v-if="productRow.isNewExpDate"
                v-model="productRow.expDates[0]"
                type="text"
                class="form-control px-0 exp-date"
                placeholder="Сроки"
                :id="`exp-date-${productRow._id}`"
                :disabled="false"
                @input="productRow.edited = true"
              />

              <p
                v-for="expDate in productRow.expDates"
                :key="expDate"
                class="mb-0"
              >
                {{ expDate }}
              </p>
            </div>
          </div>

          <select
            v-model="productRow.store"
            class="border-1 form-select custom-select w-100"
            :disabled="
              productRow._id &&
                productRow._id.indexOf('new_') < 0 &&
                String(productRow._id).length > 0
            "
          >
            <option value="" disabled selected hidden>Склад</option>
            <option v-for="store in stores" :value="store" :key="store">
              {{ store }}
            </option>
          </select>
        </div>
        <div class="col-5 px-0">
          <Multiselect
            v-model="productRow.position"
            :options="positions"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="name"
            label="name"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
            :disabled="
              productRow._id &&
                productRow._id.indexOf('new_') < 0 &&
                String(productRow._id).length > 0
            "
            :limit="5"
          ></Multiselect>

          <div class="px-1 pt-2">
            <span class="fw-bold"
              >{{ productRow.total.toFixed(3) }} -
              {{ productMeasure(productRow.productName) }}</span
            >
            <button
              v-if="!productRow.deleteSpinner && isDeleteProductBalance()"
              class="btn btn-sm btn-outline-danger"
              @click="deleteProductBalanceObj(productRow)"
            >
              X
            </button>
            <spinner v-if="productRow.deleteSpinner" />
          </div>

          <div class="px-1 pt-2">
            <span class="fw-bold1"
              >1С:
              {{
                productRow.storebalance
                  ? productRow.storebalance.balance.toFixed(3)
                  : 0
              }}</span
            >
          </div>
        </div>
      </div>

      <div class="row row-cols-2">
        <div v-if="false" class="col-sm flex">
          <div class=" form-check form-switch margin-auto">
            <label class="mx-1 text-muted">Приоритет (Расход)</label>

            <input
              v-if="!productRow.setDefaultSpinner"
              style="width: 3rem; height: 1.0rem"
              class="form-check-input"
              type="checkbox"
              @change="setDefaultPosition(productRow)"
              :disabled="
                !productRow._id ||
                  String(productRow._id).length == 0 ||
                  productRow.changeProduct ||
                  isProductBalanceRO()
              "
              v-model="productRow.default"
            />
            <spinner v-if="productRow.setDefaultSpinner" />
          </div>
        </div>

        <div class="col-sm  flex">
          <button
            class="btn btn-light margin-auto"
            :disabled="productRow.total != 0"
            @click="changeProduct(productRow)"
          >
            Смена товара
          </button>
        </div>
      </div>

      <div class="row row-cols-1 mt-2">
        <div class="col-sm-6">
          <input
            class="form-control"
            type="text"
            @input="productRow.edited = true"
            v-model="productRow.productObj.artikul"
            placeholder="Артикул"
          />
        </div>
      </div>

      <div class="row row-cols-2">
        <div class="col-sm flex">
          <div class=" form-check form-switch margin-auto">
            <label class="mx-1 text-muted">По умолчанию (Приход)</label>

            <input
              v-if="!productRow.setAutoFillSpinner"
              style="width: 3rem; height: 1.0rem"
              class="form-check-input"
              type="checkbox"
              @change="setAutoFillPosition(productRow)"
              :disabled="
                !productRow._id ||
                  String(productRow._id).length == 0 ||
                  productRow.changeProduct ||
                  isProductBalanceRO()
              "
              v-model="productRow.autoFill"
            />
            <spinner v-if="productRow.setAutoFillSpinner" />
          </div>
        </div>

        <div class="col-sm ">
          <div class="row row-cols-1">
            <div class="col">
              <button
                v-if="!isProductBalanceRO()"
                class="btn btn-light margin-auto "
                @click="moveProduct(productRow)"
                :disabled="productRow.changeProduct"
              >
                Перемещение
              </button>
            </div>
          </div>

          <div class="row row-cols-1">
            <div class="col">
              <button
                v-if="!isProductBalanceRO()"
                class="btn margin-auto "
                :class="{
                  'btn-warning':
                    'inventories' in productRow &&
                    productRow.inventories.length > 0,
                  'btn-light':
                    !('inventories' in productRow) ||
                    productRow.inventories.length == 0,
                }"
                @click="pushToInventory(productRow)"
                :disabled="productRow.changeProduct"
              >
                Ревизия
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-cols-2"></div>

      <div class="row row-cols-4 mt-1">
        <div class="col-2">
          <button
            v-if="!isProductBalanceRO()"
            class="btn"
            :class="{
              'btn-warning': productRow.showNewIncome,
              'btn-light': !productRow.showNewIncome,
            }"
            @click="addIncome(productRow)"
            :disabled="productRow.changeProduct"
          >
            +
          </button>
        </div>
        <div class="col-2">
          <button
            v-if="!isProductBalanceRO()"
            class="btn"
            :class="{
              'btn-warning': productRow.showNewOutcome,
              'btn-light': !productRow.showNewOutcome,
            }"
            @click="addOutcome(productRow)"
            :disabled="productRow.changeProduct"
          >
            -
          </button>
        </div>
        <div class="col">
          <button
            v-if="!isProductBalanceRO()"
            class="btn btn-light"
            @click="showRowHistory(productRow)"
            :disabled="productRow.changeProduct"
          >
            История
          </button>
        </div>
        <div v-if="productRow.edited" class="col">
          <button
            v-if="!productRow.saveSpinner"
            class="btn btn-light"
            @click="saveRow(productRow)"
            :disabled="
              !productRow.position ||
                (productRow.newIncome &&
                  incomeTypeOther == productRow.newIncome.type &&
                  !productRow.newIncome.comment) ||
                (productRow.newOutcome &&
                  outcomeTypeOther == productRow.newOutcome.type &&
                  !productRow.newOutcome.comment) ||
                (productRow.showNewMove &&
                  (!productRow.newMove.position || !productRow.newMove.qty)) ||
                !productRow.store
            "
          >
            Сохранить
          </button>
          <Spinner v-if="productRow.saveSpinner" />
        </div>
      </div>

      <div v-if="productRow.showNewIncome" class="row mt-1">
        <div class="col">
          <Multiselect
            v-model="productRow.newIncome.type"
            :options="incomeTypes"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="label"
            label="label"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
            placeholder="Приход"
          ></Multiselect>
          <input
            v-if="incomeTypeOther == productRow.newIncome.type"
            v-model="productRow.newIncome.comment"
            type="text"
            class="form-control px-1"
            placeholder="Комментарий"
          />
        </div>
        <div class="col">
          <input
            v-model="productRow.newIncome.qty"
            type="number"
            class="form-control px-1"
            min="0"
          />
        </div>
      </div>

      <div v-if="productRow.showNewOutcome" class="row mt-1">
        <div class="col">
          <Multiselect
            v-model="productRow.newOutcome.type"
            :options="outcomeTypes"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="label"
            label="label"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
            placeholder="Расход"
          ></Multiselect>
          <input
            v-if="outcomeTypeOther == productRow.newOutcome.type"
            v-model="productRow.newOutcome.comment"
            type="text"
            class="form-control px-1"
            placeholder="Комментарий"
          />
        </div>
        <div class="col">
          <input
            v-model="productRow.newOutcome.qty"
            type="number"
            class="form-control px-1"
            min="0"
          />
        </div>
      </div>

      <div v-if="productRow.showNewMove" class="row mt-1">
        <div class="col">
          <Multiselect
            v-model="productRow.newMove.position"
            :options="positions"
            :loading="false"
            :internal-search="false"
            valueProp="_id"
            trackBy="name"
            label="name"
            :filterResults="true"
            :minChars="2"
            :resolveOnLoad="true"
            :searchable="true"
            noOptionsText="Не найден"
            :limit="5"
            placeholder="Новая ячейка"
          ></Multiselect>
        </div>
        <div class="col">
          <input
            v-model="productRow.newMove.qty"
            type="number"
            step=".001"
            class="form-control px-1"
            placeholder="Количество"
          />
        </div>
      </div>

      <div v-if="productRow.showHistory" class="mt-1">
        <div
          v-for="(historyRow, historyRowIndex) in productRow.rows"
          :key="historyRowIndex"
          class="row row-cols-4 product-label border-top mt-1"
        >
          <div class="col-sm">
            {{ dateFormat(historyRow.date) }}
          </div>
          <div class="col-sm">
            {{
              historyRow.user ? historyRow.user.name : "Пользователь не найден"
            }}
          </div>
          <div class="col-sm">
            {{ historyRow.qty * (historyRow.income ? 1 : -1) }}
          </div>
          <div class="col-sm">
            <span v-if="historyRow.order">{{
              historyRow.order.orderNumber
            }}</span>
          </div>

          <div
            v-if="!('productChange' in historyRow) || !historyRow.productChange"
            class="col-sm fw-bold"
          >
            <span v-if="historyRow.income">{{
              historyRow.incomeType.label
            }}</span>
            <span v-if="!historyRow.income">{{
              historyRow.outcomeType.label
            }}</span>
          </div>

          <div
            v-if="'productChange' in historyRow && historyRow.productChange"
            class="col-sm fw-bold"
          >
            <span>Смена товара</span>
          </div>

          <div class="col-7">
            <span>{{ historyRow.comment }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row  mt-2">
      <div class="col">
        <button
          class="btn btn-outline-secondary w-50"
          @click="showMore('down')"
        >
          Показать еще
        </button>
        <button
          class="btn btn-outline-secondary w-50"
          @click="showMore('start')"
        >
          В начало
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch, nextTick } from "vue";
import Multiselect from "@vueform/multiselect";
import useCommonMixin from "@/components/commonMixin.js";
import Spinner from "@/components/Spinner.vue";
//import { onMounted, onUnmounted } from "vue";
// import router from "../router";
import { v4 as uuidv4 } from "uuid";
import Inputmask from "inputmask";

export default {
  components: { Multiselect, Spinner },
  setup() {
    const positions = ref([]);
    const products = ref([]);
    const incomeTypes = ref([]);
    const outcomeTypes = ref([]);
    const productBalance = ref([]);

    const incomeTypeOther = ref("");
    const outcomeTypeOther = ref("");

    const filterCursor = ref(10);
    const filterStep = ref(10);
    const filterMax = ref(20);

    const searchText = ref("");
    const searchTextTmp = ref("");
    const currentPosition = ref("");
    const currentProduct = ref("");

    let spinnerGetProductBalance = ref(false);
    const selectedRowId = ref("");

    const stores = ref([
      "Склад №1 (ПТЗ)",
      "Склад №2 (МУРМАНСК)",
      "Склад №3 (АПАТИТЫ)",
    ]);
    const currentStore = ref("Склад №1 (ПТЗ)");

    const skladPtz = () => {
      return JSON.parse(localStorage.getItem("skladPtz"));
    };

    const skladApatiti = () => {
      return JSON.parse(localStorage.getItem("skladApatiti"));
    };

    const skladMyrmansk = () => {
      return JSON.parse(localStorage.getItem("skladMyrmansk"));
    };

    const currentStoreProductBalance =  () => {
      return localStorage.getItem("currentStoreProductBalance");
    };

    const {
      dateFormat,
      getPositions,
      getProducts,
      getIncomeTypes,
      getOutcomeTypes,
      getProductBalance,
      postProductBalance,
      deleteProductBalance,
      postDefaultProductBalancePosition,
      postAutoFillProductBalancePosition,
      deleteProductBalanceById,
      getSettings,
      scan,
      scannedText,
      postProductBalanceToInventory,
      isMobile,
      getProductBalance1C,
      getProductBalanceRows,
      postUserHistoryByMetric,
    } = useCommonMixin();

    const isProductBalanceRO = () => {
      return JSON.parse(localStorage.getItem("isProductBalanceRO"));
    };

    const isDeleteProductBalance = () => {
      return JSON.parse(localStorage.getItem("isDeleteProductBalance"));
    };

    const applyMask = async (elementId = null) => {
      await nextTick();
      let expDateElements = [];
      if (elementId) {
        let element = document.getElementById(elementId);
        expDateElements.push(element);
      } else {
        expDateElements = document.getElementsByClassName("exp-date");
      }
      if (expDateElements != undefined) {
        for (let index = 0; index < expDateElements.length; index++) {
          const element = expDateElements[index];
          var im = new Inputmask("99.99.99-99.99.99");
          im.mask(element);
        }
      }
    };

    const selectRow = (row) => {
      // if (selectedRowId.value == row._id) {
      //   selectedRowId.value = '';
      // } else {
      //   selectedRowId.value = row._id;
      // }
      selectedRowId.value = row._id;
    };

    const addIncome = (row) => {
      row.showNewIncome = true;
      row.showNewOutcome = false;
      row.showHistory = false;
      row.showNewMove = false;

      row.edited = true;
      let newIncome = {
        type: "",
        qty: null,
        comment: "",
      };
      row.newIncome = newIncome;
    };

    const addOutcome = (row) => {
      row.showNewIncome = false;
      row.showNewOutcome = true;
      row.showHistory = false;
      row.showNewMove = false;

      row.edited = true;
      let newOutcome = {
        type: "",
        qty: null,
        comment: "",
      };
      row.newOutcome = newOutcome;
    };

    const moveProduct = (row) => {
      let confirm = window.confirm(
        `Вы действительно хотите сделать перемещение?`
      );
      if (!confirm) {
        return;
      }

      row.showNewIncome = false;
      row.showNewOutcome = false;
      row.showHistory = false;
      row.showNewMove = true;

      row.edited = true;
      let newMove = {
        position: "",
        qty: null,
        comment: "",
      };
      row.newMove = newMove;
    };

    const pushToInventory = async (row) => {
      let confirm = window.confirm(
        `Вы действительно хотите добавить ${row.productName} - ${row.total} шт в инвентаризацию?`
      );
      if (!confirm) {
        return;
      }

      let postToInventoryResp = await postProductBalanceToInventory(row);
      console.log(postToInventoryResp);
      if (postToInventoryResp.status) {
        if (postToInventoryResp.data.inventoryId) {
          let inventoryIndex = row.inventories.findIndex(
            (item) => item == postToInventoryResp.data.inventoryId
          );
          if (inventoryIndex < 0) row.inventories.push(inventoryIndex);
        }
        console.log("pushToInventory - OK!");
      }
    };

    const saveRow = async (row) => {
      let savingMove = false;
      if (row.showNewMove) {
        let positionDstIndex = positions.value.findIndex(
          (item) => item._id == row.newMove.position
        );
        if (positionDstIndex >= 0) {
          let positionDstName = positions.value[positionDstIndex].name;

          let confirm = window.confirm(
            `Вы действительно хотите переместить на ячейку ${positionDstName}?`
          );

          if (!confirm) {
            return;
          }
          savingMove = true;
        }
      }
      console.log("row", row);
      row.saveSpinner = true;
      let postProductBalanceRes = await postProductBalance(row);
      if (postProductBalanceRes.status) {
        row.total = postProductBalanceRes.data.total;
        row._id = postProductBalanceRes.data._id;
        row.rows = postProductBalanceRes.data.rows;
        row.productName = postProductBalanceRes.data.productName;
        row.productObj = postProductBalanceRes.data.productObj

        row.showNewIncome = false;
        row.showNewOutcome = false;
        row.showNewMove = false;
        row.newOutcome = {};
        row.newIncome = {};
        row.newMove = {};
        row.edited = false;
        row.changeProduct = false;
        row.isNewExpDate = false;
      }
      row.saveSpinner = false;

      if (savingMove) {
        fetchProductBalance();
      }
    };

    const removeProductBalance = async () => {
      let confirm = window.confirm(
        `Вы действительно хотите удалить все остатки и историю?`
      );
      if (!confirm) {
        return;
      }
      await deleteProductBalance();
      await fetchProductBalance();
    };

    const addProductBalance = () => {
      let newProductBalance = {
        _id: `new_${uuidv4()}`,
        product: "",
        position: "",
        total: 0,
        balance1C: 0,
        rows: [],
        productName: "",
        edited: true,
        newIncome: { type: null, comment: "" },
        newOutcome: { type: null, comment: "" },
        store: currentStore.value,
        inventories: [],
        isNewExpDate: false,
        expDates: [],
        productObj: { artikul: "" },
      };
      productBalance.value.unshift(newProductBalance);
      filterCursor.value = 5;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      selectedRowId.value = newProductBalance._id;

      applyMask(`exp-date-${selectedRowId.value}`);
    };

    const deleteProductBalanceObj = async (productRow) => {
      let positionIndex = positions.value.findIndex(
        (positionItem) => positionItem._id == productRow.position
      );
      let positionName =
        positionIndex >= 0 ? positions.value[positionIndex].name : "";
      let confirm = window.confirm(
        `Вы действительно хотите удалить остатки и историю по '${productRow.productName}' на ячейке '${positionName}'?`
      );
      if (!confirm) {
        return;
      }

      productRow.deleteSpinner = true;
      let deleteResp = { status: true };
      if (productRow._id) {
        deleteResp = await deleteProductBalanceById(productRow._id);
      }
      if (deleteResp.status) {
        let productRowIndex = productBalance.value.findIndex(
          (productRowitem) => productRowitem._id == productRow._id
        );
        if (productRowIndex >= 0)
          productBalance.value.splice(productRowIndex, 1);
      }
      productRow.deleteSpinner = false;
    };

    const showRowHistory = async (row) => {
      let historyResp = await getProductBalanceRows(row._id);
      if (historyResp.status) row.rows = historyResp.data;
      row.showNewIncome = false;
      row.showNewOutcome = false;
      row.showHistory = "showHistory" in row && row.showHistory ? false : true;
    };

    const fetchProductBalance = async () => {
      spinnerGetProductBalance.value = true;
      let productBalanceRes = await getProductBalance();
      productBalance.value = productBalanceRes.status
        ? productBalanceRes.data
        : productBalance.value;
      spinnerGetProductBalance.value = false;
    };

    const showMore = (direction) => {
      if (direction == "up") {
        filterCursor.value =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
      }
      if (direction == "down") {
        filterCursor.value =
          filterCursor.value + filterStep.value < productBalance.value.length
            ? filterCursor.value + filterStep.value
            : productBalance.value.length - filterStep.value;
      }

      if (direction == "start") {
        filterCursor.value = filterStep.value;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };

    const productBalanceFilterWithoutCursor = computed(() => {
      let result = productBalance.value;

      if (currentPosition.value) {
        result = result.filter((item) => {
          return item.position == currentPosition.value;
        });
      }

      if (currentProduct.value) {
        result = result.filter((item) => {
          return item.product == currentProduct.value;
        });
      }

      if (searchText.value.length >= 3) {
        let searchTextLC = searchText.value.toLocaleLowerCase();
        result = result.filter((item) => {
          let searchIndex = JSON.stringify(item.productName)
            .toLocaleLowerCase()
            .indexOf(searchTextLC);
          return searchIndex >= 0;
        });
      }

      if (currentStore.value) {
        result = result.filter((item) => {
          return item.store == currentStore.value;
        });
      }

      return result;
    });

    const productBalanceFilter = computed(() => {
      let result = productBalanceFilterWithoutCursor.value; //productBalance.value;

      // if (currentPosition.value) {
      //   result = result.filter((item) => {
      //     return item.position == currentPosition.value;
      //   });
      // }

      // if (currentProduct.value) {
      //   result = result.filter((item) => {
      //     return item.product == currentProduct.value;
      //   });
      // }

      // if (searchText.value.length >= 3) {
      //   let searchTextLC = searchText.value.toLocaleLowerCase();
      //   result = result.filter((item) => {
      //     let searchIndex = JSON.stringify(item.productName)
      //       .toLocaleLowerCase()
      //       .indexOf(searchTextLC);
      //     return searchIndex >= 0;
      //   });
      // }

      if (result && result.length > filterMax.value) {
        let filterStart =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
        let filterEnd = filterCursor.value + filterStep.value;

        result = result.slice(filterStart, filterEnd);
      }

      // if (currentStore.value) {
      //   result = result.filter((item) => {
      //     return item.store == currentStore.value;
      //   });
      // }

      return result;
    });

    const clearSearchText = () => {
      searchText.value = "";
      searchTextTmp.value = "";
    };

    const totalQty = () => {
      let result = productBalanceFilter.value.reduce(
        (sum, curr) => sum + curr.total,
        0
      );
      return result;
    };

    const totalQty1C = ref(0);
    // const totalQty1C = () => {
    //   let result = productBalanceFilter.value.reduce(
    //     (sum, curr) => sum + curr.balance1C,
    //     0
    //   );
    //   return result;
    // };

    const setDefaultPosition = async (productRow) => {
      if (productRow._id) {
        productRow.setDefaultSpinner = true;
        let setDefResponse = await postDefaultProductBalancePosition(
          productRow
        );
        if (setDefResponse.status) {
          await fetchProductBalance();
        }
        productRow.setDefaultSpinner = false;
      }
    };

    const setAutoFillPosition = async (productRow) => {
      if (productRow._id) {
        productRow.setAutoFillSpinner = true;
        let setAutoFillResponse = await postAutoFillProductBalancePosition(
          productRow
        );
        if (setAutoFillResponse.status) {
          await fetchProductBalance();
        }
        productRow.setAutoFillSpinner = false;
      }
    };

    const changeProduct = (productRow) => {
      if (!productRow.changeProduct) {
        let confirm = window.confirm(`Вы действительно хотите сменить товар?`);
        if (!confirm) {
          return;
        }
      }

      productRow.changeProduct = productRow.changeProduct ? false : true;
      productRow.edited = productRow.changeProduct;
    };

    onMounted(async () => {
      stores.value = [];
      if (skladPtz()) stores.value.push("Склад №1 (ПТЗ)");
      if (skladMyrmansk()) stores.value.push("Склад №2 (МУРМАНСК)");
      if (skladApatiti()) stores.value.push("Склад №3 (АПАТИТЫ)");

      currentStore.value = currentStoreProductBalance()

      let positionsRes = await getPositions();
      let productsRes = await getProducts();
      let incomeTypesRes = await getIncomeTypes();
      let outcomeTypesRes = await getOutcomeTypes();

      positions.value = positionsRes.status
        ? positionsRes.data
        : positions.value;
      products.value = productsRes.status ? productsRes.data : products.value;
      incomeTypes.value = incomeTypesRes.status
        ? incomeTypesRes.data
        : incomeTypes.value;
      outcomeTypes.value = outcomeTypesRes.status
        ? outcomeTypesRes.data
        : outcomeTypes.value;

      await fetchProductBalance();

      let incomeTypeOtherIndex = incomeTypes.value.findIndex(
        (item) => item.name == "other"
      );
      incomeTypeOther.value =
        incomeTypeOtherIndex >= 0
          ? incomeTypes.value[incomeTypeOtherIndex]._id
          : "unknown";

      let outcomeTypeOtherIndex = outcomeTypes.value.findIndex(
        (item) => item.name == "other"
      );
      outcomeTypeOther.value =
        outcomeTypeOtherIndex >= 0
          ? outcomeTypes.value[outcomeTypeOtherIndex]._id
          : "unknown";

      await getSettings();

      document.addEventListener("paste", (event) => {
        let paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );
        handleClipboard(paste);
        event.preventDefault();
      });

      applyMask();

      postUserHistoryByMetric('Учет товаров')
    });

    const startScan = async () => {
      scan();
    };

    watch(scannedText, () => {
      console.log("scannedText", scannedText.value);
      let positionIndex = positions.value.findIndex(
        (positionItem) => positionItem.guid == scannedText.value
      );
      console.log("positionIndex", positionIndex);
      if (positionIndex >= 0) {
        currentPosition.value = positions.value[positionIndex]._id;
      }
    });

    const handleClipboard = (text) => {
      console.log("handleClipboard new", text);
      let positionIndex = PositionIndex(text);
      let productIndex = ProductIndex(text);

      let newMoveSelected = false;
      let selectedRow = null;

      if (selectedRowId.value) {
        let selectedRowIndex = productBalance.value.findIndex(
          (item) => item._id == selectedRowId.value
        );
        if (selectedRowIndex >= 0) {
          selectedRow = productBalance.value[selectedRowIndex];
        }
      }

      let isNewRow = selectedRow ? selectedRow._id.indexOf("new_") >= 0 : false;

      if (selectedRow && selectedRow.showNewMove) {
        newMoveSelected = true;
      }

      if (positionIndex >= 0) {
        if (newMoveSelected && selectedRow) {
          selectedRow.newMove.position = positions.value[positionIndex]._id;
        } else if (selectedRow && isNewRow) {
          selectedRow.position = positions.value[positionIndex]._id;
        } else {
          currentPosition.value = positions.value[positionIndex]._id;
        }
      }

      if (productIndex >= 0) {
        if (selectedRow && isNewRow) {
          selectedRow.product = products.value[productIndex]._id;
        } else {
          currentProduct.value = products.value[productIndex]._id;
        }
      }
    };

    const PositionIndex = (text) => {
      let positionIndex = positions.value.findIndex(
        (positionItem) => positionItem.guid == text
      );
      return positionIndex;
    };

    const ProductIndex = (text) => {
      let productIndex = products.value.findIndex((product) => {
        let barcodeIndex = product.barcodes.findIndex(
          (barcodeItem) => text.indexOf(barcodeItem) >= 0
        );
        return barcodeIndex >= 0;
      });
      return productIndex;
    };

    const productMeasure = (productName) => {
      let result = "";
      let productSplit = productName.split("-");
      if (productSplit.length >= 2) {
        result = productSplit[productSplit.length - 1];
      }
      return result;
    };

    const setExpDateVisible = (productRow) => {
      if (!("showExpDates" in productRow)) productRow.showExpDates = false;
      productRow.showExpDates = productRow.showExpDates ? false : true;
      productRow.isNewExpDate = false;
    };

    const addExpDate = (productRow) => {
      if (!("expDates" in productRow)) productRow.expDates = [];
      productRow.expDates.unshift("");
      productRow.isNewExpDate = true;
      productRow.edited = true;
      productRow.showExpDates = true;
      applyMask(`exp-date-${productRow._id}`);
    };

    const firstExpDate = computed(() => {
      return (productRow) => {
        let expDate = "";
        if ("expDates" in productRow && productRow.expDates.length > 0)
          expDate = productRow.expDates[0];
        if (productRow.showExpDates) expDate = "";
        return expDate;
      };
    });

    const onChangeCurrentStore = async () => {
      localStorage.setItem("currentStoreProductBalance", currentStore.value);
      await setProductBalance1C()
    }

    const setProductBalance1C = async () => {
      await nextTick();
      let balance = 0;

      if (currentStore.value) {
        let balanceResponse = await getProductBalance1C(
          currentProduct.value,
          currentStore.value
        );
        balance = balanceResponse.data.balance;
      }

      totalQty1C.value = balance;
    };

    return {
      positions,
      products,
      incomeTypes,
      outcomeTypes,
      dateFormat,
      productBalance,
      addIncome,
      incomeTypeOther,
      addOutcome,
      outcomeTypeOther,
      saveRow,
      showRowHistory,
      addProductBalance,
      fetchProductBalance,
      showMore,
      productBalanceFilter,
      filterCursor,
      filterStep,
      searchTextTmp,
      searchText,
      clearSearchText,
      totalQty,
      totalQty1C,
      currentPosition,
      removeProductBalance,
      setDefaultPosition,
      changeProduct,
      startScan,
      isProductBalanceRO,
      deleteProductBalanceObj,
      setAutoFillPosition,
      currentProduct,
      moveProduct,
      selectRow,
      selectedRowId,
      isDeleteProductBalance,
      stores,
      currentStore,
      pushToInventory,
      isMobile,
      productMeasure,
      setExpDateVisible,
      addExpDate,
      firstExpDate,
      setProductBalance1C,
      spinnerGetProductBalance,
      onChangeCurrentStore,
    };
  },
};
</script>

<style scoped>
.single-label {
  line-height: 100%;
}

.product-label {
  line-height: 110%;
}
.flex {
  display: flex;
}

.margin-auto {
  margin-top: auto;
}

.multiselect-single-label {
  padding-right: 10px !important;
}
.selected-row {
  background-color: #e1e7e0 !important;
}
</style>
