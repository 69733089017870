import { computed, ref } from "vue";

export default () => {
  let users = ref([]);
  const searchUser = ref('')

  const getUsers = async () => {
    console.log("/getUsers");
    let res = await fetch(process.env.VUE_APP_API_URL + "/getUsers", {
      method: "GET",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            "username:" +

            localStorage.getItem("password")
          ),
      },
    });
    console.log(res.status);

    if (res.status == 200) {
      users.value = await res.json();
    }
  };

  const usersFilter = computed(() => {
    let result = users.value

    if (searchUser.value.length >= 3) {
      result = users.value.filter((user) => {
        return JSON.stringify(user).toLocaleLowerCase().indexOf(searchUser.value.toLocaleLowerCase()) >= 0
      })
    }

    return result
  })

  const saveUser = async (user) => {
    user.spinner = true;
    console.log("/saveUser");
    let res = await fetch(process.env.VUE_APP_API_URL + "/saveUser", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            "username:" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(user),
    });
    console.log(res.status);
    let resJson = undefined;
    if (res.status == 200) {
      resJson = await res.json();
      console.log(resJson);
      user.spinner = false;
      user.modified = false;
    }
  };

  const addUser = async () => {
    console.log("/addUser");
    let newUser = {
      name: "Новый пользователь",
      username: "newuser",
      password: "newuser",
      admin: false,
    };
    let res = await fetch(process.env.VUE_APP_API_URL + "/addUser", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            "username:" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(newUser),
    });
    console.log(res.status);
    let resJson = undefined;

    if (res.status == 200) {
      await getUsers();

      resJson = await res.json();
      const el = document.getElementById(resJson._id);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        el.classList.add("bg-danger");
      }
    }
  };

  const deleteUser = async (user) => {
    let result = confirm("Удалить пользователя " + user._id + "?");
    if (!result) {
      console.log("!result");
      return;
    }
    user.spinner = true;
    console.log("/deleteUser");
    let res = await fetch(process.env.VUE_APP_API_URL + "/deleteUser", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " +
          btoa(
            "username:" +
            localStorage.getItem("password")
          ),
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(user),
    });
    console.log(res.status);

    if (res.status == 200) {
      await getUsers();
      user.spinner = false;
    }
  };





  return {
    users,
    getUsers,
    saveUser,
    addUser,
    deleteUser,
    searchUser,
    usersFilter,
  }
}
