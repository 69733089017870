<template>
  <div>
    <div class="text-center">
      <h1>ИНВЕНТАРИЗАЦИИ СКЛАДА</h1>
      <div class="d-flex-header justify-content-center m-3 btn-group">
        <div class="row row-cols-2">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
        </div>

        <div class="row row-cols-1">
          <div class="col mt-1">
            <Multiselect
              v-model="currentStore"
              :options="stores"
              :loading="false"
              :internal-search="false"
              valueProp="_id"
              trackBy="name"
              label="name"
              :filterResults="true"
              :minChars="2"
              :resolveOnLoad="true"
              :searchable="true"
              noOptionsText="Не найден"
              :limit="10"
              @change="onChangeCurrentStore()"
            />
          </div>
        </div>

        <div class="row row-cols-2">
          <div class="col mt-1">
            <button
              class="btn btn-primary w-100"
              @click="getDocs()"
              :disabled="spinnerLoading"
            >
              <span v-if="!spinnerLoading">Обновить </span>
              <span v-if="spinnerLoading">Идет загрузка </span>
            </button>
          </div>

          <div class="col mt-1">
            <router-link
              class="btn btn-warning w-100"
              :to="{ name: 'skladInventoryItem', params: { id: '__new__' } }"
              >НОВАЯ</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container">
      <div
        v-for="doc in docs"
        :key="doc._id"
        class="row row-cols-2 border mx-1 mt-1"
        @click="openDoc(doc._id)"
      >
        <div class="col">{{ dateFormat(doc.date) }}</div>
        <div class="col ">
          {{ doc.number }}
        </div>
        <div class="col">
          <span v-if="doc.store && doc.store.name"> {{ doc.store.name }}</span>
        </div>
        <div class="col">
          <span v-if="doc.user && doc.user.name">{{ doc.user.name }}</span>
        </div>

        <div class="col">
          <span> {{ doc.comment }}</span>
        </div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
//import { onMounted, onUnmounted } from "vue";
import router from "../router";
import useCommonMixin from "@/components/commonMixin.js";
import Multiselect from "@vueform/multiselect";

export default {
  components: { Multiselect },
  setup() {
    const spinnerLoading = ref(false);
    const dateFrom = ref("");
    const dateTo = ref("");
    const currentStore = ref("");
    const stores = ref([]);
    const docs = ref([]);

    const { dateFormat, getSkladInventories, getStores, postUserHistoryByMetric } = useCommonMixin();

    const currentStoreSkladInventory = () => {
      return localStorage.getItem("currentStoreSkladInventory");
    };

    const getDocs = async () => {
      spinnerLoading.value = true;
      let resp = await getSkladInventories(
        dateFrom.value,
        dateTo.value,
        currentStore.value
      );
      console.log(resp);
      if (resp.status) {
        docs.value = resp.data;
      }
      spinnerLoading.value = false;
      console.log(docs.value);
    };

    const openDoc = (id) => {
      router.push({ name: "skladInventoryItem", params: { id: id } });
    };

    const onChangeCurrentStore = async () => {
      await nextTick();
      localStorage.setItem("currentStoreSkladInventory", currentStore.value);
    };

    onMounted(async () => {
      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);

      let storesRes = await getStores();
      stores.value = storesRes.status ? storesRes.data : storesRes.value;
      currentStore.value = currentStoreSkladInventory();
      await getDocs();
      postUserHistoryByMetric('Инвентаризации склада')
    });

    return {
      dateFrom,
      dateTo,
      getDocs,
      docs,
      dateFormat,
      openDoc,
      spinnerLoading,
      currentStore,
      stores,
      onChangeCurrentStore,
    };
  },
};
</script>

<style>
.d-flex-header {
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
</style>
