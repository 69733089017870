<template>
  <div>
    <div class="text-center">
      <h1>Корректировки документов</h1>
      <div class="d-flex justify-content-center m-3 btn-group">
        <div class="row">
          <div class="col mt-1">
            <input
              v-model="dateFrom"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <input
              v-model="dateTo"
              type="date"
              class="form-control fs-5"
              placeholder="Дата"
            />
          </div>
          <div class="col mt-1">
            <button class="btn btn-primary w-100" @click="getDocs()">
              Сформировать
            </button>
          </div>
          <div class="col mt-1">
            <router-link
              class="btn btn-info w-100"
              :to="{ name: 'docCorrectionItem', params: { id: '__new__' } }"
              >Добавить</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="!spinnerLoading" class="container">
      <div class="row row-cols-1 border mx-1 mt-1">
        <div class="row">
          <div class="col">Дата</div>
          <div class="col">Номер</div>
        </div>
        <div class="row">
          <div class="col">Грузчик</div>
          <div class="col">Водитель</div>
        </div>
        <div class="row">
          <div class="col">Коммент Бух</div>
        </div>
        <div class="row">
          <div class="col">Коммент Склад</div>
        </div>
      </div>

      <DocCorrectionItem
        v-for="doc in docs"
        :key="doc._id"
        :item="doc"
        :products-list="products"
        list
      />

      <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import useCommonMixin from "@/components/commonMixin.js";
// import SkladOrdersReadOnly from "@/components/SkladOrdersReadOnly.vue";
import DocCorrectionItem from "@/components/DocCorrectionItem.vue";

export default {
  components: { DocCorrectionItem },
  setup() {
    const spinnerLoading = ref(true);
    const products =  ref([])
    const docs = ref([]);
    const dateFrom = ref("");
    const dateTo = ref("");

    const { dateFormat, getDocsCorrection, getProducts, postUserHistoryByMetric, } = useCommonMixin();

    const getDocs = async () => {
      docs.value = [];
      spinnerLoading.value = true;
      let result = await getDocsCorrection(dateFrom.value, dateTo.value);
      if (result.status) {
        docs.value = result.data;
        spinnerLoading.value = false;
      }
    };

    onMounted(async () => {
      let productsResult = await getProducts();
      if (productsResult.status) {
        products.value = productsResult.data;
      }

      let today = new Date();
      var lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        1
      );
      var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
      dateFrom.value = firstDayOfMonth.toISOString().slice(0, 10);
      dateTo.value = lastDayOfMonth.toISOString().slice(0, 10);
      // await getDocs();
      postUserHistoryByMetric('Корректировки документов')
    });

    return {
      dateFrom,
      dateTo,
      getDocs,
      docs,
      spinnerLoading,
      dateFormat,
      products
    };
  },
};
</script>

<style>
.lh-20 {
  line-height: 20px;
}
</style>
